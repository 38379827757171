import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiErrors, handleApiResponse, resetErrors } from "./slice-api";
import { selectFilterItem as setArticlesFilter } from "./slice-articles";
import { selectFilterItem as setLmFilter } from "./slice-learning-modules";
import { selectFilterItem as setEventsFilter } from "./slice-events";
import { clearReducers, loginState } from "./slice-auth";
import { logActivityInSheet } from "./slice-log";
import axios from "../../services/apiService";

import { add_to_datalayer } from "../../helpers/tracking";

const initialState = {
  data: null,
  foxoSynced: false
};

export const getAccountData = createAsyncThunk("account/get", async (id, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get("api/v1/account");
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const register = createAsyncThunk("account/register", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.post("api/v1/account/register", data);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data.success && response.data.userLoggedIn && response.data.userLoggedIn.success){
      add_to_datalayer({
        event: 'User_Registration',
      });
      thunkAPI.dispatch(resetErrors());
      thunkAPI.dispatch(clearReducers());
      localStorage.setItem("wb_idp_token", response.data.userLoggedIn.data.token);
      localStorage.setItem("user_preferences", JSON.stringify(response.data.userLoggedIn.data.preferences ? response.data.userLoggedIn.data.preferences : null));
      thunkAPI.dispatch(loginState());
      thunkAPI.dispatch(logActivityInSheet({activity: 'Registration Level 1', sheet: 'Registrations'}));

      //thunkAPI.dispatch(setDefaultFilters());
      //Remove Foxo Integration
      // thunkAPI.getState().settings.history.replace({pathname: '/register/foxo', state: {hasFoxo: response.data.hasFoxo}});
      // thunkAPI.getState().settings.history.replace('/');
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response))
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const registerValidate = createAsyncThunk("account/register-validtate-step", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.post(`api/v1/account/register-validtate-step/${parameters.step}`, parameters.data);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


export const updateProfile = createAsyncThunk("account/update", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/update", data);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data.success){
      thunkAPI.dispatch(resetErrors());
      thunkAPI.dispatch(getAccountData()).then(() => {
        thunkAPI.getState().settings.history.push('/account/profile');
      });
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response))
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


export const completeProfile = createAsyncThunk("account/complete", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/complete", data);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data.success){
      thunkAPI.dispatch(resetErrors());
      await thunkAPI.dispatch(logActivityInSheet({activity: 'Registration Level 2', sheet: 'Registrations'}));
      // thunkAPI.dispatch(getAccountData()).then(() => {
      //   thunkAPI.getState().settings.history.push('/account/profile');
      // });
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response))
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const updatePassword = createAsyncThunk("account/updatePassword", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/update/pass", data);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data.success){
      thunkAPI.dispatch(resetErrors());
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response))
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const setNewPassword = createAsyncThunk("account/setNewPassword", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/set/pass", data);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data.success){
      thunkAPI.dispatch(resetErrors());
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response))
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const updatePreferences = createAsyncThunk("account/updatePreferences", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/update/preferences", data);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data && response.data.success){
      thunkAPI.dispatch(clearReducers());
      localStorage.setItem("user_preferences", JSON.stringify(response.data.preferences ? response.data.preferences : null));
      //thunkAPI.dispatch(setDefaultFilters());
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response))
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const updateMarketing = createAsyncThunk("account/updateMarketing", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/update/marketing", data);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data && response.data.success){
      thunkAPI.dispatch(resetAccountReducer());
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response))
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const getFoxoStatus = createAsyncThunk("account/getFoxStatus", async (id, thunkAPI) => {
  try {
    const response = await axios.get("api/v1/account/foxo-status");
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const unSyncFoxo = createAsyncThunk("account/unSyncFoxo", async (id, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.post("api/v1/foxo-unsync");
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(resetAccountReducer());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const forgotPass = createAsyncThunk("account/forgotPass", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/request-password-reset", data);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const resetPassword = createAsyncThunk("account/resetPassword", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put("api/v1/account/process-password-reset", data);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const addBulkFavourites = createAsyncThunk("account/addBulkFavourites", async (parameters, thunkAPI) => {
  if(!parameters.skip_loading){
    thunkAPI.dispatch(setLoadingStart());
  }
  try {
    const response = await axios.post("api/v1/account/add-favourites", {doctors: parameters.doctors});
    if(!parameters.skip_loading){
      thunkAPI.dispatch(setLoadingEnd());
    }
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    if(!parameters.skip_loading){
      thunkAPI.dispatch(setLoadingEnd());
    }
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const removeFavourite = createAsyncThunk("account/removeFavourite", async (data, thunkAPI) => {
  try {
    const response = await axios.post("api/v1/account/remove-favourite", data);
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);



export const setDefaultFilters = () => {
  return (dispatch) => {
    if(localStorage.getItem("user_preferences") && JSON.parse(localStorage.getItem("user_preferences")) && JSON.parse(localStorage.getItem("user_preferences")).length > 0){
      dispatch(setArticlesFilter({data: JSON.parse(localStorage.getItem("user_preferences")), filterItem: 'topics', filter: 'clinical-article'}));
      dispatch(setArticlesFilter({data: JSON.parse(localStorage.getItem("user_preferences")), filterItem: 'topics', filter: 'treatment-pathways'}));
      dispatch(setArticlesFilter({data: JSON.parse(localStorage.getItem("user_preferences")), filterItem: 'topics', filter: 'treatment-techniques'}));
      dispatch(setArticlesFilter({data: JSON.parse(localStorage.getItem("user_preferences")), filterItem: 'topics', filter: 'research'}));
      dispatch(setArticlesFilter({data: JSON.parse(localStorage.getItem("user_preferences")), filterItem: 'topics', filter: 'news'}));
      dispatch(setLmFilter( {data: JSON.parse(localStorage.getItem("user_preferences")) , filter: 'topics'}));
      dispatch(setEventsFilter( {data: JSON.parse(localStorage.getItem("user_preferences")) , filter: 'topics'}));

    }
  }
}


const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    mutateAccountLMs(state, action){
      if(action.payload.lms){
        action.payload.lms.forEach(lm => {
          const {id, ...rest} = lm;
          state.data.lms[id] = rest;
        });
      }
    },
    mutateAccountLMsArticles(state, action){
      if(action.payload.id){
        if(state.data.lms_articles.indexOf(action.payload.id) === -1){
          state.data.lms_articles.push(action.payload.id)
        }
      }
    },
    resetAccountReducer(){
      return initialState
    }
  },
  extraReducers: {
    [getAccountData.fulfilled]: (state, action) => {
      if(action.payload && action.payload.user){
        state.data = action.payload.user;
      }
    },
    [getAccountData.rejected]: (state, action) => {

    },
    [getFoxoStatus.pending]: (state, action) => {
      
    },
    [getFoxoStatus.fulfilled]: (state, action) => {
        state.foxoSynced = action.payload.synced
    },
    [getFoxoStatus.rejected]: (state, action) => {
      console.log(action);
    },
    [updatePreferences.pending]: (state, action) => {
      
    },
    [updatePreferences.fulfilled]: (state, action) => {
      if(state.data && state.data.preferences){
        state.data.preferences = action.payload.preferences
      }
    },
    [updatePreferences.rejected]: (state, action) => {
      console.log(action);
    },
    [updateMarketing.pending]: (state, action) => {
      
    },
    [updateMarketing.fulfilled]: (state, action) => {
      
    },
    [updateMarketing.rejected]: (state, action) => {
      console.log(action);
    },
    [updateProfile.pending]: (state, action) => {
      
    },
    [updateProfile.fulfilled]: (state, action) => {
      
    },
    [updateProfile.rejected]: (state, action) => {
      console.log(action);
    },
    [completeProfile.pending]: (state, action) => {
      
    },
    [completeProfile.fulfilled]: (state, action) => {
      
    },
    [completeProfile.rejected]: (state, action) => {
      console.log(action);
    },
    [addBulkFavourites.pending]: (state, action) => {
      
    },
    [addBulkFavourites.fulfilled]: (state, action) => {
      if(state.data.favourites){
        state.data.favourites = action.payload.favourites
      }
    },
    [addBulkFavourites.rejected]: (state, action) => {
      console.log(action);
    },
    [removeFavourite.pending]: (state, action) => {
      
    },
    [removeFavourite.fulfilled]: (state, action) => {
      if(state.data.favourites){
        state.data.favourites = action.payload.favourites
      }
    },
    [removeFavourite.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const { resetAccountReducer, mutateAccountLMs, mutateAccountLMsArticles  } = accountSlice.actions;

export default accountSlice.reducer;
