import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { renderMore, resetRender } from "../../store/features/slice-centres";
import Section from "../../components/Section";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import CenterListItem from "../../components/Cards/Contact";
import Tabs from "../../components/Tabs";
import CentresFilter from '../../components/Filters/CentresFilter';
import ScrollToTop from '../../components/ScrollToTop';
import Loading from "../../components/Loading";


import G from "../../app/globals";





const CentresIndexPage = () => {
  const [FilterOpen, setFilterOpen] = useState(false);
  const centresIDs = useSelector((state) => state.centres.list['centres']);
  const centresListBasic = useSelector((state) => state.centres.basic);
  const currentLoaded = useSelector((state) => state.centres.currentLoaded['centres']);
  const filters = useSelector((state) => state.centres.filters['centres']);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  const isFiltered = filters && (filters.treatments || filters.techniques || filters.state || filters.city || filters.postcode)
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(setPageClass("page-centres split"));
    dispatch(setUpHeader({
      show:true,
      logo: true,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    //reset the rendered items when accessing page (exclude back btn (POP))
    if(history.action === 'PUSH'){
      dispatch(resetRender());
    }
  }, [dispatch, history.action]);


  const rendeCentresItems = () => {
    return centresIDs && currentLoaded ? centresIDs.slice(0, currentLoaded).map(id => (
      <CenterListItem
          key={id}
          image={centresListBasic[id].image}
          title={centresListBasic[id].title}
          phone={centresListBasic[id].phone}
          phoneFotmatted={centresListBasic[id].phoneFormatted}
          address={centresListBasic[id].address ? centresListBasic[id].address : centresListBasic[id].location['address']}
          fax={centresListBasic[id].fax}
          isDesktop={isDesktop}
          slug={centresListBasic[id].slug}
      />
    )) : null;
  }

  const handleFilterIconClick = () => {
    if(isDesktop){
      setFilterOpen(!FilterOpen);
    }
    else{
      history.push("/contact-icon/filter")
    }
  }

  return (
    <>
    <ScrollToTop />
    <PageHead 
      className="page-centres--page-head"
      title="Contact Icon"
      bg="blue"
      icon="filter"
      iconText={isDesktop ? (FilterOpen ? 'Close' : 'Filter') : 'Filter'}
      Clicked={handleFilterIconClick}
      secondaryIcon={isFiltered ? 'check' : null}
    />
    
    <PageContent className="page-centres--page-content">
      {isDesktop ? 
        <CentresFilter 
          className={`page-centres--filter in-page${FilterOpen ? '' : ' collapsed'}`}
          title="Search"
          filter="centres"
        /> 
      : null }
      {isAuthenticated ? 
        <Section className="page-centres--tabs">
            <Tabs className="page-centres--tabs-btns" activeItem={2}
                items={[
                  {
                    index: 1,
                    text: 'Nearest centres', 
                    Clicked:() => history.push('/contact-icon'),
                  }, 
                  {
                    index: 2,
                    text: 'View all centres'
                  }
                ]}
            />
        </Section>
      : null}
      {centresIDs && centresIDs.length > 0 ? 
        <Section className="page-centres--content-list attached-list">
          <InfiniteScroll
            dataLength={currentLoaded}
            next={() => dispatch(renderMore('centres'))}
            hasMore={(currentLoaded < centresIDs.length)}
            loader={<Loading />}
            scrollThreshold={0.7}
          >
            {rendeCentresItems()}
          </InfiniteScroll>
        </Section> 
      : null}
    </PageContent>
    </> 
  );
};

export default CentresIndexPage;
