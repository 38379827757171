import React, {useState} from 'react';
import parse from 'html-react-parser';
import SearchIconUrl from '../../assets/images/icons/search_input.svg';
import { Word } from '../Word';

const Input = React.forwardRef((props, ref) => {
  const [type, SetInputType] = useState(props.type);
  const [showPass, SetShowPass] = useState('Show');
  const toggleShowPass = () => {
    if(type === 'password'){
      SetInputType('text');
      SetShowPass('Hide')
    }
    else{
      SetInputType('password');
      SetShowPass('Show')
    }
  }
  let icon = null;
    switch(props.icon){
        case 'search': icon = SearchIconUrl
        break;
        default: icon = null
    }
  return (
    <div className="form-group">
      {props.title ? (
        <label htmlFor={props.id ? props.id : props.name} className="form-label">
          <Word value={props.title} />{props.isRequired ? '*' : ''}
          {props.type === 'password' ? <span className="btn-text" onClick={toggleShowPass}>{showPass}</span> : null}
        </label>
      ) : null}
      <input
        className={`form-input${icon ? ' has-icon' : ''}`}
        id = {props.id ? props.id : props.name}
        name={props.name}
        type={type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        required={props.isRequired}
        disabled={props.isDisabled}
        style={icon ? {backgroundImage: `url(${icon})`} : null}
        pattern={props.pattern}
        ref={ref}
        autoComplete={props.autoComplete}
      />
      {props.description && (
        <p className="form-description">{parse(props.description)}</p>
      )}
    </div>
  );
});

export default React.memo(Input);
