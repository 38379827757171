import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";


function PublicRoute({ component: Component, roles, ...rest }) {
  
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    
    return (
        <Route {...rest} render={props => {
            if (isAuthenticated) {
                // logged in so redirect to home page
                return <Redirect replace to={{ pathname: props.location.state && props.location.state.from ? props.location.state.from.pathname : '/'}} />
            }

            // Not logged in so return component
            return <Component {...props} />
        }} />
    );
}

export default PublicRoute;