import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { google, outlook, office365, yahoo,  ics } from "calendar-link";
import { setPageClass, setUpHeader } from "../../../store/features/slice-ui";
import PageContent from "../../../components/PageContent";
import Section from "../../../components/Section";
import Button from "../../../components/Button";
import {Word} from "../../../components/Word";
import { ReactComponent as CheckedSolidIcon } from '../../../assets/images/icons/check_solid.svg';
import G from "../../../app/globals";
import SetNewPasswordForm from "../../../components/SetNewPasswordForm";


const EventRegisterSuccess = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { slug } = useParams();
  useEffect(() => {
    dispatch(setPageClass("page-event-register-success"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      profile: true,
      search: true,
    }));
  }, [dispatch]);

  const formatIOSDate = (date) => {
    let d = G.NEW_DATE(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        min = '' + d.getMinutes();
    if(month.length < 2){
      month = '0' + month;
    }
    if(day.length < 2){
      day = '0' + day;
    }
    if(hour.length < 2){
      hour = '0' + hour;
    } 
    if(min.length < 2){
      min = '0' + min;
    } 
    return `${year}${month}${day}T${hour}${min}00`;
  }

  const event = {
    title: props.location.state?.title,
    start: props.location.state?.start.date ? formatIOSDate(props.location.state.start.date) : null,
    end: props.location.state?.end.date ?  formatIOSDate(props.location.state.end.date) : null,
    location: props.location.state?.venue
  }

  const handleSetPasswordCallback = () => {
    if(props.location.state?.slug){
      history.push({pathname: `/education/event/${props.location.state.slug}/register-success`, state: {...props.location.state, auto_login: false}})
    }
   
  }

  return (
    <>
    {props.location.state?.slug === slug ? 
      <PageContent className="page-event-register-success--page-content">
          <div className="success--message">
            <div className="success--message-icon"><CheckedSolidIcon /></div>
            <h2><Word value="Registration complete" /></h2>
          </div>
          <Section className="page-event-register-success--details">
              <h1>{props.location.state.title}</h1>
              <div>{props.location.state.date}</div>
              <div>{props.location.state.time}</div>
          </Section>
          <Section className="page-event-register-success--actions">
            
            <Button 
              className="btn-primary purple full-width"
              text="Add to Google calendar"
              Clicked={() => window.open(google(event))}
            />
            <Button 
              className="btn-primary purple full-width"
              text="Add to Outlook calendar"
              Clicked={() => window.open(outlook(event))}
            />
            <Button 
              className="btn-primary purple full-width"
              text="Add to Yahoo calendar"
              Clicked={() => window.open(yahoo(event))}
            />
            <Button 
              className="btn-primary purple full-width"
              text="Add to Office365 calendar"
              Clicked={() => window.open(office365(event))}
            />
            <Button 
              className="btn-primary purple full-width"
              text="Add to iCal calendar"
              Clicked={() => window.location.href = ics(event)}
            />
            <Button 
              className="btn-outline purple full-width"
              text="Back to events"
              Clicked={() => history.replace('/education/events')}
            />
          </Section>
          {props.location.state.auto_login && (
            <Section className="page-event-register-success--password">
              <div className="page-event-register-success--password-content">
                {props.location.state?.form?._ahpra_number ? 
                  <h2>Set your password to gain access to your IDA account so you can make modifications to your booking</h2>
                  : 
                  <h2>Set your password and AHPRA number to gain access to your IDA account so you can make modifications to your booking</h2>
                }
                
                <SetNewPasswordForm 
                  ahpraNumber={props.location.state?.form?._ahpra_number}
                  callback={handleSetPasswordCallback} 
                />
              </div>
            </Section>
          )}
          
      </PageContent>
    : <Redirect replace to={({pathname: '/education/events'})} />}
    </>
  );
};

export default EventRegisterSuccess;
