import React from 'react';
import { useLocation } from 'react-router-dom';
import NavLink from './Navlink';
import { Word } from './Word';
import { ReactComponent as HomeIcon } from '../assets/images/icons/home.svg';
import { ReactComponent as EducationIcon } from '../assets/images/icons/education.svg';
import { ReactComponent as ReferIcon } from '../assets/images/icons/refer.svg';
import { ReactComponent as MoreIcon } from '../assets/images/icons/more.svg';
import G from '../app/globals';


const MenuBottom = () => {
    const location = useLocation();
    return (
        <>
            <nav className="bottom-menu">
                <div className="bottom-menu--inner">
                    <NavLink exact className="bottom-menu--item btn-icon" to="/">
                        <HomeIcon />
                        <Word value="Home" />
                    </NavLink>
                    <NavLink className="bottom-menu--item btn-icon" to="/refer-patient">
                        <ReferIcon />
                        <Word value="Refer patient" />
                    </NavLink>
                    <NavLink className="bottom-menu--item btn-icon" to="/education">
                        <EducationIcon />
                        <Word value="Education" />
                    </NavLink>
                    <NavLink className={`bottom-menu--item btn-icon${G.MORE_ACTIVE_PAGES.includes(location.pathname) ? ' active' : ''}`} to="/more">
                        <MoreIcon />
                        <Word value="More" />
                    </NavLink>
                </div>
            </nav>
        </>
    );
};

export default MenuBottom;