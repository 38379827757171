import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { resetErrors } from "../../store/features/slice-api";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import { Word } from "../../components/Word";
import DoctorCard from "../../components/Cards/Cta";
import G from "../../app/globals";
import Tabs from "../../components/Tabs";
import SingleProfile from "../../components/Dcotor/SingleProfile";
import SingleLocations from "../../components/Dcotor/SingleLocations";
import SingleContact from "../../components/Dcotor/SingleContact";
import Link from "../../components/Link";
import CompleteRegisterForm from "../../components/CompleteRegisterForm";
import ReferForm from "../../components/ReferForm";
import Panel from "../../components/Panel";
import AddToFavoriteButton from "../../components/Dcotor/AddToFavoriteButton";
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';


const ReferPage = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    let { doctorSlug } = useParams();
    const doctorDetailsSingle = useSelector((state) => state.doctors.full ? state.doctors.full[doctorSlug] : null);
    const accountData = useSelector((state) => state.account.data);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const options = useSelector((state) => state.references.options);

    useEffect(() => {
        dispatch(setPageClass("page-referpatient-refer"));
        dispatch(setUpHeader({
            show: true,
            back: true,
            profile: true,
            search: true
        }));
    }, [dispatch]);


    useEffect(() => {
        //empty previous errors from redux
        dispatch(resetErrors());
    }, [dispatch]);

    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if(props.location && props.location.state && props.location.state.tab){
            if(props.location.state.tab === 'contact'){
                setTabIndex(2);
            }
            else{
                setTabIndex(0);
            }
        }
        else{
            setTabIndex(0);
        }
    }, [props.location]);

    
    const handleTabClick = (i) => {
        setTabIndex(i);
    }

    return (
        <>
            {doctorDetailsSingle ?
                <>
                    <PageHead
                        className="page-referpatient-refer--page-head"
                        title="Refer a Patient"
                    />
                    <PageContent className="page-doctor-single--page-content">
                        <Section className="split-content w-40-60">

                            <Section className="page-referpatient-refer--left">
                                <div className="page-referpatient-refer--selection">
                                    <div className="page-referpatient-refer--selection-content">
                                        <div className="page-referpatient-refer--selection-specialist">
                                            <div className="page-referpatient-refer--selection-specialist__title">
                                                <h3><Word value="Specialist" /></h3>
                                                <AddToFavoriteButton doctorSlug={doctorDetailsSingle.slug} />
                                            </div>
                                            
                                            <DoctorCard
                                                image={doctorDetailsSingle.profileImage && doctorDetailsSingle.profileImage.length > 0 ? doctorDetailsSingle.profileImage[0] : null}
                                                title={parse(doctorDetailsSingle.salutation + " " + doctorDetailsSingle.name)}
                                                subtitle={doctorDetailsSingle.tags && doctorDetailsSingle.tags.length > 0 ? doctorDetailsSingle.tags.map((tag, index) => index > 0 ? ', ' + tag.name : tag.name) : null}
                                                isCentered
                                            />

                                            <Section className="page-referpatient-singledoctor--tabs">
                                                <Tabs className="page-referpatient-singledoctor--tabs-btns"
                                                    items={[
                                                        {
                                                            index: 0,
                                                            text: 'Profile',
                                                            Clicked: () => handleTabClick(0)
                                                        },
                                                        {
                                                            index: 1,
                                                            text: 'Location',
                                                            Clicked: () => handleTabClick(1)
                                                        },
                                                        {
                                                            index: 2,
                                                            text: 'Contact',
                                                            Clicked: () => handleTabClick(2)
                                                        }
                                                    ]}
                                                    activeItem={tabIndex}
                                                />
                                                <div className="page-doctor-single--tabs-content">
                                                    {tabIndex === 0 ?
                                                        <SingleProfile
                                                            slug={doctorDetailsSingle.slug}
                                                            interests={doctorDetailsSingle.interests}
                                                            interestsText={doctorDetailsSingle.interestsText}
                                                            publications={doctorDetailsSingle.publications}
                                                            bio={doctorDetailsSingle.bio}
                                                        />
                                                        : null}
                                                    {tabIndex === 1 ?
                                                        <SingleLocations
                                                            centres={doctorDetailsSingle.centres}
                                                        />
                                                        : null}
                                                    {tabIndex === 2 ?
                                                        <SingleContact
                                                            foxoId={doctorDetailsSingle.foxoId}
                                                            linkedinUrl={doctorDetailsSingle.linkedinUrl}
                                                            phone={doctorDetailsSingle.phone}
                                                            phoneFormatted={doctorDetailsSingle.phoneFormatted}
                                                            fax={doctorDetailsSingle.fax}
                                                        />
                                                        : null}
                                                </div>
                                            </Section>


                                        </div>
                                    </div>
                                </div>
                            </Section>
                            <Section className="page-referpatient-refer--right">
                                {isAuthenticated ?
                                    accountData ? 
                                        accountData.clinics?.length > 0 ?
                                            <ReferForm accountData={accountData} referralInfo={doctorDetailsSingle.referralInfo} />
                                        :
                                            <Panel
                                                icon={<LockIcon />}
                                                title='To access this feature, please fill in the below:'
                                            >
                                                <CompleteRegisterForm 
                                                    accountData={accountData}
                                                    buttonText='Start Referring'
                                                />
                                            </Panel>
                                    : null
                                   
                                    :
                                    <div className="page-referpatient-refer--login">
                                        <div className="page-referpatient-refer--login-text">{parse(options?.static_texts.rp_popup_guest_access || '', G.HTML_PARSE_OPTIONS)}</div>
                                        <div className="page-referpatient-refer--login-buttons">
                                            <Link
                                                className={`btn btn-primary text-yellow full-width`}
                                                to={{
                                                    pathname: '/login',
                                                    state: { from: location }
                                                }}
                                            >
                                                Sign in or register
                                            </Link>
                                        </div>
                                    </div>
                                }
                                
                            </Section>
                        </Section>
                    </PageContent>
                </>
                : null}
        </>
    );
};

export default ReferPage;
