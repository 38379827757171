import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';

const initialState = {};


export const logActivityInSheet = createAsyncThunk("ActivityInSheet/logItem", async (data,thunkAPI) => {

  try {
    const response = await axios.put(`api/v1/log/activity-in-sheet`, data);
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    resetLogReducer(){
      return initialState
    }
  },
  extraReducers: {
    [logActivityInSheet.pending]: (state, action) => {
      
    },
    [logActivityInSheet.fulfilled]: (state, action) => {
      // console.log(action.payload);
    },
    [logActivityInSheet.rejected]: (state, action) => {
    }
  }
});

export const { resetLogReducer } = logSlice.actions
export default logSlice.reducer;

