import React from 'react';
import { Word } from '../Word';

const Checkbox = (props) => {
  
  return (
    <div className="form-group">
      <label className="container-checkbox">
        {props.title ? (
            <Word value={props.title} />
        ) : null}
        <input 
          id = {props.name}
          name={props.name}
          type={"checkbox"}
          onChange={props.handleChange} 
          checked={props.isChecked}
          required={props.isRequired}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default React.memo(Checkbox);
