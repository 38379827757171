import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "../../store/features/slice-alerts";
import { fetchDoctorsFilter, selectFilterItem } from "../../store/features/slice-doctors";

import { Word } from '../Word';
import Image from '../Image';
import Button from "../Button";
import G from "../../app/globals";


const ContactCard = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handlePhoneClick = (phone) => {
        window.location.href = `tel:${phone}`;
    }

    const handleAddressClick = (address) => {
        if(props.isDesktop){
            window.open(`https://www.google.com/maps?q=${address}`);
        }
        else{
            window.location.href = `https://www.google.com/maps?q=${address}`;
        } 
    }
    const handleFaxClick = (number) => {
        G.COPY_STRING_TO_CLIPBOARD(number);
        dispatch(toast(
            {
              message: 'Fax number has been copied to the clipboard',
              type: 'success',
              timeout: 5000
            }
        ))
    }

    const handleViewDoctorsClick = () => {
        dispatch(selectFilterItem({ data: {label: props.title, value: props.slug}, filterItem: 'doctor_centre', filter: 'rp' }));
        dispatch(fetchDoctorsFilter('rp')).then(() => {
            history.push(`/refer-patient`,  { source: 'contact' });
          });
    }
    return (
        <div className={`card card-contact${props.minimal ? ' is-minimal' : ''}`}>
            {props.image ? 
                <Image 
                    className={`card-contact--figure` }
                    src={props.image} 
                    alt={props.title ? props.title : ""}
                />
            : null}
            <div className="card-contact--content">
                {props.title ? <h4 className={`card-contact--title${props.minimal ? ' is-minimal' : ''}`}>{props.title}</h4> : null}
                {props.phone ? 
                    <div className="card-contact--item card-contact--phone" onClick={() => handlePhoneClick(props.phoneFotmatted)}>
                        <div className="card-contact--item-label card-contact--phone-label"><Word value="Phone" /></div>
                        <div className="card-contact--item-value card-contact--phone-value">{props.phone}</div>
                    </div>
                : null}
                {props.fax ? 
                    <div className="card-contact--item card-contact--fax" onClick={() => handleFaxClick(props.fax)}>
                        <div className="card-contact--item-label card-contact--fax-label"><Word value="Fax" /></div>
                        <div className="card-contact--item-value card-contact--fax-value">{props.fax}</div>
                    </div>
                : null}
                {props.address ? 
                    <div className="card-contact--item card-contact--address" onClick={() => handleAddressClick(props.address)}>
                        <div className="card-contact--item-label card-contact--address-label"><Word value="Address" /></div>
                        <div className="card-contact--item-value card-contact--address-value">{props.address}</div>
                    </div>
                : null}
                {props.slug ? 
                    <Button
                        type={"button"}
                        className="btn-primary full-width-m"
                        text="View Doctors"
                        Clicked={handleViewDoctorsClick}
                    />
                : null}
            </div>
        </div>
    );
};

export default React.memo(ContactCard);