import React, { useEffect } from "react";
import { useDispatch ,useSelector } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
// import { useA2HS } from "react-use-a2hs";
import { validateToken } from "../store/features/slice-auth";
import { getAccountData } from "../store/features/slice-account"; //setDefaultFilters
import { setReduxHistory } from "../store/features/slice-settings";
import { setBreakPoint, closeModal } from "../store/features/slice-ui";
import { fetchTopics, fetchSpecialties, fetchOptions  } from "../store/features/slice-references";
import { fetchDoctors, fetchDoctorsTags, fetchDoctorsSpecialInterests  } from "../store/features/slice-doctors";
import { fetchCentresLocations, fetchCentresLocationsN, fetchCentres, fetchCentresTechniques, fetchCentresTreatments, fetchCentresNearest } from "../store/features/slice-centres";
import Header from "../components/Header";
import Loading from "../components/Loading";
import Toasts from "../components/Alerts/Toasts";
import BottomMenu from "../components/MenuBottom";
import SideMenu from "../components/SideMenu";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
// import AA2HS from "../components/AA2HS";
import G from "./globals";
// import APP_DATA from '../../package.json';


const Layout = (props) => {
  const location = useLocation();
  const history = useHistory();
  const loadingCounter = useSelector((state) => state.ui.loadingCounter);
  const pageClass = useSelector((state) => state.ui.pageClass);
  const modal = useSelector((state) => state.ui.modal);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isValidating = useSelector((state) => state.auth.isValidating);
  const accountData = useSelector((state) => state.account.data);
  const headerConfig = useSelector((state) => state.ui.header);
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const toasts = useSelector((state) => state.alerts.toasts);
  const special_interests = useSelector((state) => state.doctors.taxonomies.special_interests);
  const fields = useSelector((state) => state.doctors.taxonomies.fields);
  const topics = useSelector((state) => state.references.topics);
  const specialities = useSelector((state) => state.references.specialities);
  const options = useSelector((state) => state.references.options);
  const doctors = useSelector((state) => state.doctors.ids);
  const centres = useSelector((state) => state.centres.ids);
  const centresNearest = useSelector((state) => state.centres.nearest);
  const locations = useSelector((state) => state.centres.taxonomies.locations);
  const locationsN = useSelector((state) => state.centres.taxonomies.locationsN);
  const treatments = useSelector((state) => state.centres.taxonomies.treatments);
  const techniques = useSelector((state) => state.centres.taxonomies.techniques);

  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  // console.log('isDesktop?', isDesktop, breakPoint, G.TABLET_BREAKPOINTS, !G.TABLET_BREAKPOINTS.includes(breakPoint));
  // const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    // console.log('breakPoint', breakPoint);
  }, []);

  const dispatch = useDispatch();
  // const [promptEvent, promptToInstall] = useA2HS();

  useEffect(() => {
    const handleWindowResize = () => dispatch(setBreakPoint(
      G.GET_BREAKPOINT()
    ));
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [dispatch, breakPoint]);

  // useEffect(() => {
  //   if(isAuthenticated){
  //     dispatch(setDefaultFilters());
  //   }
  // }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if(isAuthenticated && !accountData){
      dispatch(getAccountData());
    }
  }, [dispatch, isAuthenticated, accountData])

  useEffect(() => {
    if((!topics)){
      dispatch(fetchTopics());
    }
  }, [dispatch, topics]);

  useEffect(() => {
    if((!specialities)){
      dispatch(fetchSpecialties());
    }
  }, [dispatch, specialities]);

  useEffect(() => {
    if((!options)){
      dispatch(fetchOptions());
    }
  }, [dispatch, options]);

  useEffect(() => {
    if((!doctors)){
      dispatch(fetchDoctors());
    }
  }, [dispatch, doctors]);

  useEffect(() => {
    if(!special_interests){
      dispatch(fetchDoctorsSpecialInterests())
    }
  }, [dispatch, special_interests]);

  useEffect(() => {
    if(!fields){
      dispatch(fetchDoctorsTags())
    }
  }, [dispatch, fields]);

  useEffect(() => {
    if((!centres)){
      dispatch(fetchCentres());
    }
  }, [dispatch, centres]);

  useEffect(() => {
    if((!centresNearest && isAuthenticated && accountData)){
      dispatch(fetchCentresNearest(accountData.postcode));
    }
  }, [dispatch, centresNearest, isAuthenticated, accountData]);

  useEffect(() => {
    if(!locations){
      dispatch(fetchCentresLocations())
    }
  }, [dispatch, locations]);

  useEffect(() => {
    if(!locationsN){
      dispatch(fetchCentresLocationsN())
    }
  }, [dispatch, locationsN]);

  useEffect(() => {
    if(!treatments){
      dispatch(fetchCentresTreatments())
    }
  }, [dispatch, treatments]);

  useEffect(() => {
    if(!techniques){
      dispatch(fetchCentresTechniques())
    }
  }, [dispatch, techniques]);

  useEffect(() => {
    dispatch(setReduxHistory({...history}));
    dispatch(closeModal());
  }, [dispatch, location.pathname, history]);


  useEffect(() => {
    if(isAuthenticated && isValidating === false){
      dispatch(validateToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname, isAuthenticated])

//   useEffect(() => {
//     if(isProduction){
//         fetch('/meta.json')
//             .then((response) => response.json())
//             .then((meta) => {
//                 const latestVersion = meta.version || APP_DATA.version;
//                 const currentVersion = APP_DATA.version;
//                 if(latestVersion !== currentVersion){
//                     window.location.reload(true);
//                 }
//         });
//     }
// }, [location.pathname, isProduction])


  return (
    <>
      {loadingCounter > 0 ? <Loading className="full" /> : null}
      {toasts && toasts.length > 0 ? <Toasts toastList={toasts} /> : null}
      {headerConfig.show ? 
        <Header 
          colour={headerConfig.colour}
          logo={headerConfig.logo}
          profile={headerConfig.profile}
          search={headerConfig.search}
          back={headerConfig.back}
          close={headerConfig.close}
          backTo={headerConfig.backTo}
          favourites={headerConfig.favourites}
        /> 
      : null }
      <main className={`main ${pageClass}`}>
        {breakPoint ? 
            isDesktop && headerConfig.sideNav ?
              <div className="main--inner">
                <div className="main--sidebar"><SideMenu /></div>
                <div className="main--content">{props.children}</div>
              </div>
            : props.children 
        : null}
        
      </main>
      {breakPoint ? (isDesktop || !headerConfig.sideNav) ? null  : <BottomMenu /> : null}
      {breakPoint ? (isDesktop) ? <Footer />  : null : null}
      <Modal isOpen={modal.status}>
          {modal.content}
      </Modal>
      {/* {promptEvent && ( <AA2HS fn={promptToInstall} /> )} */}
    </>
  );
};

export default Layout;
