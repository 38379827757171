import { useHistory, useLocation } from 'react-router-dom';
import Button from './Button';
import FlexibleListItem from './flexible/List/Item';


const RegisterLoginPrompt = (props) => {

    const history = useHistory();
    const location = useLocation();

    const items = [
        {
            "title": "Referrals",
            "description": "Refer to an Icon Specialist",
            "icon": "refer"
        },
        {
            "title": "Education",
            "description": "Reach your yearly CPD requirements",
            "icon": "education"
        },
        {
            "title": "Events",
            "description": "Gain access to exclusive Icon events",
            "icon": "calendar"
        },
        {
            "title": "News",
            "description": "Stay up to date with the latest industry news",
            "icon": "article"
        }
    ]

    return (
        <div className='register-login-prompt'>
            <div className='register-login-prompt--inner'>
                <div className='register-login-prompt--text'>
                    <h2>Sign up to read the rest of this article</h2>
                    <p>Get access to haematology and oncology related resources, education, events and clinical referral guidelines.</p>
                    <div className='register-login-prompt--text-buttons'>
                        <Button
                            className='btn btn-primary purple pill'
                            Clicked={() => history.push({ pathname: '/register', state: { from: location } })}
                            text="Register"
                        />
                        <Button
                            className='btn btn-outline blue pill'
                            Clicked={() => history.push({ pathname: '/login', state: { from: location } })}
                            text="Login"
                        />
                    </div>
                </div>
                <div className='register-login-prompt--items'>
                    {items.map((item, index) => (
                        <FlexibleListItem
                            key={index}
                            title={item.title}
                            description={item.description}
                            icon={item.icon}
                            type={'extra-small'}
                            bg={'grey'}
                        />
                    ))}
                </div>
            </div>
        </div>
    );

};

export default RegisterLoginPrompt;