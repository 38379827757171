import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import parse from 'html-react-parser';
import { openModal } from "../../store/features/slice-ui";
import { toast } from "../../store/features/slice-alerts";
import Button from "../Button";
import Link from "../Link";
import { Word } from "../Word";
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/phone.svg';
import { ReactComponent as FaxIcon } from '../../assets/images/icons/fax.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/images/icons/linkedin.svg';
import { ReactComponent as MailIcon } from '../../assets/images/icons/mail.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/icons/mobile.svg';
import G from "../../app/globals";



const SingleContact = (props) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    //Remove Foxo Integration
    // const accountData = useSelector((state) => state.account.data);
    const options = useSelector((state) => state.references.options);
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);

    //Remove Foxo Integration
    // const handleSecureOnlineChatClick = () => {
    //     let modalText = G.DEFAULT_DP_POPUP;
    //     if(isAuthenticated){
    //         if(accountData && accountData.foxoStatus === 'is_synced'){
    //             window.open(`${G.FOXO_URL}/contacts/${props.foxoId}`)
    //         }
    //         else{
    //             if(options && options.static_texts && options.static_texts.dp_popup_nofoxo_access){
    //                 modalText =  parse(options.static_texts.dp_popup_nofoxo_access, G.HTML_PARSE_OPTIONS);
    //             }
    //             dispatch(openModal(modalText))
    //         }
    //     }
    //     else{
    //         if(options && options.static_texts && options.static_texts.dp_popup_guest_access){
    //             modalText =  parse(options.static_texts.dp_popup_guest_access, G.HTML_PARSE_OPTIONS);
    //         }
    //         dispatch(openModal(modalText))
    //     }
    // }

    const handleLinkedInClick = (link) => {
        if (isDesktop) {
            window.open(link);
        }
        else {
            window.location.href = link;
        }
    }

    const handlePhoneClick = (phone) => {
        window.location.href = `tel:${phone}`;
    }

    const copyFaxNumber = (number) => {
        G.COPY_STRING_TO_CLIPBOARD(number);
        dispatch(toast(
            {
                message: 'Fax number has been copied to the clipboard',
                type: 'success',
                timeout: 5000
            }
        ))
    }

    const handleEmailMobileClick = (type) => {
        let link = "";
        let text = ""
        if (type === 'email') {
            link = `mailto:${props.email}`;
            text = `email`;
        }
        else {
            link = `tel:${props.mobileFormatted}`;
            text = `phone`;
        }
        let content;
        if (isAuthenticated) {
            content = <>
                {options && options.static_texts && options.static_texts.doctor_phone_email_popup_doctor_nofoxo ?
                    <div className="modal--content-text">{parse(options.static_texts.doctor_phone_email_popup_doctor_nofoxo, G.HTML_PARSE_OPTIONS)}</div>
                    : null}
                <Link className={`btn btn-primary text-yellow full-width`} to={`/refer-patient/${props.slug}`}>Refer a patient</Link>
                <Button
                    type={"button"}
                    className="btn-primary text-yellow full-width"
                    text={`Continue to ${text} Doctor`}
                    Clicked={() => window.location.href = link}
                />
            </>;
        }
        else {
            content = <>
                {options && options.static_texts && options.static_texts.doctor_phone_email_popup_guest ?
                    <div className="modal--content-text">{parse(options.static_texts.doctor_phone_email_popup_guest, G.HTML_PARSE_OPTIONS)}</div>
                    : null}
                <Link className={`btn btn-primary text-yellow full-width`} to={`/login`}>Sign in or register</Link>
            </>;
        }
        dispatch(openModal(<div className="modal--content-with-btns">{content}</div>));
    }



    return (
        <div className="page-doctor-single--contact">
            {/* Remove Foxo Integration */}
            {/* {props.foxoId ? 
                <Button 
                    className="btn-primary text-yellow full-width"
                    text="Secure online chat"
                    Clicked={handleSecureOnlineChatClick}
                />
            : null} */}
            <div className="page-doctor-single--contact-details">
                {props.phone && props.phoneFormatted ?
                    <div className="page-doctor-single--contact-details__item" onClick={() => handlePhoneClick(props.phoneFormatted)}>
                        <span><PhoneIcon /></span>
                        {props.phone}
                    </div>
                    : null}
                {props.mobile && props.mobileFormatted ?
                    <div className="page-doctor-single--contact-details__item" onClick={() => handleEmailMobileClick("phone")}>
                        <span><MobileIcon /></span>
                        {isAuthenticated ? props.mobile : G.HIDE_PHONE(props.mobile)}
                    </div>
                    : null}
                {props.email ?
                    <div className="page-doctor-single--contact-details__item" onClick={() => handleEmailMobileClick("email")}>
                        <span><MailIcon /></span>
                        {isAuthenticated ? props.email : G.HIDE_EMAIL(props.email)}
                    </div>
                    : null}
                {props.fax ?
                    <div className="page-doctor-single--contact-details__item" onClick={() => copyFaxNumber(props.fax)}>
                        <span><FaxIcon /></span>
                        {props.fax}
                    </div>
                    : null}
                {props.linkedinUrl ?
                    <div className="page-doctor-single--contact-details__item" onClick={() => handleLinkedInClick(props.linkedinUrl)}>
                        <span><LinkedInIcon /></span>
                        <Word value="Connect on LinkedIn" />
                    </div>
                    : null}
            </div>
        </div>
    );
};

export default SingleContact;