import React from 'react';

const PageContent = (props) => {
    return (
        <div className={`page-content${props.className ? ' '+props.className : ''}`}>
            <div className="page-content--inner">
                {props.children}
            </div>
        </div>
    );
};
export default React.memo(PageContent);