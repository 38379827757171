import React, {useState} from 'react';
import { useLocation } from "react-router-dom";
import Link  from '../Link';
import Image  from '../Image';
import { ReactComponent as ArrrowRightIcon } from '../../assets/images/icons/chevron-right.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/download.svg';
import { ReactComponent as ArrrowDownIcon } from '../../assets/images/icons/chevron-down.svg';
import { ReactComponent as ArrrowUpIcon } from '../../assets/images/icons/chevron-up.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';
import { ReactComponent as StarSolidIcon } from '../../assets/images/icons/star_solid.svg';



const CTACard = (props) => {
    const location = useLocation();
    const [isOpen, setOpen] = useState(false);
    return (
        <div className={`card card-cta${props.isDisabled ? ' is-disabled' : ''}`}>
            <Link 
                className={`card-cta--main${props.isCentered ? ' is-centered' : ''}${props.isBordered ? ' is-bordered' : ''}${props.Clicked ? ' is-clickable' : ''}${props.isSmall ? ' is-small' : ''}${props.iconBg ? ' has-icon-bg'  : ' has-icon-nobg'}`} 
                to={props.Clicked || !props.link || props.isDisabled  ? null : {pathname: props.link, state: { prevPath: location.pathname }}} 
                onClick={props.Clicked ? props.Clicked : null}
            >
                {props.image ? 
                    <Image 
                        className={`card-cta--figure`}
                        src={props.image} 
                        alt={props.title ? props.title : ""} 
                    />
                : props.svg ? 
                        <figure className={`card-cta--figure`}>
                            <div className={`card-cta--figure-svg${props.svgBg ? ' '+props.svgBg : ''}${props.svgBig ? ' big' : ''}`}>{props.svg}</div>
                        </figure> 
                    : null}
                <div className={`card-cta--content ${props.icon ? 'has-icon' : ''}`}>
                    {props.tag && props.tag ? 
                        <div className="text text--tag card-cta--tag dark">
                            {props.tag}
                        </div>
                    : null}
                    {props.tags && props.tags.length > 0 ? 
                        <div className="card-cta--tags">
                            {props.tags.map((tag, index) => {
                                return <div className="text text--tag bold" key={index}>
                                            {tag.name}
                                        </div>
                            })}
                        </div>
                    : null}
                    {props.title ? <h4 className="card-cta--title">
                        {props.title}
                        {props.isFavourite ? <StarSolidIcon /> : null}
                    </h4> : null}
                    {props.subtitle ? 
                        <div className="text card-cta--subtitle">
                            {props.subtitle}
                        </div>
                    : null}
                </div>
                {props.icon ?  
                <div className={`card-cta--icon${props.iconBg ? ' '+props.iconBg  : ' no-bg'}`}>
                    {props.icon === 'chevron_right' ? <ArrrowRightIcon /> : null}
                    {props.icon === 'download' ? <DownloadIcon className={props.iconColour ? props.iconColour : null}  /> : null}
                    {props.icon === 'completed' ? <CheckIcon className={`big${props.iconColour ? ' '+props.iconColour : ''}`} /> : null}
                </div>
                : null}
            </Link>
            {props.specialInterests && props.specialInterests.length > 0 ? 
            <div className="card-cta--specialinterests">
                <div className="card-cta--specialinterests-title" onClick={() => setOpen(!isOpen)}>
                See my special interests
                {isOpen ? <ArrrowUpIcon /> : <ArrrowDownIcon />}
                </div>
                {isOpen ? 
                    <div className="card-cta--specialinterests-content">
                        {props.specialInterests.map((specialInterest, index) => {
                                return <div className={`text text--tag white`} key={index}>
                                            {specialInterest.title}
                                        </div>
                        })}
                    </div>
                : null}
            </div> 
            : null}
        </div>
    );
};

export default React.memo(CTACard);