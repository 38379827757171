import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageClass, setUpHeader } from "../store/features/slice-ui";
import { resetErrors } from "../store/features/slice-api";
import { forgotPass } from "../store/features/slice-account";
import { toast } from "../store/features/slice-alerts";
import PageHead from "../components/PageHead";
import PageContent from "../components/PageContent";
import Input from "../components/Form/Input";
import Button from "../components/Button";
import {Word} from "../components/Word";

const ForgotPassword = () => {

  const dispatch = useDispatch();
  const errors = useSelector((state) => state.api.errors.forgot_password);
  useEffect(() => {
    dispatch(setPageClass("page-forgot-password no-bottom-menu"));
    dispatch(setUpHeader({
      back: true,
      sideNav: false
    }));
  });


  const initialFormState = {
    _email: ""
  };
  const [form, setState] = useState(initialFormState);

  const handleChange = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
      //empty previous errors from redux
      dispatch(resetErrors());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPass(form)).then((e) => {
      if(e.payload){
          dispatch(resetErrors());
          setState(initialFormState);
          dispatch(toast({
              message: e.payload.message,
              type: 'success'
          }));
      }
    });
  };

  return (
    <>
    <PageHead 
      className="page-forgot-password--head"
      title="Account recovery"
    />
    <PageContent className="page-forgot-password--content">
      {errors && errors.length > 0 ? 
        <div className="form-errors">
        {errors.map((error, index) => (
            <div key={index} className="form-errors--item">{error.message}</div>
        ))}
        </div> 
      : null}
      <p><Word value="An email will be sent to you with instructions." /></p>
      <form onSubmit={handleSubmit}>
          <Input
            type={"email"}
            title={"Email"}
            name={"_email"}
            value={form._email}
            handleChange={handleChange}
            isRequired
          />
          <Button
            type={"submit"}
            className="btn-primary btn-blue text-yellow full-width"
            text="Recover account"
          />
        </form>
      </PageContent>
    </>
  );
};

export default ForgotPassword;
