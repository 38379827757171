import React, {useCallback} from 'react';
import { useDispatch } from "react-redux";
import { closeModal } from "../store/features/slice-ui";
import { ReactComponent as CloseIcon } from '../assets/images/icons/close.svg';



const Modal = (props) => {
    const dispatch = useDispatch();
    const escFunction = useCallback((event) => {
        if(event.keyCode === 27) {
            dispatch(closeModal());
        }
    }, [dispatch]);

    if(props.isOpen){
        document.addEventListener("keydown", escFunction, false);
    }
    else{
        document.removeEventListener("keydown", escFunction, false);
    }

    return (
        <>
        {props.isOpen ?
            <div className="modal">
                <div className="modal--backdrop" onClick={() => dispatch(closeModal())}></div>
                <div className="modal--box">
                    <div className="modal--close" onClick={() => dispatch(closeModal())}><CloseIcon /></div>
                    <div className="modal--content">{props.children}</div>
                </div>
            </div>
        : null}
        </>
    );
};

export default React.memo(Modal);