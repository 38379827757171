import parse from 'html-react-parser';
import Section from "../Section"
import Video from "../Video"
import Image from "../Image"
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg';
import { ReactComponent as StartIcon } from '../../assets/images/icons/star.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import G from "../../app/globals";

const ArticleHero = (props) => {
    return (
        <>
            {(props.article.video && !props.isLocked)  && (
                <Section className="page-single-article--hero">
                    <Video
                        vid={props.article.video.link ? props.article.video.link : props.article.video.file}
                        onProgress={props.onVideoProgress}
                    />
                </Section>
            )}
            <Section className="page-single-article--head has-border">
                {props.article.hero_image && (!props.article.video || (props.article.video && props.isLocked)) ?
                    <div className="page-single-article--head-image">
                        <Image
                            src={props.article.hero_image}
                            alt={props.article.title}
                        />
                    </div>
                    : null}
                <div className="page-single-article--head-content">

                    {props.article.format ?
                        <div className={`text text--category${props.isLmArticle ? ' green' : ' yellow'}`}>
                            {props.article.format_icon ? parse(props.article.format_icon) : null}
                            {props.article.format}
                        </div>
                        : null}
                    <h1 className="page-single-article--title">{parse(props.article.title)}</h1>
                    {props.article.completion_time || props.article.cpd || props.article.categories.find(item => item.slug === 'news') ?
                        <div className="page-single-article--d-tags">
                            {props.article.categories.find(item => item.slug === 'news') && props.article.published_date ?
                                <div className="text text--tag">
                                    {<CalendarIcon />}
                                    {props.article.published_date}
                                </div>
                                : null}
                            {props.article.completion_time && props.article.completion_time.time ?
                                <div className="text text--tag">
                                    {<ClockIcon />}
                                    {G.COMPLETION_TIME_TEXT(props.article.completion_time)}
                                </div>
                                : null}
                            {props.article.cpd && props.article.cpd.type ?
                                <div className="text text--tag">
                                    {<StartIcon />}
                                    {G.CPD_TEXT(props.article.cpd)}
                                </div>
                                : null}
                        </div>
                        : null}
                    {/* {props.article.tags && props.article.tags.length > 0 ? 
                    // <div className="page-single-article--tags">
                    //   {props.article.tags.map((tag, index) => {
                    //           return <div className={`text text--category blue`} key={index}>
                    //                       {tag.name}
                    //                   </div>
                    //   })}
                    // </div>
                  : null} */}
                </div>
            </Section>
        </>
    );
};

export default ArticleHero;