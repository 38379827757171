import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import { resetCpdReducer } from "./slice-cpd";
import axios from '../../services/apiService';

const initialState = {
  list: {
    'clinical-article': {
      articles: null,
      pagination: null
    },
    'treatment-pathways': {
      articles: null,
      pagination: null
    },
    'treatment-techniques': {
      articles: null,
      pagination: null
    },
    'research': {
      articles: null,
      pagination: null
    },
    'news': {
      articles: null,
      pagination: null
    }
  },
  taxonomies: {
    topics: null
  },
  filters:{
    'clinical-article':{
      topics: [],
      s: null
    },
    'treatment-pathways':{
      topics: [],
      s: null
    },
    'treatment-techniques':{
      topics: [],
      s: null
    },
    'research':{
      topics: [],
      s: null
    },
    'news':{
      topics: [],
      s: null
    }
  },
  single: null
};


export const fetchArticles = createAsyncThunk("articles/getArticles", async (parameters,thunkAPI) => {
  let page = parameters && parameters.page ? parameters.page : 1;
  let ppp = parameters && parameters.ppp ? parameters.ppp : 25;
  let category = parameters && parameters.category ? parameters.category : 'clinical-article';
  let userPreferences = parameters && parameters.preferences ? parameters.preferences.map(function (obj) {return obj.value}) : null;
  let applyFilter = true;
  if (parameters && typeof parameters.applyFilter === 'boolean') {
      applyFilter = parameters.applyFilter;
  }
  let tax_query = [];
  if(thunkAPI.getState().articles.filters[category].topics && thunkAPI.getState().articles.filters[category].topics.length > 0){
    tax_query.push('topic:'+thunkAPI.getState().articles.filters[category].topics.map(function (obj) {return obj.value}))
  }
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/articles/${category}`, { 
      type : 'public',
      params: {
        page: page,
        ppp: ppp,
        lang: thunkAPI.getState().settings.language,
        tax_query: applyFilter ? tax_query : null,
        query: thunkAPI.getState().articles.filters[category].s ? thunkAPI.getState().articles.filters[category].s : null,
        topics_preferences: userPreferences ? userPreferences : null
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchArticleSingle = createAsyncThunk("articles/getSingleArticle", async (id,thunkAPI) => {

  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/article/${id}`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const completeArticle = createAsyncThunk("articles/logComplete", async (data,thunkAPI) => {
  if(data.category !== 'clinical-article' ){
    thunkAPI.dispatch(setLoadingStart());
  }
  
  try {
    const response = await axios.get(`api/v1/log/article-complete`, {params: data});
    if(data.category !== 'clinical-article' ){
      thunkAPI.dispatch(setLoadingEnd());
    }
    thunkAPI.dispatch(resetCpdReducer());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    if(data.category !== 'clinical-article' ){
      thunkAPI.dispatch(setLoadingEnd());
    }
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    selectFilterItem(state, action) {
      const { data, filterItem, filter } = action.payload
      state.filters[filter][filterItem] = data;
    },
    clearFilterItems(state, action) {
      state.filters[action.payload] = {
        topics: [],
        s: null  
      };
    },
    clearSingleArticle(state){
      state.single = null;
    },
    resetArticlesReducer(){
      return initialState
    }
  },
  extraReducers: {
    [fetchArticles.pending]: (state, action) => {
      
    },
    [fetchArticles.fulfilled]: (state, action) => {
      if(action.meta && action.meta.arg){
        state.list[action.meta.arg.category]['articles'] = action.payload.articles;
        state.list[action.meta.arg.category]['pagination'] = action.payload.pagination;
      }
    },
    [fetchArticles.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchArticleSingle.pending]: (state, action) => {
      
    },
    [fetchArticleSingle.fulfilled]: (state, action) => {
      state.single = action.payload.article
    },
    [fetchArticleSingle.rejected]: (state, action) => {
      console.log(action);
    },
    [completeArticle.pending]: (state, action) => {
      
    },
    [completeArticle.fulfilled]: (state, action) => {
      
    },
    [completeArticle.rejected]: (state, action) => {
    }
  }
});

export const { selectFilterItem, clearFilterItems, resetArticlesReducer, clearSingleArticle} = articlesSlice.actions
export default articlesSlice.reducer;

