import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';

const initialState = {
  item: null
};


export const fetchQandaItem = createAsyncThunk("qa/getItem", async (id,thunkAPI) => {

  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/qa/${id}`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const logQandaItem = createAsyncThunk("qa/logItem", async (data,thunkAPI) => {

  //thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.put(`api/v1/log/qa`, data);
    //thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    // thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


const qaSlice = createSlice({
  name: "qa",
  initialState,
  reducers: {
    resetQAReducer(){
      return initialState
    }
  },
  extraReducers: {
    [fetchQandaItem.pending]: (state, action) => {
    },
    [fetchQandaItem.fulfilled]: (state, action) => {
      state.item = action.payload.qa
    },
    [fetchQandaItem.rejected]: (state, action) => {
      console.log(action);
    },
    [logQandaItem.pending]: (state, action) => {
      
    },
    [logQandaItem.fulfilled]: (state, action) => {
      // console.log(action.payload);
    },
    [logQandaItem.rejected]: (state, action) => {
    }
  }
});

export const { resetQAReducer } = qaSlice.actions
export default qaSlice.reducer;

