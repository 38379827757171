import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { updateMarketing } from "../../store/features/slice-account";

import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import Checkbox from "../../components/Form/Checkbox";
import UserSummary from "../../components/UserSummary";
import G from "../../app/globals";


const Marketing = () => {
    const dispatch = useDispatch();
    const [selectedMarketing, setSelectedMarketing] = useState([]);
    const accountData = useSelector((state) => state.account.data);
    useEffect(() => {
      dispatch(setPageClass("page-preferences"));
      dispatch(setUpHeader({
        show: true,
        back: true,
        profile: true,
        search: true
      }));
    }, [dispatch]);

    useEffect(() => {
      if(accountData && accountData.marketing){
        setSelectedMarketing(accountData.marketing);
      }
    }, [accountData])

    const handleChange = (e) => {
      let checkbox_arr = [];
      if(e.target.checked){
        checkbox_arr = [...selectedMarketing, e.target.name]
      }
      else{
        checkbox_arr = selectedMarketing.filter(item => item !== e.target.name)
      }
      setSelectedMarketing(checkbox_arr);
    };

    const handleMarketingSave = () => {
      dispatch(updateMarketing({_marketing: selectedMarketing}));
    }

    return (
      <>
        {accountData ? 
        <>
          <PageHead 
            className="page-marketing--page-head"
            title="Marketing"
          />
          <PageContent className="page-marketing--page-content">
            <Section className="split-content w-40-60">
                <Section><UserSummary save={handleMarketingSave} /></Section>
                <Section className="page-marketing--list">
                <div className="page-marketing--list-intro">Please select your email marketing preferences</div>
                  {G.MARKETING_OPTIONS && G.MARKETING_OPTIONS.length > 0 ?
                    G.MARKETING_OPTIONS.map((option, index) => {
                      return <Checkbox
                              key={index}
                              title={option.label}
                              name={option.value}
                              handleChange={handleChange}
                              isChecked={selectedMarketing && selectedMarketing.includes(option.value) ? true : false}
                            />
                    })
                  : null}
                </Section>
            </Section>
          </PageContent>
        </>
        : null}
      </>
    );
};

export default Marketing;
