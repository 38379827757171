import React from 'react';

const Panel = ({title, icon, children}) => {
    return (
        <div className='panel'>
            {(title || icon) && (
                <div className='panel--header'>
                    {icon}
                    {title}
                </div>
            )}
            <div className='panel--content'>
                {children}
            </div>
        </div>
    )
};

export default Panel;