import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { clinicalTrialEOI  } from "../store/features/slice-forms";
import { resetErrors } from "../store/features/slice-api";
import Input from "./Form/Input";
import Checkbox from "./Form/Checkbox";
import TextArea from "./Form/TextArea";
import Button from "./Button";
import Turnstile from './Turnstile';
import G from "../app/globals";


const ClinicalTrialsEOIForm = ({props}) => {
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountData = useSelector((state) => state.account.data);
  const errorsRef = useRef();
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.api.errors.ct_eoi);
  const initialFormState = {
    _name: "",
    _location: "",
    _phone: "",
    _email: "",
    _message: "",
    _consent: false,
    _trial_name: props?.laymanTitle
  };
  const [form, setForm] = useState(initialFormState);


  useEffect(() => {
    if(errors && Object.values(errors).length > 0){
      errorsRef.current?.scrollIntoView({behavior: "smooth"});
    }
  }, [errors, errorsRef])

  useEffect(() => {
    if(isAuthenticated && accountData){
      setForm((f) => ({...f, 
        _name: accountData.salutation + ' ' + accountData.firstName + ' ' + accountData.lastName,
        _phone: accountData.mobile,
        _email: accountData.email,
      }));
    }
  }, [isAuthenticated, accountData]);


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const setTurnstileToken = (token) => {
    setForm({
      ...form,
      cf_turnstile_response: token,
    });
  };

  const handleSubmit = (e) => {
    if(e){
      e.preventDefault();
    }
    dispatch(clinicalTrialEOI({form: form})).then(e => {
      if(e.payload && e.payload.success){
        dispatch(resetErrors());
        setForm(initialFormState);
        G.RESET_SCROLL();
        history.push(`/research/clinical-trials/eoi-success`);
      }
    });
  };

  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);

  return (
    <>
      <div className="clinical-trials-eoi-form">
        <div ref={errorsRef}></div>
        {errors && Object.values(errors).length > 0 ?
          <div className="form-errors">
            { Object.values(errors).map((error, index) => (
              <div key={index} className="form-errors--item">{error}</div>
            ))}
          </div>
          : null}
        <form onSubmit={handleSubmit}>
                <Input
                  type={"text"}
                  name={"_name"}
                  title={"Name"}
                  value={form._name}
                  handleChange={handleChange}
                  isRequired
                />
                <Input
                  type={"text"}
                  name={"_location"}
                  title={"Location"}
                  value={form._location}
                  handleChange={handleChange}
                />
                <Input
                  type={"text"}
                  name={"_phone"}
                  title={"Phone Number"}
                  value={form._phone}
                  handleChange={handleChange}
                />
                <Input
                  type={"email"}
                  name={"_email"}
                  title={"Email"}
                  value={form._email}
                  handleChange={handleChange}
                  isRequired
                />
                <TextArea
                    title={"How can we help?"}
                    name={"_message"}
                    value={form._message}
                    handleChange={handleChange}
                />
                <Checkbox 
                  title="I acknowledge I have read and understood the Group Privacy Policy and agree to the collection, use and handling of my Personal Data in accordance with the policy."
                  name={"_consent"}
                  handleChange={handleChange}
                  isChecked={form._consent}
                  isRequired
                />
                <Turnstile setToken={setTurnstileToken} />
                <Button
                  type={"submit"}
                  className="btn btn-primary purple text-yellow full-width"
                  text="Submit"
                />
              </form>
      </div>
    </>
  );
};

export default ClinicalTrialsEOIForm;