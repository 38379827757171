import React from 'react';
import { useSelector } from "react-redux";
import { Word } from './Word';
import Select from './Form/Select';
import G from "../app/globals";




const Tabs = (props) => {
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
    let selectOptions = props.items ? props.items.map(item => ({"label": item.text, "value": item.index, "clicked": item.Clicked})) : [];
    let selectedOption = selectOptions.filter(item => item.value === props.activeItem);
    const handleSelectChange = (payload) => {
        payload.clicked()
    }
    return (
        props.items && props.items.length > 0 ?
        <>
            {!isDesktop && props.items.length > 4 ? 
            <Select 
                name="tabs"
                id={'tabs'}
                options={selectOptions}
                value={selectedOption}
                onChange={handleSelectChange}
            />
            : 
            <div className={`tabs${props.className ? ' '+props.className : ''}`}>
                {props.items.map((item, index) => {
                    return <div className={`tabs--item${props.activeItem === item.index ? ' active' : ''}${item.disabled ? ' is-disabled' : ''}`} key={index} onClick={item.Clicked}>
                                <Word value={item.text} />
                            </div>
                })}
            </div>
            }
        </>
        : null
    );
};

export default Tabs;