import React from 'react';
import Turnstile from 'react-turnstile';



const TurnstileWidget = ({ setToken }) => {
    
    const turnstileKey = "0x4AAAAAAAfKjl4ETA4nh9l1";
    return (
        <Turnstile
            sitekey={turnstileKey}
            onVerify={setToken}
            retry="auto"
        />
    );

};

export default TurnstileWidget;