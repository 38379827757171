import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse  from 'html-react-parser';
import { setPageClass, setUpHeader } from "../store/features/slice-ui";
import { fetchPage  } from "../store/features/slice-references";
import PageHead from "../components/PageHead";
import PageContent from "../components/PageContent";
import Section from "../components/Section";
import G from "../app/globals";


const GenericPage = (props) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => {
    let value = null
    if(props.id){
      switch(props.id){
        case 739: value =  state.references.disclaimer;
        break;
        case 741: value =  state.references.privacy;
        break;
        case 1756: value =  state.references.about;
        break;
        case 74223: value =  state.references.practice_based_eoi_success;
        break;
        case 72597: value =  state.references.trial_eoi_success;
        break;
        case 64857: value =  state.references.become_doctor_success;
        break;
        default: value = null;
      }
    }
    return value;
    
  });
  useEffect(() => {
    dispatch(setPageClass("page-generic"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if(!page){
      dispatch(fetchPage(props.id))
    }
  }, [dispatch, page, props.id]);

  return (
    <>
      <PageHead className="page-generic--page-head" 
      title={page ? parse(page.title) : null}
      bg="blue"
      />
      <PageContent className="page-generic--content">
        <Section className="page-generic--the-content">
          {page ? parse(page.content, G.HTML_PARSE_OPTIONS) : null}
        </Section>
      </PageContent>
    </>
  );
};

export default GenericPage;
