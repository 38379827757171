import React, { useEffect } from "react";
import parse from "html-react-parser";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUpHeader } from "../../store/features/slice-ui";
import { fetchPageWelcome  } from "../../store/features/slice-references";
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import Button from '../../components/Button';

// Flexible Modules
import FlexibleVideo from '../../components/flexible/Video';
import FlexibleList from '../../components/flexible/List/List';
// import FlexibleAccordion from '../components/flexible/Accordion/Accordion';
import FlexibleCta from '../../components/flexible/Cta';
// import FlexibleCarousel from '../components/flexible/Carousel/Carousel';

const Welcome = () => {

    const history = useHistory();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const page = useSelector((state) => state.references.welcome);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setUpHeader({
            show: false,
            sideNav: false
          }));
      }, [dispatch]);

      useEffect(() => {
        if(!page){
          dispatch(fetchPageWelcome())
        }
      }, [dispatch, page]);

    return (
        <div style={{'overflow':'hidden', 'width': '100%'}}>
            <header className='header blue-dark welcome-header'>
                <div className='header--inner'>
                    <div className='header--logo' onClick={() => history.push('/')}><Logo /></div>
                    <div className='header--buttons'>
                        {!isAuthenticated &&
                            <Button 
                                className='btn btn-outline white pill'
                                Clicked={() => isAuthenticated ? history.push('/account') : history.push('/login')}
                                text={isAuthenticated ? 'My Account' : 'Log in' }
                            /> 
                        }
                        <Button 
                            className='btn btn-primary purple pill'
                            Clicked={() => isAuthenticated ? history.push('/account') : history.push('/register')}
                            text={isAuthenticated ? 'My Account' : 'Register' }
                        />
                    </div>
                </div>
            </header>

            <section className='welcome-intro'>
                <h1>{page?.modules?.intro?.title}</h1>
                {page?.modules?.intro?.description && parse(page.modules?.intro?.description)}
                <div className='welcome-bg-1'></div>
                <div className='welcome-bg-2'></div>
            </section>

            <section className='welcome-modules'>
    
                {page?.modules?.video && (
                    <FlexibleVideo
                        src={page.modules.video}
                        offset={true}
                        controls={false}
                        playing={true}
                        playsinline={true}
                        muted={true}
                        loop={true}
                    />
                )}
                {page?.modules?.list && (
                    <FlexibleList
                        title={page.modules.list.title}
                        description={page.modules.list.description}
                        items={page.modules.list.items}
                        cta={!isAuthenticated ? {
                            label: 'Register',
                            callback: () => history.push('/register')
                        } : null}
                    />
                )}

                {/* <FlexibleCarousel
                    title={'From your peers'}
                    options={{
                        slidesPerView: 1,
                        spacing: 0,
                        breakpoints: {
                            '(min-width: 520px)': {
                                slidesPerView: 2
                            },
                            '(min-width: 1200px)': {
                                slidesPerView: 3
                            }
                        }
                    }}
                    hasDots={true}
                    items={[
                        {
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum, sollicitudin elementum massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            image: {
                                src: '/temp/temp_dr.png',
                                alt: ''
                            },
                            name: 'Dr. James Testington',
                            position: 'MBBS (Hons), PhD, FRANZCR'
                        },
                        {
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum, sollicitudin elementum massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            image: {
                                src: '/temp/temp_dr.png',
                                alt: ''
                            },
                            name: 'Dr. James Testington',
                            position: 'MBBS (Hons), PhD, FRANZCR'
                        },
                        {
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum, sollicitudin elementum massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            image: {
                                src: '/temp/temp_dr.png',
                                alt: ''
                            },
                            name: 'Dr. James Testington',
                            position: 'MBBS (Hons), PhD, FRANZCR'
                        },
                        {
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum, sollicitudin elementum massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            image: {
                                src: '/temp/temp_dr.png',
                                alt: ''
                            },
                            name: 'Dr. James Testington',
                            position: 'MBBS (Hons), PhD, FRANZCR'
                        },
                        {
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget luctus ipsum, sollicitudin elementum massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            image: {
                                src: '/temp/temp_dr.png',
                                alt: ''
                            },
                            name: 'Dr. James Testington',
                            position: 'MBBS (Hons), PhD, FRANZCR'
                        }
                    ]}
                /> */}

                {/* <FlexibleAccordion 
                    title='FAQs'
                    items={[
                        {
                            title: 'Are there any subscription fees?',
                            content: 'Content 1'
                        },
                        {
                            title: 'Can the app be used to keep track of my CPD?',
                            content: 'Content 2'
                        },
                        {
                            title: 'What types of resources are available?',
                            content: 'Content 3'
                        }
                    ]}
                /> */}
                {page?.modules?.cta && (
                    <FlexibleCta
                        title={page.modules.cta.title}
                        list={page.modules.cta.list?.map(item => item.title)}
                        image={{
                            src: page.modules.cta.image?.url,
                            alt: page.modules.cta.image?.alt
                        }}
                        cta={!isAuthenticated ? {
                            label: 'Register',
                            callback: () => history.push('/register')
                        } : null}
                    />
                )}
            </section>
        </div>
    );

};
    
export default Welcome;