import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { fetchClinicalResourcesData } from "../../store/features/slice-indexes";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import CtaSubpageCard from "../../components/Cards/CtaSubPage";
import StandardCard from "../../components/Cards/Standard";
import Carousel from "../../components/Carousel";
import { Word } from '../../components/Word';
import Button from "../../components/Button";
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg';
import { ReactComponent as TreatmentPathwayIcon } from '../../assets/images/icons/treatment-pathway.svg';
import { ReactComponent as ReseachIcon } from '../../assets/images/icons/research.svg';
import { ReactComponent as TreatmentTechniqueIcon } from '../../assets/images/icons/treatment-technique.svg';
import { ReactComponent as NewsIcon } from '../../assets/images/icons/news.svg';
import G from '../../app/globals';


const ClinicalInformationIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clinicalResourcesContent = useSelector((state) => state.indexes.cr);
  const requestState = useSelector((state) => state.indexes.requests.cr);
  const accountData = useSelector((state) => state.account.data);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  useEffect(() => {
    dispatch(setPageClass("page-clinical"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    let userPref = accountData && accountData.preferences ? accountData.preferences : null;
    if(isAuthenticated){
      if (!clinicalResourcesContent && !requestState && userPref) {
        dispatch(fetchClinicalResourcesData({ preferences: userPref }));
      }
    }
    else{
      if (!clinicalResourcesContent && !requestState) {
        dispatch(fetchClinicalResourcesData({ preferences: null }));
      }
    }
  }, [dispatch, clinicalResourcesContent, requestState, isAuthenticated, accountData]);


  const renderTpArticles = () => {
    return clinicalResourcesContent && clinicalResourcesContent.articles_tp ? clinicalResourcesContent.articles_tp.map(item => (
      <StandardCard
          key={item.id}
          image={item.image_medium}
          title={parse(item.title)}
          stamp={item.format ? { 'title': item.format, 'colour': 'yellow', 'icon': item.format_icon ? item.format_icon : null } : null}
          link={`/article/${item.slug}`}
          completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
          cpd={G.CPD_TEXT(item.cpd)}
          className="keen-slider__slide"
          authors={item.authors}
      />
    )) : null
  }

  const renderTtArticles = () => {
    return clinicalResourcesContent && clinicalResourcesContent.articles_tt ? clinicalResourcesContent.articles_tt.map(item => (
      <StandardCard
          key={item.id}
          image={item.image_medium}
          title={parse(item.title)}
          stamp={item.format ? { 'title': item.format, 'colour': 'yellow', 'icon': item.format_icon ? item.format_icon : null } : null}
          link={`/article/${item.slug}`}
          completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
          cpd={G.CPD_TEXT(item.cpd)}
          className="keen-slider__slide"
          authors={item.authors}
      />
    )) : null
  }

  
  return (
    <>
      <PageHead 
        className="page-clinical--page-head"
        title="Clinical Information"
        bg="blue"
      />
      <PageContent className="page-clinical--page-content">
        <Section className="page-clinical--ctas">
            <CtaSubpageCard 
                title={<Word value="Treatment Pathways" />}
                subtitle={<Word value="Educate through" />}
                icon={<TreatmentPathwayIcon />}
                Clicked={() => history.push('/clinical-resources/treatment-pathways')}
            />
            <CtaSubpageCard 
                title={<Word value="Research" />}
                subtitle={<Word value="Connect through" />}
                icon={<ReseachIcon />}
                Clicked={() => history.push('/research')}
            />
            <CtaSubpageCard 
                title={<Word value="Treatment Techniques" />}
                subtitle={<Word value="Educate through" />}
                icon={<TreatmentTechniqueIcon />}
                Clicked={() => history.push('/clinical-resources/treatment-techniques')}
            />
            <CtaSubpageCard 
                title={<Word value="Latest News" />}
                subtitle={<Word value="Connect through" />}
                icon={<NewsIcon />}
                Clicked={() => history.push('/news')}
            />
        </Section>
        {clinicalResourcesContent && clinicalResourcesContent.featured ? 
          <Section className="page-clincal--featured-card">
            <h2><Word value="Featured" /></h2>
            <StandardCard 
              image={clinicalResourcesContent.featured.image}
              title={parse(clinicalResourcesContent.featured.title)}
              description={parse(clinicalResourcesContent.featured.excerpt)}
              className="is-featured"
              link={`/article/${clinicalResourcesContent.featured.slug}`}
            />
          </Section>
        : null}
        {clinicalResourcesContent && clinicalResourcesContent.articles_tp && clinicalResourcesContent.articles_tp.length > 0 ? 
          <Section className="page-clinical--tp" >
            <h2>
              <Word value="Treatment Pathways" />
              <Button 
                className="btn-plain text-black"
                text="View all"
                icon={<ArrowRight />}
                Clicked={() => history.push('/clinical-resources/treatment-pathways')}
              />
            </h2>
            <Carousel
            className="page-clinical--tp-carousel"
            options={{
              slidesPerView: 1, 
              spacing: 20,
              breakpoints: {
                '(min-width: 480px)': {
                  slidesPerView: 2
                },
                '(min-width: 1200px)': {
                  slidesPerView: 3
                }
              }
            }}
            hasDots
            >
              {renderTpArticles()}
            </Carousel>
          </Section>
        : null }
        {clinicalResourcesContent && clinicalResourcesContent.articles_tt && clinicalResourcesContent.articles_tt.length > 0 ? 
          <Section className="page-clinical--tt" >
            <h2>
              <Word value="Treatment Techniques" />
              <Button 
                className="btn-plain text-black"
                text="View all"
                icon={<ArrowRight />}
                Clicked={() => history.push('/clinical-resources/treatment-techniques')}
              />
            </h2>
            <Carousel
            className="page-clinical--tt-carousel"
            options={{
              slidesPerView: 1, 
              spacing: 20,
              breakpoints: {
                '(min-width: 480px)': {
                  slidesPerView: 2
                },
                '(min-width: 1200px)': {
                  slidesPerView: 3
                }
              }
            }}
            hasDots
            >
              {renderTtArticles()}
            </Carousel>
          </Section>
        : null }
      </PageContent>
    </>
  );
};

export default ClinicalInformationIndex;
