import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import CentresFilter from '../../components/Filters/CentresFilter';

const CentresFilterPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageClass("page-filter-centres"));
    dispatch(setUpHeader({
      show: true,
      back: true, 
      profile: true,
      search: true
    }));
  }, [dispatch]);

  return (
    <>
      <PageHead 
        className="page-filter-centres--page-head"
        title="Filter"
      />
      <PageContent className="page-filter-centres--page-content">
        <CentresFilter 
          className="page-filter-centres--content-wrapper pt-0" 
          pushAfterSubmit="/contact-icon"
        />
      </PageContent>
    </> 
    
  );
};

export default CentresFilterPage;
