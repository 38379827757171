import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse  from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { fetchPage  } from "../../store/features/slice-references";
import { reportIssue  } from "../../store/features/slice-forms";
import { resetErrors } from "../../store/features/slice-api";
import { toast } from "../../store/features/slice-alerts";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import Input from "../../components/Form/Input";
import Button from "../../components/Button";
import TextArea from "../../components/Form/TextArea";
import Turnstile from '../../components/Turnstile';




const Report = () => {
  const dispatch = useDispatch();
  const pageContent = useSelector((state) => state.references.report_issue);
  const errors = useSelector((state) => state.api.errors.report_issue);
  const initialFormState = {
    _name: "",
    _phone: "",
    _email: "",
    _message: ""
  };
  const [form, setForm] = useState(initialFormState);
  useEffect(() => {
    dispatch(setPageClass("page-report-issue"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if(!pageContent){
      dispatch(fetchPage(1768));
    }
  }, [dispatch, pageContent]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const setTurnstileToken = (token) => {
    setForm({
      ...form,
      cf_turnstile_response: token,
    });
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(reportIssue({form: form})).then(e => {
        if(e.payload && e.payload.success){
          dispatch(resetErrors());
          setForm(initialFormState);
          dispatch(toast({
              message: e.payload.message,
              type: 'success'
          }));
        }
      });
  };
  
  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);
  
  return (
    <>
      <PageHead 
        className="page-report-issue--page-head"
        title={pageContent ? parse(pageContent.title) : null}
        bg="blue"
      />
      {pageContent ? 
      <PageContent className="page-report-issue--page-content">
        <Section className="page-report-issue--intro">{parse(pageContent.content)}</Section>
        <Section className="page-report-issue--form">
            {errors && Object.values(errors).length > 0 ? 
                <div className="form-errors">
                {Object.values(errors).map((error, index) => (
                    <div key={index} className="form-errors--item">{error}</div>
                ))}
                </div> 
            : null}
            <form onSubmit={handleSubmit}>
              <Input
                type={"text"}
                name={"_name"}
                title={"Name"}
                value={form._name}
                handleChange={handleChange}
                isRequired
              />
              <Input
                type={"text"}
                name={"_phone"}
                title={"Phone"}
                value={form._phone}
                handleChange={handleChange}
                isRequired
              />
              <Input
                type={"email"}
                name={"_email"}
                title={"Email"}
                value={form._email}
                handleChange={handleChange}
                isRequired
              />
              <TextArea
                  title={"Message"}
                  name={"_message"}
                  value={form._message}
                  handleChange={handleChange}
                  isRequired
              />
              <Turnstile setToken={setTurnstileToken} />
              <Button
                type={"submit"}
                className="btn btn-primary text-yellow full-width-m"
                text="Submit"
              />
            </form>
          </Section>
      </PageContent>
  
      : null}
    </>
  );
};

export default Report;
