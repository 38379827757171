import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchClinicalTrialsFiltered, fetchClinicalTrialsFilters, selectFilterItem,  clearFilterItems} from "../../store/features/slice-clinical-trials";
import Section from "../Section";
import Button from "../Button";
import Select from '../Form/Select';
import Input from '../Form/Input';
import { Word } from "../Word";


const DoctorsFilter = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const taxonomies = useSelector((state) => state.clinicalTrials.taxonomies);
    const filters = useSelector((state) => state.clinicalTrials.filters);
    const [diagnosisesSelect, setDiagnosisesSelect] = useState(filters.diagnosis);
    const [treatmentTypeSelect, setTreatmentTypeSelect] = useState(filters.treatment_type);
    const [stateSelect, setStateSelect] = useState(filters.state);
    const [doctorsSelect, setDoctorsSelect] = useState(filters.doctors);
    const [keyword, setKeyword] = useState(filters.s ? filters.s : '');


    const handleDiagnosisesSelectChange = (payload) => {
        setDiagnosisesSelect(payload);
    };
    const handleTreatmentTypeSelectChange = (payload) => {
      setTreatmentTypeSelect(payload);
    };
    const handleStateSelectChange = (payload) => {
      setStateSelect(payload);
    };
    const handleDoctorSelectChange = (payload) => {
      setDoctorsSelect(payload);
    };
    const handleKeywordChange = (e) => {
      setKeyword(e.target.value);
    }

    const handleFilterSubmit = () => {
        dispatch(selectFilterItem( {data: diagnosisesSelect , filterItem: 'diagnosis'}));
        dispatch(selectFilterItem( {data: treatmentTypeSelect , filterItem: 'treatment_type'}));
        dispatch(selectFilterItem( {data: stateSelect , filterItem: 'state'}));
        dispatch(selectFilterItem( {data: doctorsSelect , filterItem: 'doctors'}));
        dispatch(selectFilterItem( {data: keyword , filterItem: 's'}));
        dispatch(fetchClinicalTrialsFiltered()).then(() => {
          if(props.pushAfterSubmit){
           history.push(props.pushAfterSubmit)
          }
        });
    };

    const handleClearFilter = () => {
      setDiagnosisesSelect(null);
      setTreatmentTypeSelect(null);
      setStateSelect(null);
      setDoctorsSelect(null);
      setKeyword('')
        if(filters.diagnosis || filters.treatment_type || filters.state || filters.doctors || filters.s){
          dispatch(clearFilterItems());
        }
    };

    useEffect(() => {
      if(!taxonomies){
        dispatch(fetchClinicalTrialsFilters());
      }
    }, [dispatch, taxonomies]);
    
    return (
        <Section className={`filter${props.className ? ' '+props.className : ''}`}>
          <div className="filter--content">
            {props.title ? <h2><Word value={props.title} /></h2> : null}
            <div className="filter--form">
              {taxonomies?.diagnosises?.length > 0 ? 
                    <Select 
                      name="diagnosis"
                      id={'diagnosis'}
                      title="Diagnosis"
                      options={taxonomies.diagnosises.map(item => ({ 'value': item, 'label': item }))}
                      onChange={handleDiagnosisesSelectChange}
                      value={diagnosisesSelect}
                      isMulti
                    />
              : null}
              {taxonomies?.treatmentTypes?.length > 0 ? 
                    <Select 
                      name="treatment_type"
                      id={'treatment_type'}
                      title="Treatment Type"
                      options={taxonomies.treatmentTypes.map(item => ({ 'value': item, 'label': item }))}
                      onChange={handleTreatmentTypeSelectChange}
                      value={treatmentTypeSelect}
                      isMulti
                    />
              : null}
              {taxonomies?.states?.length > 0 ? 
                    <Select 
                      name="state"
                      id={'state'}
                      title="State"
                      options={taxonomies.states.map(item => ({ 'value': item, 'label': item }))}
                      onChange={handleStateSelectChange}
                      value={stateSelect}
                    />
              : null}
              {taxonomies?.doctors?.length > 0 ? 
                    <Select 
                      name="doctors"
                      id={'doctors'}
                      title="Doctors"
                      options={taxonomies.doctors.map(item => ({ 'value': item.slug, 'label': item.name }))}
                      onChange={handleDoctorSelectChange}
                      value={doctorsSelect}
                      isMulti
                    />
              : null}
              <Input
                  type={"text"}
                  title={"Keyword"}
                  name={"keyword"}
                  value={keyword}
                  handleChange={handleKeywordChange}
              />
              {taxonomies ? 
                <div className="filter--actions">
                  <Button
                      type={"button"}
                      className="btn-primary text-yellow full-width"
                      text="Filter"
                      Clicked={handleFilterSubmit}
                      isDisabled={!diagnosisesSelect && !treatmentTypeSelect && !stateSelect && !doctorsSelect && keyword === '' ? true : false}
                  />
                  <Button
                    type={"button"}
                    className="btn-outline full-width"
                    text="Clear all"
                    Clicked={handleClearFilter}
                    isDisabled={!diagnosisesSelect &&  !filters.diagnosis && !treatmentTypeSelect &&  !filters.treatment_type && !stateSelect &&  !filters.states && !doctorsSelect &&  !filters.doctors && keyword === '' ? true : false}
                  />
                </div>
              : null}
            </div>
          </div>
        </Section>
    );
};

export default DoctorsFilter;