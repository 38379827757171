import React from 'react';
import Link  from '../Link';
import { ReactComponent as ArrrowRightIcon } from '../../assets/images/icons/chevron-right.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/check.svg';



const LmResourceCTACard =  React.forwardRef((props, ref) => {
    return (
            <Link 
                className={`card card-cta-lm-resource${props.isDisabled ? ' is-disabled' : ''}`} 
                to={!props.link || props.isDisabled  ? null : props.link}
                ref={ref}
            >
                {props.svg ? 
                    <figure className={`card-cta-lm-resource--figure`}>
                        <div className={`card-cta-lm-resource--figure-svg${props.svgBg ? ' '+props.svgBg : ''}${props.svgBig ? ' big' : ''}`}>{props.svg}</div>
                    </figure> 
                : null}
                <div className={`card-cta-lm-resource--content ${props.icon ? 'has-icon' : ''}`}>
                    {props.title ? <h4 className="card-cta--title">{props.title}</h4> : null}
                </div>
                <div className={`card-cta-lm-resource--icon${props.isCompleted ? ' no-bg'  : ' green'}`}>
                    {props.isCompleted  ? <CheckIcon className={`big green`} />  : <ArrrowRightIcon />}
                </div>
            </Link>
    );
});

export default LmResourceCTACard;