import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse  from 'html-react-parser';
import { useLocation, useHistory } from 'react-router-dom';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { fetchPage  } from "../../store/features/slice-references";
import { practiceBasedEoi  } from "../../store/features/slice-forms";
import { resetErrors } from "../../store/features/slice-api";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import Input from "../../components/Form/Input";
import Button from "../../components/Button";
import Select from '../../components/Form/Select';
import Turnstile from '../../components/Turnstile';




const PracticeBased = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pageContent = useSelector((state) => state.references.practice_based);
  const errors = useSelector((state) => state.api.errors.practice_based_eoi);
  const specialties = [
    {
      label: 'Clinical Haematology',
      value: 'Clinical Haematology'
    },
    {
      label: 'Medical Oncology',
      value: 'Medical Oncology'
    },
    {
      label: 'Palliative Care',
      value: 'Palliative Care'
    },
    {
      label: 'Radiation Oncology',
      value: 'Radiation Oncology'
    },
  ];
  const [specialtySelected, setSpecialtySelected] = useState('');
  const states = [
    {
      label: 'QLD',
      value: 'QLD'
    },
    {
      label: 'NSW',
      value: 'NSW'
    },{
      label: 'ACT',
      value: 'ACT'
    },
    {
      label: 'VIC',
      value: 'VIC'
    },
    {
      label: 'TAS',
      value: 'TAS'
    },
    {
      label: 'SA',
      value: 'SA'
    },
    {
      label: 'WA',
      value: 'WA'
    }
  ]
  const [stateSelected, setStateSelected] = useState('');
  const initialFormState = {
    _name: "",
    _contact_number: "",
    _email: "",
    _specialty: "",
    _state: "",
  };
  const [form, setForm] = useState(initialFormState);
  useEffect(() => {
    dispatch(setPageClass("page-practice-based"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if(!pageContent){
      dispatch(fetchPage(74225));
    }
  }, [dispatch, pageContent]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  

  const handleSpecialtySelectChange = (payload) => {
    setForm({
      ...form,
      '_specialty' : payload.value,
    });
    setSpecialtySelected(payload);
  };
  const handleStateSelectChange = (payload) => {
    setForm({
      ...form,
      '_state' : payload.value,
    });
    setStateSelected(payload);
  };

  const setTurnstileToken = (token) => {
    setForm({
      ...form,
      cf_turnstile_response: token,
    });
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(practiceBasedEoi({form: form})).then(e => {
        if(e.payload && e.payload.success){
          dispatch(resetErrors());
          setForm(initialFormState);
          setStateSelected('');
          setSpecialtySelected('');
          history.push(`${location.pathname}/success`);
        }
      });
  };
  
  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);
  
  return (
    <>
      <PageHead 
        className="page-practice-based--page-head"
        title={pageContent ? parse(pageContent.title) : null}
        bg="blue"
      />
      {pageContent ? 
      <PageContent className="page-practice-based--page-content">
        <Section className="page-practice-based--intro">{parse(pageContent.content)}</Section>
        <Section className="page-practice-based--form">
            {errors && Object.values(errors).length > 0 ? 
                <div className="form-errors">
                {Object.values(errors).map((error, index) => (
                    <div key={index} className="form-errors--item">{error}</div>
                ))}
                </div> 
            : null}
            <form onSubmit={handleSubmit}>
              <Input
                type={"text"}
                name={"_name"}
                title={"Name"}
                value={form._name}
                handleChange={handleChange}
                isRequired
              />
              <Input
                type={"text"}
                name={"_contact_number"}
                title={"Contact Number"}
                value={form._contact_number}
                handleChange={handleChange}
                isRequired
              />
              <Input
                type={"email"}
                name={"_email"}
                title={"Email"}
                value={form._email}
                handleChange={handleChange}
                isRequired
              />
              <Select 
                title={'Specialty'}
                name={'_specialty'}
                id={'specialty'}
                options={specialties}
                onChange={handleSpecialtySelectChange}
                value={specialtySelected}
                isRequired
              />
              <Select 
                title={'State of practice'}
                name={'_state'}
                id={'state'}
                options={states}
                onChange={handleStateSelectChange}
                value={stateSelected}
                isRequired
              />
              <Turnstile setToken={setTurnstileToken} />
              <Button
                type={"submit"}
                className="btn btn-primary text-yellow full-width-m"
                text="Submit"
              />
            </form>
          </Section>
      </PageContent>
  
      : null}
    </>
  );
};

export default PracticeBased;
