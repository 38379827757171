import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from 'axios';

const initialState = {
  ids: null,
  basic: null,
  full: null,
  list: null,
  currentLoaded: 9,
  taxonomies: null,
  filters:{
    diagnosis: null,
    treatment_type: null,
    state: null,
    doctors: null,
    s: null
  }
};

export const fetchClinicalTrialsAll = createAsyncThunk("clinical-trials/getAll", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  let meta_query = [];
  tax_query.push('country:au');
  // meta_query.push('ct_type_of_trial:Investigator Initiated')
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/clinical-trials-normalized`, { 
      params: { 
        lang: thunkAPI.getState().settings.language,
        tax_query: tax_query,
        meta_query: meta_query
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchClinicalTrialsFiltered = createAsyncThunk("clinical-trials/getFiltered", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  let meta_query = [];
  let state = [];
  tax_query.push('country:au');
  // meta_query.push('ct_type_of_trial:Investigator Initiated');
  if(thunkAPI.getState().clinicalTrials.filters.diagnosis && thunkAPI.getState().clinicalTrials.filters.diagnosis.length > 0){
    meta_query.push('ct_condition_disorder:'+thunkAPI.getState().clinicalTrials.filters.diagnosis.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().clinicalTrials.filters.treatment_type && thunkAPI.getState().clinicalTrials.filters.treatment_type.length > 0){
    meta_query.push('ct_type_of_treatment:'+thunkAPI.getState().clinicalTrials.filters.treatment_type.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().clinicalTrials.filters.doctors && thunkAPI.getState().clinicalTrials.filters.doctors.length > 0){
    meta_query.push('ct_doctors:'+thunkAPI.getState().clinicalTrials.filters.doctors.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().clinicalTrials.filters.state){
    state.push(thunkAPI.getState().clinicalTrials.filters.state.value)
  }
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/clinical-trials-normalized`, { 
      params: { 
        return: 'slugs',
        lang: thunkAPI.getState().settings.language,
        tax_query: tax_query,
        meta_query: meta_query,
        state: state,
        query: thunkAPI.getState().clinicalTrials.filters.s ? thunkAPI.getState().clinicalTrials.filters.s : null,
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);


export const fetchClinicalTrialsFilters = createAsyncThunk("clinical-trials/getFilters", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/clinical-trials/filters`, { 
      params: { 
       lang: thunkAPI.getState().settings.language,
       country: 'AU'
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
});



const clinicalTrialsSlice = createSlice({
  name: "clinicalTrials",
  initialState,
  reducers: {
    selectFilterItem(state, action) {
      const { data, filterItem } = action.payload
      state.filters[filterItem] = data;
    },
    clearFilterItems(state) {
      state.filters = {
        diagnosis: null,
        treatment_type: null,
        state: null,
        doctors: null,
        s: null
      };
      state.list = state.ids
      state.currentLoaded = 9
    },
    renderMore(state){
      state.currentLoaded = state.currentLoaded + 9
    },
    resetRender(state){
      state.currentLoaded = 9;
    },
    populateInitials(state){
       state.lis = state.ids
    },
    resetClinicalTrialsReducer(state){
    }
  },
  extraReducers: {
    [fetchClinicalTrialsAll.pending]: (state, action) => {
      
    },
    [fetchClinicalTrialsAll.fulfilled]: (state, action) => {
      state.ids = action.payload.trials.slugs;
      state.list = action.payload.trials.slugs;
      state.basic = action.payload.trials.basic;
      state.full = action.payload.trials.full;
    },
    [fetchClinicalTrialsAll.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchClinicalTrialsFiltered.pending]: (state, action) => {
        state.list = null;
    },
    [fetchClinicalTrialsFiltered.fulfilled]: (state, action) => {
        state.list = action.payload ? action.payload.trials : [];
        state.currentLoaded = 9;
    },
    [fetchClinicalTrialsFiltered.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchClinicalTrialsFilters.pending]: (state, action) => {
      
    },
    [fetchClinicalTrialsFilters.fulfilled]: (state, action) => {
      state.taxonomies = action.payload;
    },
    [fetchClinicalTrialsFilters.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const { selectFilterItem, clearFilterItems, resetRender, renderMore } = clinicalTrialsSlice.actions
export default clinicalTrialsSlice.reducer;
