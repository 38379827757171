import { createSlice } from '@reduxjs/toolkit';
import { toast } from "./slice-alerts";
import { logout } from './slice-auth';

const initialState = {
  errors: {
    register: null,
    update_account: null,
    complete_account: null,
    event_register: null,
    refer_patient: null,
    update_account_password: null,
    forgot_password: null,
    reset_password: null,
    report_issue: null,
    ct_eoi: null,
    set_account_password: null
  }
}

export const handleApiErrors = (data) => {
  console.log(data, ' - Main Error')
  return (dispatch) => {
    if(data){
      if(data.statusCode){
        switch(data.statusCode){
          case 401 : dispatch(handle403Error(data));
          break;
          case 403 : dispatch(handle403Error(data));
          break;
          case 400 : dispatch(handle400Errors(data));
          break;
          default : dispatch(toast(
            {
              message: data.message,
              type: 'error'
            }
          ));
        }
      }
      else{
        console.log('Server Response with not status code - means axios catch');
        if (data.response) {
          console.log('Request made and server responded')
          console.log(data.response.data, 'Error response Data');
          console.log(data.response.status, 'Error response Status');
          console.log(data.response.headers, 'Error response Header');
        } else if (data.request) {
          console.log('The request was made but no response was received');
          console.log(data.request, 'Request');
        } else {
          console('Something happened in setting up the request that triggered an Error');
          console.log('Error', data.message);
        }

      }
    }
    else{
      console.log('No Error Data')
    }
  }
};

export const handleApiResponse = (response) => {
  return (dispatch) => {
    if(response.data && response.data.success){
      return response.data;
    }
    else{
      dispatch(handleApiErrors(response.data|| response))
    }
  }
};

export const handle403Error = (data) => {
  return (dispatch, getState) => {
    console.log(data, 'Uncaught 403')
    if(data.code){
      if(data.code === 'jwt_auth_obsolete_token' || 
        data.code === 'jwt_auth_user_not_found' || 
        data.code === 'jwt_auth_invalid_token' || 
        data.code === 'jwt_auth_bad_iss' || 
        data.code === 'jwt_auth_no_auth_header' ||
        data.code === 'jwt_auth_obsolete_refresh_token' || 
        data.code === 'jwt_auth_invalid_refresh_token' || 
        data.code === 'jwt_auth_expired_refresh_token'
      ){
          dispatch(logout());
          if(getState().settings.history.location.pathname === '/login'){
            window.location.reload();
          }
          else{
            if(data.code === 'jwt_auth_obsolete_token' && data.message === 'wb_force_403'){
              getState().settings.history.replace('/');
            }
            else{
              getState().settings.history.replace('/login');
            }
            
            window.location.reload();
          }
      }
      else{
        dispatch(toast(
          {
            message: data.message,
            type: 'error'
          }
        ));
      }
    }
    else{
      console.log(data, 'Uncaught 403')
    }
  }
}

export const handle400Errors = (data) => {
  return (dispatch) => {
    dispatch(addError(data))
  }
}

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    addError(state, action){
      state.errors[action.payload.source] = action.payload.errors
    },
    resetErrors(){
      return initialState
    },
  }
})

 export const { addError, resetErrors } = apiSlice.actions

export default apiSlice.reducer


