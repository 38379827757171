import React from 'react';
import { Link } from 'react-router-dom';

const CustomLink = React.forwardRef((props, ref) => {

    if((typeof props.to === 'string' && (props.to?.includes('http://') || props.to?.includes('https://'))) || (typeof props.to === 'object' && (props.to?.pathname?.includes('http://') || props.to?.pathname?.includes('https://')))){
        return (
            <a ref={ref} className={props.className} href={props.to} target={props.target} rel="noopener noreferrer">{props.children}</a>
        )
    }
    return (
        props.to ? 
        <Link ref={ref} {...props}>{props.children}</Link>
        : <div ref={ref} {...props}>{props.children}</div>
    );
});

export default React.memo(CustomLink);