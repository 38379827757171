import React, { useState, useEffect, useRef } from 'react';

import Link from '../Link';
import Image from '../Image';
import CardLinks from '../Cards/DoctorLinks';


const DoctorCircularCard = (props) => {
    const ref = useRef()
    const [show, setShow] = useState(false);
    useEffect(() => {
        const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if (show && ref.current && !ref.current.contains(e.target)) {
            setShow(false)
          }
        }
    
        document.addEventListener("mousedown", checkIfClickedOutside)
    
        return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [show])
    return (
        <Link className="card card-doctor-circular" ref={ref} to={props.Clicked || !props.link ? null : props.link} onClick={props.Clicked ? props.Clicked : () => setShow(true)}>
            {props.image ? <Image 
                    className={`card-doctor-circular--figure`}
                    src={props.image} 
                    alt={props.title ? props.title : ""}
                />
            : null}
            {props.title && !props.hideTitle ? <div className="card-doctor-circular--title">{props.title}</div> : null}
            {show ? <CardLinks {...props} className="tooltip" /> : null }
        </Link>
    );
};

export default DoctorCircularCard;