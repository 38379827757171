import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import Link from '../Link';
import G from '../../app/globals';
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg';

const GlobalCtaCard = (props) => {
    const [link] = useState(props.link ? new URL(props.link) : null);
    const [is_external, setExternal] = useState(false);

    useEffect(() => {
        if(link){
            if(G.APP_URL === link.origin){
                setExternal(false);
            }
            else{
                setExternal(true);
            }
        }
    }, [link])

    const renderCtaContent = () => (
    <>
        {props.icon ? 
        <div className="card-cta-global--icon">{parse(props.icon)}</div>
        : null}
        <div className="card-cta-global--content">
            <div className="card-cta-global--title">{props.title}</div>
            {props.description ? <div className={`card-cta-global--description${link ? ' has-more' : ''}`}>{parse(props.description)}</div> : null}
            
        </div>
        {link ? <div className="card-cta-global--more"><ArrowRight /></div> : null}
        
    </>
    );

    return (
        link ? (
            is_external ? 
            <a className="card card-cta-global" href={props.link} rel="noopener noreferrer" target="_blank">{renderCtaContent()}</a> :
            <Link className="card card-cta-global" to={link.pathname}>{renderCtaContent()}</Link>
        )
        : <div className="card card-cta-global">{renderCtaContent()}</div>
    );
};

export default React.memo(GlobalCtaCard);