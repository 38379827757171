import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import Layout from './Layout';
import Routes from './Routes';
import ScrollToTop from '../components/ScrollToTop';
import './App.scss';
import 'keen-slider/keen-slider.min.css'
import 'react-day-picker/lib/style.css';



import LoadingSpinner from '../components/Loading';
import APP_DATA from '../../package.json';

function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={APP_DATA.version}
      isEnabled={isProduction} //If false, the library is disabled.
      loadingComponent={<LoadingSpinner />}
    >
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </CacheBuster>

  );
}

export default App;
