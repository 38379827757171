import axios from 'axios';
import globals from '../app/globals';

const instance = axios.create({
    baseURL: globals.API_URL
});

instance.interceptors.request.use(function (config) {
    let wb_idp_token = localStorage.getItem('wb_idp_token');
    if (wb_idp_token && config.type !== 'public') {
        config.headers.Authorization = 'Bearer ' + wb_idp_token;
    }
    return config;
});


export default instance;