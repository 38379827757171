import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { updateProfile } from "../../store/features/slice-account";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import { Word } from "../../components/Word";
import UserSummary from "../../components/UserSummary";
import Input from "../../components/Form/Input";
import InputPhone from "../../components/Form/Phone";
import Button from "../../components/Button";
import Select from "../../components/Form/Select";
import G from "../../app/globals";
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_circle.svg';






const EditProfile = () => {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        _first_name: '',
        _last_name: '',
        _mobile_number: '',
        _postcode: '',
        _salutation: '',
        _speciality: '',
        _sub_specialities: '',
        _racgp_number: '',
        _ahpra_number: "",
        _clinics: [
            {
                name: "",
                address: "",
                email: "",
                provider_number: ""
            }
        ]
    });
    const accountData = useSelector((state) => state.account.data);
    const specialities = useSelector((state) => state.references.specialities);
    const errors = useSelector((state) => state.api.errors.update_account);

    const [subSpecialities, setSubSpecialities] = useState([]);
    useEffect(() => {
        dispatch(setPageClass("page-edit-profile"));
        dispatch(setUpHeader({
            show: true,
            back: true,
            profile: true,
            search: true
        }));
    }, [dispatch]);

    useEffect(() => {
        if (accountData && accountData.speciality?.length > 0 && specialities && specialities[accountData.speciality[0]?.value]) {
            setSubSpecialities(specialities[accountData.speciality[0].value].children);
        }
    }, [accountData, specialities])

    useEffect(() => {
        if (accountData) {
            setForm({
                _first_name: accountData.firstName ? accountData.firstName : '',
                _last_name: accountData.lastName ? accountData.lastName : '',
                _mobile_number: accountData.mobile ? accountData.mobile : '',
                _postcode: accountData.postcode ? accountData.postcode : '',
                _salutation: accountData.salutation ? accountData.salutation : '',
                _speciality: accountData.speciality && accountData.speciality[0] ? accountData.speciality[0].value : '',
                _sub_specialities: accountData.subSpecialties && accountData.subSpecialties.length > 0 ? accountData.subSpecialties.map(item => (item.value)) : '',
                _racgp_number: accountData.racgpNumber,
                _ahpra_number: accountData.ahpraNumber,
                _clinics: accountData.clinics
            });
        }
    }, [accountData]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === '_ahpra_number') {
            e.target.setCustomValidity(e.target.validity.patternMismatch ? 'AHPRA number must start with 3 letters followed by 10 numbers.' : '');
        }
    };
    const handleSelectChange = (source, payload, isMulti) => {
        setForm({
            ...form,
            [source]: isMulti ? payload.map(item => item.value) : payload.value
        });
    };
    const handleSpecialityChange = (payload) => {
        setForm({
            ...form,
            '_speciality': payload?.value,
            '_sub_specialities': '',
        });
        if (payload?.children?.length > 0) {
            setSubSpecialities(payload.children);
        }
    };

    const handleChangeClinic = (e, index) => {
        setForm({
            ...form,
            '_clinics': form._clinics.map((clinic, i) => index === i ? { ...clinic, [e.target.name]: e.target.value } : clinic)
        });
    }
    const handleAddClinic = () => {
        setForm({
            ...form,
            '_clinics': [...form._clinics, {
                name: "",
                address: "",
                email: "",
                provider_number: ""
            }]
        })
    }

    const handleDeleteClinic = (i) => {
        var clinics = form._clinics.filter((value, index) => index !== i);
        setForm({
            ...form,
            '_clinics': clinics
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateProfile(form)).then(() => { G.RESET_SCROLL() });
    }

    return (
        <>
            {accountData ?
                <>
                    <PageHead
                        className="page-profile--page-head"
                        title="Edit Profile"
                    />
                    <PageContent className="page-profile--page-content">
                        <Section className="split-content w-40-60">
                            <Section><UserSummary /></Section>
                            <Section className="page-profile--details">
                                {errors && errors.length > 0 ?
                                    <div className="form-errors">
                                        {errors.map((error, index) => (
                                            <div key={index} className="form-errors--item">{error.message}</div>
                                        ))}
                                    </div>
                                    : null}
                                <form onSubmit={handleSubmit}>
                                    <div className="page-profile--details-account">
                                        <h2><Word value="Account details" /></h2>
                                        <Select
                                            type={"text"}
                                            name={"_salutation"}
                                            title={"Salutation"}
                                            options={G.SALUTATIONS_OPTIONS}
                                            value={form._salutation ? ({ label: form._salutation, value: form._salutation }) : null}
                                            onChange={(payload) => handleSelectChange('_salutation', payload)}
                                            isRequired
                                        />
                                        <Input
                                            type={"text"}
                                            name={"_first_name"}
                                            title={"First name"}
                                            value={form._first_name}
                                            handleChange={handleChange}
                                            isRequired
                                        />
                                        <Input
                                            type={"text"}
                                            name={"_last_name"}
                                            title={"Last name"}
                                            value={form._last_name}
                                            handleChange={handleChange}
                                            isRequired
                                        />
                                        <InputPhone
                                            type={"number"}
                                            name={"_mobile_number"}
                                            title={"Mobile"}
                                            value={form._mobile_number}
                                            handleChange={handleChange}
                                            isRequired
                                        />
                                        <Input
                                            type={"number"}
                                            name={"_postcode"}
                                            title={"Postcode"}
                                            value={form._postcode}
                                            handleChange={handleChange}
                                            isRequired
                                        />
                                    </div>
                                    <div className="page-profile--details-professional">
                                        <h2><Word value="Professional details" /></h2>
                                        {accountData.speciality && accountData.speciality.length > 0 ?
                                            <>
                                                <Select
                                                    title={'Speciality'}
                                                    name={'_speciality'}
                                                    options={Object.values(specialities)}
                                                    onChange={handleSpecialityChange}
                                                    value={form._speciality ? ({ label: specialities[form._speciality].label, value: form._speciality }) : null}
                                                    isRequired
                                                />
                                                <Select
                                                    title={'Sub Speciality'}
                                                    name={'_sub_specialities'}
                                                    options={subSpecialities}
                                                    onChange={(payload) => handleSelectChange('_sub_specialities', payload, true)}
                                                    value={form._sub_specialities ? form._sub_specialities.map(item => ({ label: item, value: item })) : null}
                                                    isMulti
                                                    isRequired
                                                />
                                            </>
                                            : null}
                                        <Input
                                            type={"text"}
                                            name={"_ahpra_number"}
                                            title={"AHPRA Number"}
                                            value={form._ahpra_number}
                                            handleChange={handleChange}
                                            isRequired
                                            pattern="^[a-zA-Z]{3}\d{10}"
                                        />
                                        <Input
                                            type={"text"}
                                            name={"_racgp_number"}
                                            title={"RACGP Number"}
                                            value={form._racgp_number}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                    {accountData?.clinics?.length > 0 && (
                                        <div className="page-profile--details-practice">
                                            <h2><Word value="Practice details" /></h2>
                                            {form._clinics?.map((clinic, index) => (
                                                <div key={index} className={`form-container${index > 0 ? ' has-close' : ''}`}>
                                                    {index > 0 ? <div className="form-container--close" onClick={() => handleDeleteClinic(index)}><CloseIcon /></div> : null}
                                                    <Input
                                                        type={"text"}
                                                        name={"name"}
                                                        id={`clinic_name_${index}`}
                                                        title={"Clinic name"}
                                                        value={clinic.name}
                                                        handleChange={(e) => handleChangeClinic(e, index)}
                                                        isRequired
                                                    />
                                                    <Input
                                                        type={"text"}
                                                        name={"address"}
                                                        id={`clinic_address_${index}`}
                                                        title={"Clinic address"}
                                                        value={clinic.address}
                                                        handleChange={(e) => handleChangeClinic(e, index)}
                                                        isRequired
                                                    />
                                                    <Input
                                                        type={"email"}
                                                        name={"email"}
                                                        id={`clinic_email_${index}`}
                                                        title={"Clinic email"}
                                                        value={clinic.email}
                                                        handleChange={(e) => handleChangeClinic(e, index)}
                                                        isRequired
                                                    />
                                                    <Input
                                                        type={"text"}
                                                        name={"provider_number"}
                                                        id={`clinic_pn_${index}`}
                                                        title={"Provider number"}
                                                        value={clinic.provider_number}
                                                        handleChange={(e) => handleChangeClinic(e, index)}
                                                        isRequired
                                                    />
                                                </div>
                                            ))}
                                            {form?._clinics?.length < G.CLINICS_ALLOWED_NB ?
                                                <Button
                                                    className="btn-plain blue add-btn"
                                                    text="+ Add another clinic"
                                                    Clicked={handleAddClinic}
                                                />
                                                : null}
                                        </div>
                                    )}

                                    <div className="form-actions">
                                        <Button
                                            type={"submit"}
                                            className="btn btn-primary btn-blue text-yellow full-width"
                                            text="Update"
                                        />
                                    </div>
                                </form>
                            </Section>
                        </Section>
                    </PageContent>
                </>
                : null}
        </>
    );
};

export default EditProfile;
