import ReactPlayer from 'react-player'

const FlexibleVideo = (props) => {

    const {src, offset, ...rest} = props
    
    return (
        <div className={`flexible-video ${offset ? 'has-offset' : ''}`}>
            <ReactPlayer 
                url={src}
                width='100%'
                height='100%'
                {...rest}
            />
        </div>
    );

};

export default FlexibleVideo;