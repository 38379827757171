import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toasts: [],
    modal: null
}
let nextToastId = 0;

export const toast = (payload) => {
  return (dispatch) => {
    if(payload){
      nextToastId++;
      let payloadMerged = {...payload, id: nextToastId};
      dispatch(addtoast(payloadMerged));
      if(!payloadMerged.fixed){
        dispatch(removeToastTimeout(nextToastId, payloadMerged.timeout ? payloadMerged.timeout : 5000));
      }
    }
  }
};

const removeToastTimeout = (id, timeout) => {
  return (dispatch) => {
    setTimeout( () => {
      dispatch(removeToast(id));
  }, timeout)
  }
  
}

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addtoast(state, action){
      state.toasts.push({
        id: action.payload.id,
        title: action.payload.title,
        message: action.payload.message,
        link: action.payload.link,
        type: action.payload.type ? action.payload.type : 'info'
      });
    },
    removeToast(state, action){
      let toastToBeDeleted = state.toasts.find(toast => { return toast.id === action.payload });
      if(toastToBeDeleted){
        state.toasts = state.toasts.filter(t => {
            return t.id !== toastToBeDeleted.id;
        });
      }
    }
  }
})

export const { addtoast, removeToast } = alertsSlice.actions

export default alertsSlice.reducer


