import React from 'react';

const Loading = (props) => {
    return (
        <section className={`loading${props.className ? ' '+props.className : ''}`}>
            {props.className && props.className === 'full' ? <div className="loading--preload-bar"></div> : <div className="loading--spinner"></div>
            }

        </section>
    );
};

export default React.memo(Loading);