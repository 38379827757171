import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import Section from "../../components/Section";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import CenterListItem from "../../components/Cards/Contact";
import Tabs from "../../components/Tabs";
import G from "../../app/globals";

const CentresNearestPage = () => {
  const centresIDs = useSelector((state) => state.centres.nearest);
  const centresListBasic = useSelector((state) => state.centres.basic);
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(setPageClass("page-centres split"));
    dispatch(setUpHeader({
      show:true,
      logo: true,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  const rendeCentresItems = () => {
    return centresIDs.length > 0 ?
              centresIDs.map(id => (
                <CenterListItem
                    key={id}
                    image={centresListBasic[id].image}
                    title={centresListBasic[id].title}
                    phone={centresListBasic[id].phone}
                    phoneFotmatted={centresListBasic[id].phoneFormatted}
                    address={centresListBasic[id].address ? centresListBasic[id].address : centresListBasic[id].location['address']}
                    fax={centresListBasic[id].fax}
                    isDesktop={isDesktop}
                    slug={centresListBasic[id].slug}
                />
              )) 
            : <div>No nearby centres.</div>
  }

  return (
    <>
      {!isAuthenticated ? 
      <Redirect replace to="/contact-icon/all" />
      :
        <>
        <PageHead 
          className="page-centres--page-head"
          title="Contact Icon"
          bg="blue"
        />
        <PageContent className="page-centres--page-content">
        <Section className="page-centres--tabs">
            <Tabs className="page-centres--tabs-btns" activeItem={1}
                items={[
                  {
                    index: 1,
                    text: 'Nearest centres'
                  }, 
                  {
                    index: 2,
                    text: 'View all centres', 
                    Clicked:() => history.push('/contact-icon/all')
                  }
                ]}
            />
        </Section>
        {centresIDs ? 
          <Section className="page-centres--content-list attached-list">
              {rendeCentresItems()}
          </Section> 
        : null}
      </PageContent>
      </> 
      }
    </>
    
  );
};

export default CentresNearestPage;
