import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { fetchClinicalTrialsAll } from "../../store/features/slice-clinical-trials";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import Accordion from "../../components/Accordion";
import Image from '../../components/Image';
import ClinicalTrialsEOIForm from "../../components/ClinicalTrialsEOIForm";
import { ReactComponent as StarSolidIcon } from '../../assets/images/icons/star_solid.svg';
import { Word } from "../../components/Word";
import G from "../../app/globals";






const ClinicalTrialDetails = () => {

    const dispatch = useDispatch();
    let { slug } = useParams();
    const clinicalTrialsIDs = useSelector((state) => state.clinicalTrials.ids);
    const clinicalTrialSingle = useSelector((state) => state.clinicalTrials.full ? state.clinicalTrials.full[slug] : null);
    const doctorsListBasic = useSelector((state) => state.doctors.basic);
    
    useEffect(() => {
        dispatch(setPageClass("page-ct-single"));
        dispatch(setUpHeader({
          show: true,
          back: true,
          profile: true,
          search: true,
        //   backTo: '/research/clinical-trials'
        }));
    }, [dispatch]);

    useEffect(() => {
        if((!clinicalTrialsIDs)){
          dispatch(fetchClinicalTrialsAll());
        }
      }, [dispatch, clinicalTrialsIDs]);

    return (
        <>
            {clinicalTrialSingle ?
            <>
                <PageHead 
                    className="page-ct-single--page-head" 
                    title={clinicalTrialSingle.laymanTitle}
                />
                <PageContent className="page-ct-single--page-content">
                    <Section className="page-ct-single--content">
                       {clinicalTrialSingle.title ? <h4 className="page-ct-single--title">{parse(clinicalTrialSingle.title, G.HTML_PARSE_OPTIONS)}</h4> : null }
                       {clinicalTrialSingle.laymanSummary ? 
                            <div className="page-ct-single--content-item">
                                <b><Word value="Trial summary" />:</b>
                                {parse(clinicalTrialSingle.laymanSummary, G.HTML_PARSE_OPTIONS)}
                            </div>
                       : null}
                       {clinicalTrialSingle.receptor ? 
                            <div className="page-ct-single--content-item">
                                <b><Word value="Receptor status / problem studied" />:</b>
                                <p>{clinicalTrialSingle.receptor}</p>
                            </div>
                       : null}
                       {clinicalTrialSingle.inclusionCriteria ? 
                            <Accordion title={<Word value="Inclusion criteria" />}>
                                {parse(clinicalTrialSingle.inclusionCriteria, G.HTML_PARSE_OPTIONS)}
                            </Accordion>
                       : null}
                       {clinicalTrialSingle.exclusionCriteria ? 
                            <Accordion title={<Word value="Exclusion criteria" />}>
                                {parse(clinicalTrialSingle.exclusionCriteria, G.HTML_PARSE_OPTIONS)}
                            </Accordion>
                       : null}
                    </Section>
                    <Section className="page-ct-single--aside">
                        <div className="page-ct-single--info">
                            {clinicalTrialSingle.status ? 
                                <div className="page-ct-single--info-header">
                                    <div className={`page-ct-single--info-header-status page-ct-single--info-header-status-${clinicalTrialSingle.status.value}`}>{clinicalTrialSingle.status.label}</div>
                                </div>
                            : null}
                            <div className="page-ct-single--info-details">
                                {clinicalTrialSingle.laymanTitle ? 
                                    <div className="page-ct-single--info-details__item">
                                        <b><Word value="Trial title" /></b>
                                        <p>{clinicalTrialSingle.laymanTitle}</p>
                                    </div>
                                : null}
                                {clinicalTrialSingle.condition ? 
                                    <div className="page-ct-single--info-details__item">
                                        <b><Word value="Diagnosis" /></b>
                                        <p>{clinicalTrialSingle.condition}</p>
                                    </div>
                                : null}
                                {clinicalTrialSingle.typeTrial?.value ? 
                                    <div className="page-ct-single--info-details__item">
                                        <b><Word value="Type of trial" /></b>
                                        <p>{clinicalTrialSingle.typeTrial.value}</p>
                                    </div>
                                : null}
                                {clinicalTrialSingle.typeTreatment?.length > 0 ? 
                                    <div className="page-ct-single--info-details__item">
                                        <b><Word value="Type of treatement" /></b>
                                        <p>{clinicalTrialSingle.typeTreatment.map(item => item.value).join(', ')}</p>
                                    </div>
                                : null}
                                {clinicalTrialSingle.phase?.value ? 
                                    <div className="page-ct-single--info-details__item">
                                        <b><Word value="Phase" /></b>
                                        <p>{clinicalTrialSingle.phase.value}</p>
                                    </div>
                                : null}
                                {clinicalTrialSingle.locations?.length > 0 ? 
                                    <div className="page-ct-single--info-details__item">
                                        <b><Word value="Locations" /></b>
                                        <div className="page-ct-single--info-details__item_tags">
                                            {clinicalTrialSingle.locations.map((item, index) => {
                                                return <div className="text text--tag bold" key={index}>
                                                            {item.label}
                                                        </div>
                                            })}
                                        </div>
                                    </div>
                                : null}
                                {clinicalTrialSingle.doctors && clinicalTrialSingle.doctors.length > 0 && doctorsListBasic ? 
                                    <div className="page-ct-single--info-details__item">
                                        <b><Word value="Investigators" /></b>
                                        <div className="page-ct-single--info-details__item_tags">
                                            {clinicalTrialSingle.doctors.map((doctor, index) => {
                                                return (
                                                    doctorsListBasic[doctor.slug] ? 
                                                        <div key={index} className="text text--author">
                                                            {doctorsListBasic[doctor.slug].profileImage ? 
                                                                <Image 
                                                                    src={doctorsListBasic[doctor.slug].profileImage}
                                                                    alt={doctorsListBasic[doctor.slug].name}
                                                                />
                                                            : null}
                                                            {parse(doctorsListBasic[doctor.slug].salutation+" "+doctorsListBasic[doctor.slug].name)}
                                                            {doctor.principal ? <StarSolidIcon /> : null}
                                                        </div>
                                                    : null)
                                            })}
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </Section>
                    <Section className="page-ct-single--content">
                        <h2>Express your interest</h2>
                        <ClinicalTrialsEOIForm laymanTitle={clinicalTrialSingle.laymanTitle} />
                    </Section>
                </PageContent>
                
            </>
            : null}
         </>
    );
};

export default ClinicalTrialDetails;
