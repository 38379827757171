import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { fetchClinicalTrialsAll, renderMore, resetRender } from "../../store/features/slice-clinical-trials";
import Section from "../../components/Section";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import ClinicalTrialCard from "../../components/Cards/ClinicalTrial";
import ClinicalTrialFilter from "../../components/Filters/ClinicalTrialFilter";
import ScrollToTop from '../../components/ScrollToTop';
import Loading from "../../components/Loading";

import G from "../../app/globals";

const ClinicalTrialsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clinicalTrialsIDs = useSelector((state) => state.clinicalTrials.ids);
  const clinicalTrialsListIDs = useSelector((state) => state.clinicalTrials.list);
  const clinicalTrialsListBasic = useSelector((state) => state.clinicalTrials.basic);
  const currentLoaded = useSelector((state) => state.clinicalTrials.currentLoaded);
  const filters = useSelector((state) => state.clinicalTrials.filters);
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  const isFiltered = filters && (filters.diagnosis || filters.treatment_type || filters.state || filters.doctors || filters.s);
  const [FilterOpen, setFilterOpen] = useState(isFiltered || false);

  useEffect(() => {
    dispatch(setPageClass("page-ct-list"));
    dispatch(setUpHeader({
      show:true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if((!clinicalTrialsIDs)){
      dispatch(fetchClinicalTrialsAll());
    }
  }, [dispatch, clinicalTrialsIDs]);

  useEffect(() => {
    //reset the rendered items when accessing page (exclude back btn (POP))
    if(history.action === 'PUSH'){
      dispatch(resetRender());
    }
  }, [dispatch, history.action]);

  const renderDoctorsCards = () => {
    return clinicalTrialsListBasic && clinicalTrialsListIDs && currentLoaded ? clinicalTrialsListIDs.slice(0, currentLoaded).map(id => (
      clinicalTrialsListBasic[id] ? <ClinicalTrialCard
          key={id}
          slug={clinicalTrialsListBasic[id].slug}
          laymanTitle={clinicalTrialsListBasic[id].laymanTitle}
          title={clinicalTrialsListBasic[id].title}
          condition={clinicalTrialsListBasic[id].condition}
          locations={clinicalTrialsListBasic[id].locations}
          status={clinicalTrialsListBasic[id].status}
          link={`/research/clinical-trials/${id}`}
      /> : null
    )) : null;
  };

  const handleFilterIconClick = () => {
    if(isDesktop){
      setFilterOpen(!FilterOpen);
    }
    else{
      history.push(`/research/clinical-trials/filter`)
    }
  }
  return (
    <>
    <ScrollToTop />
    <PageHead 
      className="page-ct-list--page-head"
      title="Clinical Trials"
      icon="filter"
      iconText={isDesktop ? (FilterOpen ? 'Close' : 'Filter') : 'Filter'}
      Clicked={handleFilterIconClick}
      secondaryIcon={isFiltered ? 'check' : null}
      bg="blue"
    />
    <PageContent className="page-ct-list--content">
    {isDesktop ? 
        <ClinicalTrialFilter 
          className={`page-ct-list--filter in-page${FilterOpen ? '' : ' collapsed'}`}
          title="Search"
        />
    : null}
    {clinicalTrialsListIDs && clinicalTrialsListIDs.length > 0 ? 
      <Section className="page-ct-list--content-list">
          <InfiniteScroll
              dataLength={currentLoaded}
              next={() => dispatch(renderMore())}
              hasMore={(currentLoaded < clinicalTrialsListIDs.length)}
              loader={<Loading />}
              scrollThreshold={0.7}
            >
              {renderDoctorsCards()}
            </InfiniteScroll>
      </Section> 
    : null}
    </PageContent>
    </> 
  );
};

export default ClinicalTrialsList;
