const dictionary = {
    "Home": {
        "en": "Home"
    },
    "Discuss patient": {
        "en": "Discuss patient"
    },
    "Education": {
        "en": "Education"
    },
    "Find a doctor": {
        "en": "Find a doctor"
    },
    "More": {
        "en": "More"
    },
    "Less": {
        "en": "Less"
    },
    "Email": {
        "en": "Email",
    },
    "Password": {
        "en": "Password",
    },
    "Log in": {
        "en": "Log in",
    },
    "Register": {
        "en": "Register",
    },
    "Forgot password": {
        "en": "Forgot password"
    },
    "Don’t have an account?": {
        "en": "Don’t have an account?"
    },
    "Apply here": {
        "en": "Apply here"
    },
    "First name": {
        "en": "First name"
    },
    "Last name": {
        "en": "Last name"
    },
    "Name": {
        "en": "Name"
    },
    "Provider number": {
        "en": "Provider number"
    },
    "Apply": {
        "en": "Apply"
    },
    "My Bio": {
        "en": "My Bio"
    },
    "CPD Log": {
        "en": "CPD Log"
    },
    "Account": {
        "en": "Account"
    },
    "Preferences": {
        "en": "Preferences"
    },
    "Log out": {
        "en": "Log out"
    },
    "Contact ICON": {
        "en": "Contact ICON"
    },
    "Message doctor (via Foxo)": {
        "en": "Message doctor (via Foxo)"
    },
    "Refer patient (via Foxo)": {
        "en": "Refer patient (via Foxo)"
    },
    "Biography": {
        "en": "Biography"
    },
    "Special Interests": {
        "en": "Special Interests"
    },
    "Edit": {
        "en": "Edit"
    },
    "Save": {
        "en": "Save"
    },
    "Title": {
        "en": "Title"
    },
    "Mobile": {
        "en": "Mobile"
    },
    "Work Address": {
        "en": "Work Address"
    }
}

export default dictionary;