import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageTitle: "",
    pageClass: "",
    loadingCounter: 0,
    header:{
      show: false,
      colour: 'blue',
      logo: false,
      profile: false,
      search: false,
      back: false,
      backTo: null,
      close: false,
      favourites: true,
      sideNav: false
    },
    sideNav: {
      show: true
    },
    breakPoint: null,
    modal:{
      status: false,
      content: null
    }
}


const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setPageClass(state, action){
      state.pageClass = action.payload;
    },
    setLoadingStart(state){
      state.loadingCounter++;
    },
    setLoadingEnd(state){
      state.loadingCounter--;
    },
    setUpHeader(state, action){
      if(action.payload){
        if (typeof action.payload.show !== 'undefined' || action.payload.show != null) {
          state.header.show = action.payload.show
        }
        else{
          state.header.show = true
        }
        if (typeof action.payload.colour !== 'undefined' || action.payload.colour != null) {
          state.header.colour = action.payload.colour
        }
        else{
          state.header.colour = "blue";
        }
        if (typeof action.payload.logo !== 'undefined' || action.payload.logo != null) {
          state.header.logo = action.payload.logo
        }
        else{
          state.header.logo = false;
        }
        if (typeof action.payload.profile !== 'undefined' || action.payload.profile != null) {
          state.header.profile = action.payload.profile
        }
        else{
          state.header.profile = false;
        }
        if (typeof action.payload.search !== 'undefined' || action.payload.search != null) {
          state.header.search = action.payload.search
        }
        else{
          state.header.search = false;
        }
        if (typeof action.payload.back !== 'undefined' || action.payload.back != null) {
          state.header.back = action.payload.back
        }
        else{
          state.header.back = false;
        }
        if (typeof action.payload.backTo !== 'undefined' || action.payload.backTo != null) {
          state.header.backTo = action.payload.backTo
        }
        else{
          state.header.backTo = null
        }
        if (typeof action.payload.close !== 'undefined' || action.payload.close != null) {
          state.header.close = action.payload.close
        }
        else{
          state.header.close = false;
        }
        if (typeof action.payload.sideNav !== 'undefined' || action.payload.sideNav != null) {
          state.header.sideNav = action.payload.sideNav
        }
        else{
          state.header.sideNav = true
        }
      }
      else{
        state.header = initialState.header
      }
    },
    setBreakPoint(state, action){
      if(action.payload){
        state.breakPoint = action.payload;
      }
    },
    openModal(state, action){
      if(action.payload){
        state.modal.status = true;
        state.modal.content = action.payload;
      }
    },
    closeModal(state){
      state.modal.status = false;
      state.modal.content = null;
    }
  }
})

export const { setPageClass, setLoadingStart, setLoadingEnd, setUpHeader, setUpSideNav, setBreakPoint, openModal, closeModal } = uiSlice.actions

export default uiSlice.reducer


