import React from 'react';
import LazyLoad from 'react-lazyload';
// import G from '../app/globals';

const WB_Image = (props) => {
    // const [webpExists, setWebpExists] = useState(false);

    // useEffect(() => {
    //     G.CHECK_IMAGE_EXISTS(props.src+'.webp', (exists) => {
    //         setWebpExists(exists);
    //     });
    // }, [props.src]);

    return (
        <figure className={props.className}>
            <LazyLoad once offset={100}>
                {/* {webpExists ?  */}
                    <picture>
                        <source srcSet={props.src} />
                        <img src={props.src} alt={props.alt} />
                    </picture>
                {/* : 
                    <img src={props.src} alt={props.alt} />
                } */}
            </LazyLoad>
        </figure>
    );
};

export default React.memo(WB_Image);
