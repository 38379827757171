import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from 'axios';

const initialState = {
  ids: null,
  basic: null,
  full: null,
  list: {
    centres: null,
    rp: null
  },
  currentLoaded:{
    centres: 9,
    rp: 9
  },
  nearest: null,
  taxonomies: {
    treatments: null,
    techniques: null,
    locations: null,
    locationsN: null
  },
  filters:{
    centres:{
      treatments: null,
      techniques: null,
      state: null,
      city: null,
      postcode: null
    },
    rp:{
      treatments: null,
      techniques: null,
      state: null,
      city: null,
      postcode: null
    }
  }
};

export const fetchCentres = createAsyncThunk("centres/getAll", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  tax_query.push('country:au');
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/centres-normalized`, { 
      params: { 
       lang: thunkAPI.getState().settings.language,
       tax_query: tax_query,
       orderby: 'title'
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchCentresFilter = createAsyncThunk("centres/Filtered", async (filter="centres",thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  if(thunkAPI.getState().centres.filters[filter].treatments && thunkAPI.getState().centres.filters[filter].treatments.length > 0){
    tax_query.push('treatment:'+thunkAPI.getState().centres.filters[filter].treatments.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().centres.filters[filter].techniques && thunkAPI.getState().centres.filters[filter].techniques.length > 0){
    tax_query.push('technique:'+thunkAPI.getState().centres.filters[filter].techniques.map(function (obj) {return obj.value}))
  }
  tax_query.push('country:au');
  let meta_query = [];
  if(thunkAPI.getState().centres.filters[filter].state){
    meta_query.push('centre_state:'+thunkAPI.getState().centres.filters[filter].state.value);
  }
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/centres-normalized`, { 
      params: { 
        return: 'slugs',
        lang: thunkAPI.getState().settings.language,
        tax_query: tax_query,
        meta_query: meta_query,
        query: thunkAPI.getState().centres.filters[filter].city ? thunkAPI.getState().centres.filters[filter].city.value : null,
        postcode: thunkAPI.getState().centres.filters[filter].postcode,
        orderby: 'title'
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchCentresNearest = createAsyncThunk("centres/Nearest", async (postcode,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  tax_query.push('country:au');
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/centres-normalized`, { 
      params: { 
        return: 'slugs',
        lang: thunkAPI.getState().settings.language,
        tax_query: tax_query,
        postcode: postcode
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchCentresTreatments = createAsyncThunk("centres/getTreatments", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    let response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/treatments`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
});

export const fetchCentresTechniques = createAsyncThunk("centres/getTechniques", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    let response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/techniques`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
});



export const fetchCentresLocations = createAsyncThunk("centres/getLocations", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    let response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/centres/locations`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
});

export const fetchCentresLocationsN = createAsyncThunk("centres/getLocationsN", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    let response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/centres/locations-normalized`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
});


const centresSlice = createSlice({
  name: "centres",
  initialState,
  reducers: {
    selectFilterItem(state, action) {
      const { data, filterItem, filter } = action.payload
      state.filters[filter][filterItem] = data;
    },
    clearFilterItems(state, action) {
      state.filters[action.payload] = {
        treatments: null,
        techniques: null,
        state: null,
        city: null,
        postcode: null
      };
      state.list[action.payload] = state.ids
      state.currentLoaded[action.payload] = 9
    },
    renderMore(state, action){
      state.currentLoaded[action.payload] = state.currentLoaded[action.payload] + 9
    },
    resetRender(state, action){
      state.currentLoaded[action.payload] = 9;
    },
    populateInitials(state, action){
      state.list[action.payload] = state.ids
    }
  },
  extraReducers: {
    [fetchCentres.pending]: (state, action) => {
    },
    [fetchCentres.fulfilled]: (state, action) => {
        state.ids = action.payload.centres.slugs;
        state.basic = action.payload.centres.basic;
        state.full = action.payload.centres.full;
        state.list['centres'] = action.payload.centres.slugs;
        state.list['rp'] = action.payload.centres.slugs;
    },
    [fetchCentres.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchCentresFilter.pending]: (state, action) => {
      if(action.meta && action.meta.arg){
        state.list[action.meta.arg] = null;
      }
    },
    [fetchCentresFilter.fulfilled]: (state, action) => {
      if(action.meta && action.meta.arg){
        state.list[action.meta.arg] = action.payload ? action.payload.centres : [];
        state.currentLoaded[action.meta.arg] = 9;
      }
    },
    [fetchCentresFilter.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchCentresNearest.pending]: (state, action) => {
      
    },
    [fetchCentresNearest.fulfilled]: (state, action) => {
        state.nearest =  action.payload && action.payload.centres ? action.payload.centres : [];
    },
    [fetchCentresNearest.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchCentresLocations.fulfilled]: (state, action) => {
      state.taxonomies.locations = action.payload;
    },
    [fetchCentresLocations.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchCentresLocationsN.fulfilled]: (state, action) => {
      state.taxonomies.locationsN = action.payload.locations;
    },
    [fetchCentresLocationsN.rejected]: (state, action) => {
      console.log(action);
    },
    
    [fetchCentresTreatments.fulfilled]: (state, action) => {
      state.taxonomies.treatments = action.payload.treatments;
    },
    [fetchCentresTreatments.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchCentresTechniques.fulfilled]: (state, action) => {
      state.taxonomies.techniques = action.payload.techniques;
    },
    [fetchCentresTechniques.rejected]: (state, action) => {
      console.log(action);
    }
  },
});

export const { selectFilterItem, clearFilterItems, renderMore, resetRender, populateInitials } = centresSlice.actions
export default centresSlice.reducer;

