import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { removeToast } from "../../store/features/slice-alerts";
import { Word } from '../Word'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/check.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/icons/error.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icons/warning.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/icons/info.svg';
import { ReactComponent as ExternalLinkIcon } from '../../assets/images/icons/external-link.svg';
import { Link } from 'react-router-dom';



const Toasts = (props) => {
    const { toastList } = props;
    const [list, setList] = useState(toastList);
    const dispatch = useDispatch();
    useEffect(() => {
        setList(toastList);
    }, [toastList]);

    const generateTitle = (type) => {
        let icon = null;
        let title = null;
        switch(type){
            case 'success': 
                icon = <SuccessIcon />;
                title = <Word value="Success" />;
            break;
            case 'error': 
                icon = <ErrorIcon />;
                title = <Word value="Error" />;
            break;
            case 'warning': 
                icon = <WarningIcon />;
                title = <Word value="Warning" />;
            break;
            case 'info': 
                icon = <InfoIcon />;
                title = <Word value="Info" />;
            break;
            default: 
                icon = null;
                title = null;
        }
        return <>{icon} {title}</>
    }
    return (
        <div className={`taosts--container`}>
                {
                    list ? list.map((toast, i) =>     
                        <div 
                            key={i}
                            className={`toast ${toast.type}`}
                        >
                            <div className="toast--header">
                                <div className="toast--name">{generateTitle(toast.type)}</div>
                                <div className="toast--close" onClick={() => dispatch(removeToast(toast.id))}><CloseIcon /></div>
                            </div>
                            <div className="toast--content">
                                {
                                toast.link ? 
                                <Link to={toast.link} className="toast--title">
                                    {toast.title}
                                    <ExternalLinkIcon />
                                </Link>
                                : <div className="toast--title">{toast.title}</div>
                                }
                                <div className="toast--message">
                                    {toast.message}
                                </div>
                            </div>
                        </div>
                    )
                    : null
                }
            </div>
    );
};

export default Toasts;