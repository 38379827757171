import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import NavLink from './Navlink';
import { Word } from './Word';
import { ReactComponent as ChevronRightIcon } from '../assets/images/icons/chevron-right.svg';


const SideMenu = () => {
    const location = useLocation();
    const [articleSource, setArticleSource] = useState(null);
    const isArticle = location.pathname.indexOf("/article") > -1 ? true : false;
    const isEvent = location.pathname.indexOf("/education/event") > -1 ? true : false;
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const articleCatg = useSelector((state) => state.articles.single && state.articles.single.categories ? state.articles.single.categories[0].slug : null);
    useEffect(() => {
        if(isArticle){
            let catg = location.state && location.state.sourcePath ? location.state.sourcePath === 'lm' ? 'learning-resource' : location.state.sourcePath : articleCatg;
            setArticleSource(catg);
        }
    }, [location, articleCatg, isArticle]);
    
    return (
        <>
            <nav className="side-menu">
                <NavLink exact className="side-menu--item" to={isAuthenticated ? "/" : "/education"}>
                    <Word value="Home" />
                    <ChevronRightIcon />
                </NavLink>
                <NavLink className="side-menu--item" to="/refer-patient">
                    <Word value="Refer a Patient" />
                    <ChevronRightIcon />
                </NavLink>
                <NavLink className={`side-menu--item${isArticle && (articleSource === 'treatment-pathways' || articleSource === 'treatment-techniques') ? ' active' : ''}`} to="/clinical-resources">
                    <Word value="Clinical Resources" />
                    <ChevronRightIcon />
                </NavLink>
                <div className="side-submenu">
                    <NavLink className={`side-menu--subitem${isArticle && articleSource === 'treatment-pathways' ? ' active' : ''}`} to="/clinical-resources/treatment-pathways">
                        <Word value="Treatment Pathways" />
                        <ChevronRightIcon />
                    </NavLink>
                    <NavLink className={`side-menu--subitem${isArticle && articleSource === 'treatment-techniques' ? ' active' : ''}`} to="/clinical-resources/treatment-techniques">
                        <Word value="Treatment Techniques" />
                        <ChevronRightIcon />
                    </NavLink>
                </div>
                <NavLink className={`side-menu--item${isArticle && (articleSource === 'clinical-article' ||  articleSource === 'learning-resource') ? ' active' : ''}`} to="/education">
                    <Word value="Education" />
                    <ChevronRightIcon />
                </NavLink>
                <div className="side-submenu">
                    <NavLink className={`side-menu--subitem${isArticle && articleSource === 'learning-resource' ? ' active' : ''}`} to="/education/learning-modules">
                        <Word value="Learning Modules" />
                        <ChevronRightIcon />
                    </NavLink>
                    <NavLink className={`side-menu--subitem${isEvent ? ' active' : ''}`} to="/education/events">
                        <Word value="Events" />
                        <ChevronRightIcon />
                    </NavLink>
                    <NavLink exact className={`side-menu--subitem${isArticle && articleSource === 'clinical-article' ? ' active' : ''}`} to="/education/clinical-articles">
                        <Word value="Clinical Articles" />
                        <ChevronRightIcon />
                    </NavLink>
                </div>
                {isAuthenticated ? 
                    <NavLink className={`side-menu--item`} to="/account/cpd-log">
                        <Word value="CPD Log" />
                        <ChevronRightIcon />
                    </NavLink>
                : null}
                <NavLink className={`side-menu--item${isArticle && articleSource === 'research' ? ' active' : ''}`} to="/research">
                    <Word value="Research" />
                    <ChevronRightIcon />
                </NavLink>
                <div className="side-submenu">
                    <NavLink className={`side-menu--subitem`} to="/research/clinical-trials">
                        <Word value="Clinical Trials" />
                        <ChevronRightIcon />
                    </NavLink>
                </div>
                <NavLink className={`side-menu--item${isArticle && articleSource === 'news' ? ' active' : ''}`} to="/news">
                    <Word value="News" />
                    <ChevronRightIcon />
                </NavLink>
                
            </nav>
        </>
    );
};

export default React.memo(SideMenu);