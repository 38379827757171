import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";


function PrivateRoute({ component: Component, roles, ...rest }) {

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated) {
                // not logged in so redirect to login page with the return url
                return <Redirect replace to={{ pathname: '/login', state: { from: props.location.pathname === '/account' ? null : props.location } }} />
            }

            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export default PrivateRoute;