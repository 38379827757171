import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import parse from 'html-react-parser';
import { generateCpdCertificate } from "../../store/features/slice-cpd";
import Link from '../Link';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg';
import { ReactComponent as StartIcon } from '../../assets/images/icons/star.svg';
import { ReactComponent as CheckedIcon } from '../../assets/images/icons/check.svg';
import { Word } from '../Word';
import Button from '../Button';
import ProgressBar from '../ProgressBar';
import Image from '../Image';


const FeedCard = (props) => {
    const dispatch = useDispatch();
    const doctorsListBasic = useSelector((state) => state.doctors.basic);
    return (
        <div className="card card-feed">
            <Link className="card-feed--main" to={props.Clicked || !props.link  ? null : props.link} onClick={props.Clicked ? props.Clicked : null}>
                {((props.categories && props.categories.length > 0) || props.isLive || props.progress) ? 
                    <div className="card-feed--categories">
                        {props.categories ? props.categories.map((category, index) => {
                            return <div className={`text text--category ${category.colour ? category.colour : ''}`} key={index}>
                                        {category.icon ? parse(category.icon) : null}
                                        {category.title}
                                    </div>
                        }) : null}
                        {props.isLive ?
                        <div className={`text text--category red`}>
                            <Word value="Live" />
                        </div>
                        : null}
                        {props.dateCompleted ?
                        <div className={`text text-green card-feed--date-completed`}>
                            <CheckedIcon />
                            {props.dateCompleted}
                        </div>
                        : null}
                        {props.progress ? <ProgressBar className="has-label-right" value={props.progress} /> : null}
                    </div>
                : null}
                <div className="card-feed--content">
                    {props.image ? 
                        <Image 
                            className="card-feed--content-figure"
                            src={props.image} 
                            alt={props.title ? props.title : ""}
                        />
                    : null}
                    <div className="card-feed--content-data">
                        {props.title ? <h4 className="card-feed--title blue">{props.title}</h4> : null }
                        {props.description ? <div className="card-feed--description">{props.description}</div> : null }
                    </div>
                </div>
                {props.completion || props.cpd ? 
                    <div className="card-feed--tags">
                        {props.completion ? 
                            <div className="text text--tag">
                                {<ClockIcon />}
                                {props.completion}
                            </div> 
                        : null}
                        {props.cpd ? 
                            <div className="text text--tag">
                                {<StartIcon />}
                                {props.cpd}
                            </div> 
                        : null}
                    </div>
                : null}
                {props.date ? 
                    <div className="text card-feed--date">
                        <CalendarIcon />
                        {props.date}
                    </div>
                : null}
                {props.time ? 
                    <div className="text card-feed--time">
                        <ClockIcon />
                        {props.time}
                    </div>
                : null}
                {props.authors && props.authors.length > 0 && doctorsListBasic ? 
                    <div className="card-feed--authors">
                        {props.authors.map((author, index) => {
                            return doctorsListBasic[author] ? <div key={index} className="text text--author">
                                        {doctorsListBasic[author].profileImage ? 
                                            <Image 
                                                src={doctorsListBasic[author].profileImage}
                                                alt={doctorsListBasic[author].name}
                                            /> 
                                        : null}
                                        {parse(doctorsListBasic[author].salutation+" "+doctorsListBasic[author].name)}
                                    </div>
                                : null
                        })}
                    </div>
                : null}
            </Link>
            {props.dateCompleted && props.cpd_type && props.cpd_type === 'Accredited' ? 
                <div className="card-feed--ctas">
                    {props.id && props.type ? 
                        <Button 
                            className="btn-primary text-yellow full-width"
                            text="Download Certificate"
                            Clicked={() => dispatch(generateCpdCertificate({parent: 'ida_certificate', id: props.id, type: props.type, }))}
                        />
                    : null}
                </div>
            : null}
            
        </div>
    );
};

export default React.memo(FeedCard);