import { configureStore } from '@reduxjs/toolkit';
import settingsReducer from './features/slice-settings';
import uiReducer from './features/slice-ui';
import alertsReducer from './features/slice-alerts';
import authReducer from './features/slice-auth';
import accountReducer from './features/slice-account';
import doctorsReducer from './features/slice-doctors';
import centresReducer from './features/slice-centres';
import eventsReducer from './features/slice-events';
import searchReducer from './features/slice-search';
import refReducer from './features/slice-references';
import learningModulesReducer from './features/slice-learning-modules';
import articlesReducer from './features/slice-articles';
import indexesReducer from './features/slice-indexes';
import qaReducer from './features/slice-qa';
import apiReducer from './features/slice-api';
import cpdReducer from './features/slice-cpd';
import clinicalTrialsReducer from './features/slice-clinical-trials';

export default configureStore({
  reducer: {
    api: apiReducer,
    settings: settingsReducer,
    ui: uiReducer,
    alerts: alertsReducer,
    auth: authReducer,
    account: accountReducer,
    references: refReducer,
    doctors: doctorsReducer,
    centres: centresReducer,
    events: eventsReducer,
    search: searchReducer,
    learningModules: learningModulesReducer,
    articles: articlesReducer,
    indexes: indexesReducer,
    qa: qaReducer,
    cpd: cpdReducer,
    clinicalTrials: clinicalTrialsReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    })
});