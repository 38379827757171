import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import { Link } from "react-router-dom";
import Section from "../../components/Section";
import { Word } from "../../components/Word"
import UserSummary from "../../components/UserSummary"
import { ReactComponent as CpdIcon } from '../../assets/images/icons/cpd.svg';
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';
import { ReactComponent as BellIcon } from '../../assets/images/icons/bell.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/icons/user.svg';
//Remove Foxo Integration
// import { ReactComponent as ConnectedIcon } from '../../assets/images/icons/connected_apps.svg';
import { ReactComponent as MarketingIcon } from '../../assets/images/icons/marketing_icon.svg';
import { ReactComponent as ArrrowRightIcon } from '../../assets/images/icons/chevron-right.svg';
import { ReactComponent as FavouritesIcon } from '../../assets/images/icons/star_solid.svg';

const AccountIndex = () => {
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.account.data);
  useEffect(() => {
    dispatch(setPageClass("page-account"));
    dispatch(setUpHeader({
      show:true,
      logo: true,
      profile: true,
      search: true
    }));
  }, [dispatch]);
  
  return (
    <>
    {accountData ? 
      <>
        <PageHead 
          className="page-account--page-head"
          title="My Account"
        />
        <PageContent className="page-account--page-content">
          <Section className="split-content w-40-60">
            <Section className="page-account--header"><UserSummary logout /></Section>
            <Section className="page-account--menu flush">
            <Link className="page-account--menu-item menu-item" to="/account/profile">
                  <ProfileIcon className="icon-title" />
                  <Word value="Profile" />
                  <ArrrowRightIcon className="icon-next" />
              </Link>
              <Link className="page-account--menu-item menu-item" to="/account/preferences">
                  <BellIcon className="icon-title" />
                  <Word value="Preferences" />
                  <ArrrowRightIcon className="icon-next" />
              </Link>
              <Link className="page-account--menu-item menu-item" to="/account/cpd-log">
                  <CpdIcon className="icon-title" />
                  <Word value="CPD Log" />
                  <ArrrowRightIcon className="icon-next" />
              </Link>
              <Link className="page-account--menu-item menu-item" to="/account/change-password">
                  <LockIcon className="icon-title" />
                  <Word value="Change Password" />
                  <ArrrowRightIcon className="icon-next" />
              </Link>
              <Link className="page-account--menu-item menu-item" to="/account/marketing">
                  <MarketingIcon className="icon-title" />
                  <Word value="Marketing" />
                  <ArrrowRightIcon className="icon-next" />
              </Link>
              {/* Remove Foxo Integration */}
              {/* <Link className="page-account--menu-item menu-item" to="/account/connected-apps">
                  <ConnectedIcon className="icon-title" />
                  <Word value="Connected Apps" />
                  <ArrrowRightIcon className="icon-next" />
              </Link> */}
              <Link className="page-account--menu-item menu-item" to="/account/favourites">
                  <FavouritesIcon className="icon-title" />
                  <Word value="Favourites" />
                  <ArrrowRightIcon className="icon-next" />
              </Link>
            </Section>
          </Section> 
        </PageContent>
      </>
    : null}
    </>
  );
};

export default AccountIndex;
