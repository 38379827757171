import React from 'react';
import {useBasePath} from "../helpers/customHooks"
import Navlink from './Navlink';

const Pagination = (props) => {
    const basePath = useBasePath();
    const renderPages = () => {
        let paginationArray = [];
        for(let i = 1; i <= props.total_pages; i++){
            paginationArray.push((<Navlink exact className={`pagination--item${i === props.current_page ? ' active' : ''}`} key={i} to={i === 1 ? basePath : `${basePath}/${i}`}>{i}</Navlink>));
        }
        return paginationArray;
    }

    return (
        <div className="pagination">
            {renderPages()}
        </div>
    );
};

export default Pagination;