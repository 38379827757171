import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { toast } from "../store/features/slice-alerts";
import { referPatient } from "../store/features/slice-doctors";
import { resetErrors } from "../store/features/slice-api";
import { Word } from "../components/Word";
import Select from "../components/Form/Select";
import Input from "../components/Form/Input";
import TextArea from "../components/Form/TextArea";
import Date from "../components/Form/Date";
import Button from "../components/Button";
import G from "../app/globals";

const ReferForm = ({ accountData }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    let { doctorSlug } = useParams();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const errors = useSelector((state) => state.api.errors.refer_patient);
    const centresListBasic = useSelector((state) => state.centres.basic);
    const doctorDetailsSingle = useSelector((state) => state.doctors.full ? state.doctors.full[doctorSlug] : null);
    const refPrefOptions = useMemo(() => [
        {
            label: 'Secure Quick e-Referral',
            value: 'sqer'
        },
        {
            label: 'Secure Online Referral form',
            value: 'sorf'
        },
        {
            label: 'Practice e-Referral',
            value: 'per'
        },
    ], []);

    const [refPref, setRefPref] = useState(refPrefOptions[0]);
    const [centresOptions, setCentreOptions] = useState([]);
    const [providerLocationOptions, setProviderLocationOptions] = useState([]);
    const initialFormState = {
        referral_form: refPrefOptions[0],
        provider_location: "",
        patient_details_files: [],
        clinical_notes_files: [],
        notes: "",
        patient_first_name: "",
        patient_last_name: "",
        patient_dob: "",
        patient_phone: "",
        patient_mobile: "",
        patient_address_street: "",
        patient_address_suburb: "",
        patient_address_postcode: "",
        patient_address_state: "",
        patient_gender: "",
        patient_condition: "",
        clinical_notes: "",
        general_files: "",
        centre: "",
        doctor: "",
        doctorFoxoId: "",
        doctorFoxoTeamId: "",
    };
    const [form, setForm] = useState(initialFormState);
    const [fromConfirmation, setFormConfirmation] = useState(false);
    const hiddenFileInput = []
    hiddenFileInput['patient_details'] = useRef();
    hiddenFileInput['clinical_notes'] = useRef();
    hiddenFileInput['general_files'] = useRef();

    useEffect(() => {
        let options = [];
        if (doctorDetailsSingle && doctorDetailsSingle.centres.length > 0) {
            options = doctorDetailsSingle.centres.map(centre => ({ value: centre.slug, label: centre.title }));
            setForm(f => ({ ...f, centre: options[0] }));
        }
        if (doctorDetailsSingle) {
            setForm(f => ({ ...f, doctor: parse(doctorDetailsSingle.salutation + ' ' + doctorDetailsSingle.name) }));
        }
        setCentreOptions(options);
    }, [doctorDetailsSingle, centresListBasic]);

    useEffect(() => {
        let options = [];
        if (accountData?.clinics?.length > 0) {
            options = accountData.clinics?.map(clinic => ({ label: clinic.name + ' - ' + clinic.provider_number, value: clinic.provider_number }))
            setForm(f => ({ ...f, provider_location: options[0] }));
        }
        else {
            options = [{ label: 'No Data', value: 'No Data' }]
            setForm(f => ({ ...f, provider_location: options[0] }));
        }
        setProviderLocationOptions(options);
    }, [accountData]);

    useEffect(() => {
        //empty previous errors from redux
        dispatch(resetErrors());
    }, [dispatch]);

    const handleRefPrefChange = (payload) => {
        setRefPref(payload);
        setForm({
            ...form,
            'referral_form': payload,
        });

    }
    const handleFileChange = async (e) => {
        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
        let fileField = [...form[e.target.name]];
        fileField.push({
            name: e.target.files[0].name,
            type: e.target.files[0].type,
            data: await toBase64(e.target.files[0])
        });
        setForm({
            ...form,
            [e.target.name]: fileField,
        });
    }

    const handleBtnFileClick = (e) => {
        hiddenFileInput[e.target.name].current.click();
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelectChange = (source, payload) => {
        setForm({
            ...form,
            [source]: payload
        });
    };

    const handleDeleteFile = (field, file) => {
        let files = form[field];
        files.splice(file, 1);
        setForm({ ...form, [field]: files })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isAuthenticated) {
            setFormConfirmation(true);
            G.RESET_SCROLL();
        }

    }
    const handleBack = (e) => {
        setFormConfirmation(false);
        G.RESET_SCROLL();
    }
    const handleSubmitFinal = () => {
        if (isAuthenticated) {
            G.RESET_SCROLL();
            dispatch(referPatient(form)).then(e => {
                setFormConfirmation(false);
                if (e.payload && e.payload.success) {
                    dispatch(resetErrors());
                    setForm(initialFormState);
                    history.push({ pathname: '/refer-patient/confirmation', state: { source: 'formsubmission', doctor: doctorSlug, file: e.payload?.pdf_confirmation?.file } });
                }
            });
        }
    }

    const copyRefInfo = (id) => {
        G.COPY_STRING_TO_CLIPBOARD(id);
        dispatch(toast(
            {
                message: 'ID has been copied to the clipboard',
                type: 'success',
                timeout: 5000
            }
        ))
    }

    const allowedFilesTypes = '.doc, .docx, .pdf, .jpg';


    return (
        <div className="page-referpatient-refer--form">
            {errors && errors.length > 0 ?
                <div className="form-errors">
                    {errors.map((error, index) => (
                        <div key={index} className="form-errors--item">{error.message}</div>
                    ))}
                </div>
                : null}
            {fromConfirmation ? null :
                <Select
                    title={'Referral preference'}
                    name={'referral_preference'}
                    id={'referral_preference'}
                    options={refPrefOptions}
                    onChange={handleRefPrefChange}
                    value={refPref}
                    defaultValue={refPrefOptions[0]}
                    isClearable={false}
                />
            }
            {refPref.value === 'sqer' ?
                <>
                    {!fromConfirmation ?
                        <form id="sqer" onSubmit={handleSubmit}>
                            <Select
                                title={'Available centres'}
                                name={'centre'}
                                id={'centre'}
                                options={centresOptions}
                                onChange={(payload) => handleSelectChange('centre', payload)}
                                value={form.centre}
                                isRequired
                                isClearable={false}
                                isDisabled={!isAuthenticated}
                            />
                            <Select
                                title={'Your provider location'}
                                name={'provider_location'}
                                id={'provider_location'}
                                options={providerLocationOptions}
                                value={form.provider_location}
                                onChange={(payload) => handleSelectChange('provider_location', payload)}
                                isRequired
                                isClearable={false}
                                isDisabled={!isAuthenticated}
                            />
                            <Input
                                type={"text"}
                                title={"Patient first name"}
                                name={"patient_first_name"}
                                value={form.patient_first_name}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <Input
                                type={"text"}
                                title={"Patient last name"}
                                name={"patient_last_name"}
                                value={form.patient_last_name}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <Date
                                title={'Patient DOB'}
                                name={'patient_dob'}
                                placeholder="DD/MM/YYYY"
                                value={form.patient_dob}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <Input
                                type={"text"}
                                title={"Patient phone"}
                                name={"patient_phone"}
                                value={form.patient_phone}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <div className="form-group">
                                <input
                                    type="file"
                                    ref={hiddenFileInput['patient_details']}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    name="patient_details_files"
                                    accept={allowedFilesTypes}
                                />
                                <Button
                                    className="btn-outline full-width page-referpatient-refer--form-upload"
                                    text="Attach Patient Details"
                                    Clicked={handleBtnFileClick}
                                    name="patient_details"
                                    isDisabled={!isAuthenticated}
                                />
                                <small>Allowed file types: {allowedFilesTypes}</small>
                                {form.patient_details_files && form.patient_details_files.length > 0 ?
                                    form.patient_details_files.map((file, index) => (
                                        <div className="page-referpatient-refer--form-upload-item" key={index}>
                                            <div className="page-referpatient-refer--form-upload-item-name">{file.name}</div>
                                            <Button
                                                className="btn-outline red text-red"
                                                text="Delete"
                                                Clicked={() => handleDeleteFile('patient_details_files', index)}
                                            />
                                        </div>
                                    ))
                                    : null}
                            </div>
                            <div className="form-group">
                                <input
                                    type="file"
                                    ref={hiddenFileInput['clinical_notes']}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    name="clinical_notes_files"
                                    accept={allowedFilesTypes}
                                />
                                <Button
                                    className="btn-outline full-width page-referpatient-refer--form-upload"
                                    text="Attach Clinical Notes"
                                    Clicked={handleBtnFileClick}
                                    name="clinical_notes"
                                    isDisabled={!isAuthenticated}
                                />
                                <small>Allowed file types: {allowedFilesTypes}</small>
                                {form.clinical_notes_files && form.clinical_notes_files.length > 0 ?
                                    form.clinical_notes_files.map((file, index) => (
                                        <div className="page-referpatient-refer--form-upload-item" key={index}>
                                            <div className="page-referpatient-refer--form-upload-item-name">{file.name}</div>
                                            <Button
                                                className="btn-outline red text-red"
                                                text="Delete"
                                                Clicked={() => handleDeleteFile('clinical_notes_files', index)}
                                            />
                                        </div>
                                    ))
                                    : null}
                            </div>
                            <TextArea
                                title={"Reason for referral"}
                                name={"notes"}
                                value={form.notes}
                                handleChange={handleChange}
                                isDisabled={!isAuthenticated}
                            />
                            <Button
                                className="btn-primary text-yellow full-width"
                                text="Validate Referral"
                                type="submit"
                                isDisabled={!isAuthenticated}
                            />
                        </form>
                        :
                        <div className="page-referpatient-refer--form-confirmation">
                            <h2><Word value="Please confirm your Secure Quick e-Referral" /></h2>
                            {form.centre ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Centre" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.centre.label}</div>
                                </div>
                                : null}
                            {form.doctor ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Specialist" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.doctor}</div>
                                </div>
                                : null}
                            {form.provider_location ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Your provider location" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.provider_location.label}</div>
                                </div>
                                : null}
                            {form.patient_first_name ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient first name" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_first_name}</div>
                                </div>
                                : null}
                            {form.patient_last_name ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient last name" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_last_name}</div>
                                </div>
                                : null}
                            {form.patient_dob ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient DOB" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_dob}</div>
                                </div>
                                : null}
                            {form.patient_phone ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient phone" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_phone}</div>
                                </div>
                                : null}
                            {form.patient_details_files && form.patient_details_files.length > 0 ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient Details - Attachments" /></h3>
                                    {
                                        form.patient_details_files.map((file, index) => (
                                            <div className="page-referpatient-refer--form-confirmation-item-value" key={index}>{file.name}</div>
                                        ))
                                    }
                                </div>
                                : null}
                            {form.clinical_notes_files && form.clinical_notes_files.length > 0 ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Clinical Notes - Attachments" /></h3>
                                    {
                                        form.clinical_notes_files.map((file, index) => (
                                            <div className="page-referpatient-refer--form-confirmation-item-value" key={index}>{file.name}</div>
                                        ))
                                    }
                                </div>
                                : null}
                            {form.notes ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Notes" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.notes}</div>
                                </div>
                                : null}
                            <Button
                                className="btn-primary text-yellow full-width"
                                text="Submit Referral"
                                Clicked={() => handleSubmitFinal('sqer')}
                            />
                            <Button
                                className="btn-outline full-width"
                                text="Edit"
                                Clicked={handleBack}
                            />
                        </div>

                    }
                </>
                : null}
            {refPref.value === "sorf" ?
                <>
                    {!fromConfirmation ?
                        <form id="sorf" onSubmit={handleSubmit}>
                            <Select
                                title={'Available centres'}
                                name={'centre'}
                                options={centresOptions}
                                onChange={(payload) => handleSelectChange('centre', payload)}
                                value={form.centre}
                                isRequired
                                isClearable={false}
                                isDisabled={!isAuthenticated}
                            />
                            <Select
                                title={'Your provider location'}
                                name={'provider_location'}
                                options={providerLocationOptions}
                                value={form.provider_location}
                                onChange={(payload) => handleSelectChange('provider_location', payload)}
                                isRequired
                                isClearable={false}
                                isDisabled={!isAuthenticated}
                            />
                            <h2><Word value="Patient details" /></h2>
                            <Input
                                type={"text"}
                                title={"Patient first name"}
                                name={"patient_first_name"}
                                value={form.patient_first_name}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <Input
                                type={"text"}
                                title={"Patient last name"}
                                name={"patient_last_name"}
                                value={form.patient_last_name}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <Date
                                title={'Patient DOB'}
                                name={'patient_dob'}
                                placeholder="DD/MM/YYYY"
                                value={form.patient_dob}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <Input
                                type={"text"}
                                title={"Patient phone"}
                                name={"patient_phone"}
                                value={form.patient_phone}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <div className={`form-container`}>
                                <h2><Word value="Patient address" /></h2>
                                <Input
                                    type={"text"}
                                    name={"patient_address_street"}
                                    title={"Street or Hospital"}
                                    value={form.patient_address_street}
                                    handleChange={handleChange}
                                    isRequired
                                    isDisabled={!isAuthenticated}
                                />
                                <Input
                                    type={"text"}
                                    name={"patient_address_suburb"}
                                    title={"Suburb"}
                                    value={form.patient_address_suburb}
                                    handleChange={handleChange}
                                    isRequired
                                    isDisabled={!isAuthenticated}
                                />
                                <Input
                                    type={"text"}
                                    name={"patient_address_postcode"}
                                    title={"Postcode"}
                                    value={form.patient_address_postcode}
                                    handleChange={handleChange}
                                    isRequired
                                    isDisabled={!isAuthenticated}
                                />
                                <Select
                                    title={'State'}
                                    name={'patient_address_state'}
                                    options={G.AUSTRALIA_STATES_OPTIONS}
                                    value={form.patient_address_state}
                                    onChange={(payload) => handleSelectChange('patient_address_state', payload)}
                                    isRequired
                                    isDisabled={!isAuthenticated}
                                />
                            </div>
                            {/* <Select
                                title={'Patient gender'}
                                name={'patient_gender'}
                                options={G.GENDER_OPTIONS}
                                value={form.patient_gender}
                                onChange={(payload) => handleSelectChange('patient_gender', payload)}
                            />
                            <Input
                                type={"text"}
                                title={"Patient condition"}
                                name={"patient_condition"}
                                value={form.patient_condition}
                                handleChange={handleChange}
                            /> */}
                            <TextArea
                                title={"Clinical notes"}
                                name={"clinical_notes"}
                                value={form.clinical_notes}
                                handleChange={handleChange}
                                isRequired
                                isDisabled={!isAuthenticated}
                            />
                            <div className="form-group">
                                <input
                                    type="file"
                                    ref={hiddenFileInput['general_files']}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    name="general_files"
                                    accept={allowedFilesTypes}
                                />
                                <Button
                                    className="btn-outline full-width page-referpatient-refer--form-upload"
                                    text="Attach Files / Pictures"
                                    Clicked={handleBtnFileClick}
                                    name="general_files"
                                    isDisabled={!isAuthenticated}
                                />
                                <small>Allowed file types: {allowedFilesTypes}</small>
                                {form.general_files && form.general_files.length > 0 ?
                                    form.general_files.map((file, index) => (
                                        <div className="page-referpatient-refer--form-upload-item" key={index}>
                                            <div className="page-referpatient-refer--form-upload-item-name">{file.name}</div>
                                            <Button
                                                className="btn-outline red text-red"
                                                text="Delete"
                                                Clicked={() => handleDeleteFile('general_files', index)}
                                            />
                                        </div>
                                    ))
                                    : null}
                            </div>
                            <Button
                                className="btn-primary text-yellow full-width"
                                text="Validate Referral"
                                type="submit"
                                isDisabled={!isAuthenticated}
                            />
                        </form>
                        :
                        <div className="page-referpatient-refer--form-confirmation">
                            <h2><Word value="Please confirm your Secure Referral" /></h2>
                            {form.centre ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Centre" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.centre.label}</div>
                                </div>
                                : null}
                            {form.doctor ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Specialist" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.doctor}</div>
                                </div>
                                : null}
                            {form.provider_location ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Your provider location" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.provider_location.label}</div>
                                </div>
                                : null}
                            {form.patient_first_name ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient first name" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_first_name}</div>
                                </div>
                                : null}
                            {form.patient_last_name ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient last name" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_last_name}</div>
                                </div>
                                : null}
                            {form.patient_dob ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient DOB" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_dob}</div>
                                </div>
                                : null}
                            {form.patient_phone ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient phone" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_phone}</div>
                                </div>
                                : null}
                            {form.patient_mobile ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient mobile" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_mobile}</div>
                                </div>
                                : null}
                            {form.patient_address_street ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient address" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">
                                        <div>{form.patient_address_street}</div>
                                        {form.patient_address_suburb ? <div>{form.patient_address_suburb}</div> : null}
                                        {form.patient_address_postcode ? <div>{form.patient_address_postcode}</div> : null}
                                        {form.patient_address_state ? <div>{form.patient_address_state.label}</div> : null}
                                    </div>
                                </div>
                                : null}
                            {form.patient_gender ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient gender" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_gender.label}</div>
                                </div>
                                : null}
                            {form.patient_condition ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Patient condition" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.patient_condition}</div>
                                </div>
                                : null}
                            {form.clinical_notes ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Clinical notes" /></h3>
                                    <div className="page-referpatient-refer--form-confirmation-item-value">{form.clinical_notes}</div>
                                </div>
                                : null}
                            {form.general_files && form.general_files.length > 0 ?
                                <div className="page-referpatient-refer--form-confirmation-item">
                                    <h3><Word value="Files - Attachments" /></h3>
                                    {
                                        form.general_files.map((file, index) => (
                                            <div className="page-referpatient-refer--form-confirmation-item-value" key={index}>{file.name}</div>
                                        ))
                                    }
                                </div>
                                : null}
                            <Button
                                className="btn-primary text-yellow full-width"
                                text="Submit Referral"
                                Clicked={() => handleSubmitFinal('sorf')}
                            />
                            <Button
                                className="btn-outline full-width"
                                text="Edit"
                                Clicked={handleBack}
                            />
                        </div>
                    }
                </>
                : null}
            {
                refPref.value === "per" ?
                    <div className="page-referpatient-refer--form-medical-objects">
                        {(doctorDetailsSingle && doctorDetailsSingle?.referralInfo && doctorDetailsSingle?.referralInfo.length > 0) && doctorDetailsSingle.referralInfo.map((item, index) => (
                            <div key={index} className="page-doctor-single--medical-object">
                                <h2>{item.platform} ID: {item.number}</h2>
                                {centresListBasic ? <p>{centresListBasic[item.centre].title}</p> : null}
                                <Button
                                    className="btn-primary text-yellow full-width"
                                    text="Copy"
                                    Clicked={() => copyRefInfo(item.number)}
                                />
                                <input
                                    type="text"
                                    readOnly
                                    style={{
                                        opacity: 0,
                                        height: 0,
                                        position: 'absolute',
                                        bottom: 0
                                    }}
                                    defaultValue={item.number}
                                    id={`id-${item.number}`}
                                />
                            </div>
                        ))}

                    </div>
                    : null
            }
        </div>
    )
};

export default ReferForm;