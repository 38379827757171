import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiErrors } from "./slice-api";
import { resetAccountReducer } from "./slice-account"; //setDefaultFilters
import { resetEventsReducer } from "./slice-events";
import { resetArticlesReducer } from "./slice-articles";
import { resetLmReducer } from "./slice-learning-modules";
import { resetDoctorsReducer } from "./slice-doctors";
import { resetSearchReducer } from "./slice-search";
import { resetIndexesReducer } from "./slice-indexes";
import { resetCpdReducer } from "./slice-cpd";
import axios from "../../services/apiService";
import { add_to_datalayer } from "../../helpers/tracking";

const initialState = {
  isAuthenticated: localStorage.getItem("wb_idp_token") ? true : false,
  isValidating: false
};

export const login = createAsyncThunk("auth/login", async (userData, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());

  try {
    await axios.post("api/v1/clear-refresh-token-cookie");
    const response = await axios.post("jwt-auth/v1/token", userData);
    thunkAPI.dispatch(setLoadingEnd());
    if(response.data.success){
      add_to_datalayer({
        event: 'User_Login',
      });
      thunkAPI.dispatch(clearReducers());
      localStorage.setItem("user_preferences", JSON.stringify(response.data.data && response.data.data.preferences ? response.data.data.preferences : null));
      //thunkAPI.dispatch(setDefaultFilters());
      return response.data;
    }
    else{
      thunkAPI.dispatch(handleApiErrors(response.data || response));
    }
    
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const validateToken = createAsyncThunk("auth/validate", async (userData, thunkAPI) => {
    try {
      const response = await axios.post("jwt-auth/v1/token/validate");
      if(!response.data.success){
        thunkAPI.dispatch(handleApiErrors(response.data || response));
      }
    }
    catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
    }
  }
);

export const refreshToken = createAsyncThunk("auth/refreshToken", async (userData, thunkAPI) => {
  try {
    const response = await axios.post("jwt-auth/v1/token/refresh");
    if(!response.data.success){
      thunkAPI.dispatch(handleApiErrors(response.data || response));
    }
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);



export const logout = () => {
  return (dispatch) => {
      clearLocalStorage();
      dispatch(logoutState());
      dispatch(clearReducers());
  }
};


export const clearReducers = () => {
  return (dispatch) => {
    dispatch(resetAccountReducer());
    dispatch(resetEventsReducer());
    dispatch(resetArticlesReducer());
    dispatch(resetLmReducer());
    dispatch(resetDoctorsReducer());
    dispatch(resetSearchReducer());
    dispatch(resetIndexesReducer());
    dispatch(resetCpdReducer());
  }
}


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginState(state) {
      state.isAuthenticated = true;
    },
    logoutState(state) {
      state.isAuthenticated = false;
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      if(action.payload){
        localStorage.setItem("wb_idp_token", action.payload.data.token);
        state.isAuthenticated = true;
      }
    },
    [login.rejected]: (state, action) => {
      console.log(action);
    },
    [validateToken.pending]: (state) => {
      state.isValidating = true;
    },
    [validateToken.fulfilled]: (state) => {
      state.isValidating = false;
    },
    [validateToken.rejected]: (state, action) => {
      console.log(action);
      state.isValidating = false;
    },
    [refreshToken.pending]: (state) => {
    },
    [refreshToken.fulfilled]: (state) => {
    },
    [refreshToken.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const { loginState, logoutState } = authSlice.actions;

export default authSlice.reducer;

const clearLocalStorage = () => {
  localStorage.removeItem("wb_idp_token");
  localStorage.removeItem("user_preferences");
};
