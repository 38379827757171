import React from 'react';
import { useSelector } from "react-redux";
import dictionary from '../services/languageService';

export const Word = React.memo((props) => {
    const lang = useSelector((state) => state.settings.language);
    return dictionary[props.value] && dictionary[props.value][lang] ? dictionary[props.value][lang] : props.value;
});

export const t = (lang, value) => {
    return dictionary[value] && dictionary[value][lang] ? dictionary[value][lang] : value;
}