import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { resetErrors } from "../../store/features/slice-api";
import { updatePassword } from "../../store/features/slice-account";
import { toast } from "../../store/features/slice-alerts";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import UserSummary from "../../components/UserSummary";
import Input from "../../components/Form/Input";
import Button from "../../components/Button";
import G from "../../app/globals"

const ChangePassword = () => {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        _current_password: '',
        _new_password: '',
        _confirm_new_password: '',
    });
    const errors = useSelector((state) => state.api.errors.update_account_password);
    const confirmPassField = useRef();
    useEffect(() => {
      dispatch(setPageClass("page-change-password"));
      dispatch(setUpHeader({
        show: true,
        back: true,
        profile: true,
        search: true
      }));
    }, [dispatch]);

    const handleChange = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
        if(e.target.name === '_new_password'){
            e.target.setCustomValidity(!new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=]).{9,}$").test(e.target.value) ? 'Password must be at least 9 characters long, include uppercase and lowercase letters, a number, and a special character from !@#$%^&*()_+-="' : '');
            if(e.target.checkValidity()) confirmPassField.current.pattern = String(e.target.value).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
        }
        if(e.target.name === '_confirm_new_password'){
            e.target.setCustomValidity(e.target.validity.patternMismatch ? 'Please enter the same Password as above.' : '');
        }
    };

    useEffect(() => {
        //empty previous errors from redux
        dispatch(resetErrors());
    }, [dispatch]);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updatePassword(form)).then((e) => {
            if(e.payload){
                G.RESET_SCROLL();
                setForm({
                    _current_password: '',
                    _new_password: '',
                    _confirm_new_password: '',
                });
                dispatch(toast({
                    message: e.payload.message,
                    type: 'success'
                }));
            }
        });
    }

    return (
        <>
            <PageHead 
            className="page-change-password--page-head"
            title="Change Password"
            />
            <PageContent className="page-change-password--page-content">
                <Section className="split-content w-40-60">
                    <Section><UserSummary /></Section>
                    <Section className="page-change-password--form">
                        {errors && errors.length > 0 ? 
                            <div className="form-errors">
                            {errors.map((error, index) => (
                                <div key={index} className="form-errors--item">{error.message}</div>
                            ))}
                            </div> 
                        : null}
                        <form onSubmit={handleSubmit}>
                            <Input
                                type={"password"}
                                name={"_current_password"}
                                title={"Current password"}
                                value={form._current_password}
                                handleChange={handleChange}
                                isRequired
                            />
                            <Input
                                type={"password"}
                                name={"_new_password"}
                                title={"New password"}
                                value={form._new_password}
                                handleChange={handleChange}
                                isRequired
                            />
                            <Input
                                type={"password"}
                                name={"_confirm_new_password"}
                                title={"Confirm new password"}
                                value={form._confirm_new_password}
                                handleChange={handleChange}
                                ref={confirmPassField}
                                isRequired
                            />
                            <div className="form-actions">
                                <Button
                                type={"submit"}
                                className="btn btn-primary btn-blue text-yellow full-width"
                                text="Update"
                            />
                            </div>
                        </form>
                    </Section>
                </Section>
            </PageContent>
        </>
    );
};

export default ChangePassword;
