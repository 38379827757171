import React, {useRef} from 'react';
import { Word } from '../Word';
import Select from 'react-select';


const WB_Select = (props) => {

  const selectRef = useRef();

  const noop = () => {
    // no operation (do nothing real quick)
  };

  return (
    <div className="form-group">
      {props.title ? (
        <label htmlFor={props.name} className="form-label">
          <Word value={props.title} />{props.isRequired ? '*' : ''}
        </label>
      ) : null}
      <Select 
        className="form-select-container"
        classNamePrefix='form-select'
        ref={selectRef}
        isClearable
        {...props}
        
      />
      {props.isRequired ? 
      <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: "100%",
              height: 0,
              position: "absolute",
              bottom: 0
            }}
            value={props.value ? props.value : ''}
            onFocus={() => selectRef.current.focus()}
            onChange={noop}
            required
          />
        : null}
    </div>
  );
};

export default React.memo(WB_Select);
