import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { setUpHeader } from "../store/features/slice-ui";


const NotFound = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setUpHeader({
      show: true,
      back: true,
      profile: true,
      search: true
  }));
  });

  return (
    <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">
      Go Home
    </Link>
  </div>
  )
}
  

export default NotFound;