import React from 'react';
import { Word } from '../Word';
import DayPickerInput from 'react-day-picker/DayPickerInput';

const WB_DatePicker = (props) => {
  
  return (
    <div className="form-group">
      {props.title ? (
        <label htmlFor={props.name} className="form-label">
          <Word value={props.title} />{props.isRequired ? '*' : ''}
        </label>
      ) : null}
      <DayPickerInput value={props.selected} onDayChange={props.onChange} placeholder={props.placeholder} dayPickerProps={props.dayPickerProps} />
    </div>
  );
};

export default React.memo(WB_DatePicker);
