import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../../store/features/slice-ui";
import { fetchEvents } from "../../../store/features/slice-events";
import PageHead from "../../../components/PageHead";
import EventsFilter from "../../../components/Filters/EventsFilter";
import PageContent from "../../../components/PageContent";
import Section from "../../../components/Section";
import Tabs from "../../../components/Tabs";
import FeaturedCard from "../../../components/Cards/Featured";
import { ReactComponent as LocationIcon } from '../../../assets/images/icons/location.svg';
import { ReactComponent as WifiIcon } from '../../../assets/images/icons/wifi.svg';

import G from "../../../app/globals";


const EventsIndex = () => {
  const [tabItems, setTabItems] = useState([]);
  const [FilterOpen, setFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountData = useSelector((state) => state.account.data);
  const events = useSelector((state) => state.events.list);
  const events_live = useSelector((state) => state.events.live);
  const events_registered = useSelector((state) => state.events.registered);
  const events_upcoming = useSelector((state) => state.events.upcoming);
  const events_recommended = useSelector((state) => state.events.recommended);
  const events_attended = useSelector((state) => state.events.attended);
  const events_past = useSelector((state) => state.events.past);
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  const filters = useSelector((state) => state.events.filters);
  const isFiltered = filters && (filters.topics || filters.type || filters.cpd_type || filters.date || filters.speakers)

  let { type = 'upcoming' } = useParams();
  useEffect(() => {
    dispatch(setPageClass("page-events"));
    dispatch(setUpHeader({
      show: true,
      back: true,
      profile: true,
      search: true,
      backTo: '/education'
    }));
  }, [dispatch]);



  useEffect(() => {
    let userPref = accountData && accountData.preferences ? accountData.preferences : null;
    if(isAuthenticated){
        if (((!events && userPref))) {
            dispatch(fetchEvents({ preferences: userPref}));
        }
    }
    else{
        if ((!events)) {
            dispatch(fetchEvents({ preferences: null }));
        }
    }
}, [dispatch, events, accountData, isAuthenticated]);

  useEffect(() => {
    let tabs = [];
    // if(events && events.length > 0){
    //   tabs.push({
    //       index: 'all',
    //       text: 'All', 
    //       Clicked:() => history.push('/education/events/all')
    //   })
    // }
    if(events_upcoming){
      tabs.push({
          index: 'upcoming',
          text: 'Upcoming',
          Clicked:() => history.push('/education/events')
      })
    }
    if(events_live && events_live.length > 0){
      tabs.push({
          index: 'live',
          text: 'Live', 
          Clicked:() => history.push('/education/events/live')
      })
    }
    if(events_registered && events_registered.length > 0){
        tabs.push({
          index: 'registered',
          text: 'Registered', 
          Clicked:() => history.push('/education/events/registered')
      });
    }
    if(events_recommended && events_recommended.length > 0){
      tabs.push({
          index: 'recommended',
          text: 'Recommended',
          Clicked:() => history.push('/education/events/recommended')

      });
    }
    if(events_attended && events_attended.length > 0){
      tabs.push({
          index: 'attended',
          text: 'Attended',
          Clicked:() => history.push('/education/events/attended')

      })
    }
    if(events_past && events_past.length > 0){
      tabs.push({
          index: 'past',
          text: 'Past',
          Clicked:() => history.push('/education/events/past')

      });
    }
    setTabItems(tabs);
  }, [events, events_live, events_registered, events_upcoming, events_recommended, events_attended, events_past, history]);

  const renderListEvents = (type) => {
    let list_events = [];
    switch(type){
      case 'live': list_events = events_live
      break;
      case 'registered': list_events = events_registered
      break;
      case 'upcoming': list_events = events_upcoming
      break;
      case 'recommended': list_events = events_recommended
      break;
      case 'attended': list_events = events_attended
      break;
      case 'past': list_events = events_past
      break;
      default: list_events = events;
    }
    return list_events && list_events.length > 0 ? list_events.map((event) => (
      <FeaturedCard 
        key={event.id}
        image={event.image_medium}
        title={parse(event.name)}
        categories={[{title: event.type, colour: 'purple', icon: event.type === 'Physical' ? <LocationIcon />  : <WifiIcon />}]}
        tags={event.cpd && event.cpd.type ? [{title: G.CPD_TEXT(event.cpd)}]: null}
        date={event.formattedDate}
        time={event.formattedTime}
        address={event.type === 'Physical' ? event.venue  : null}
        className="keen-slider__slide"
        link={`/education/event/${event.slug}`}
        stamp={events_live.length > 0 && events_live.find(item => item.id === event.id) ? {colour: 'red', title: 'Live'} : null}
    />
    )) : list_events && list_events.length === 0 && type === 'upcoming' ? <div className="page-events--grid-message">There are no upcoming events - please check back soon.</div> : null;
  }

  const handleFilterIconClick = () => {
    if(isDesktop){
      setFilterOpen(!FilterOpen);
    }
    else{
      history.push(`/education/events/filter`)
    }
  }
  
  return (
    <>
      <PageHead 
        className="page-events--page-head"
        title="Events"
        icon='filter'
        iconText={isDesktop ? (FilterOpen ? 'Close' : 'Filter') : 'Filter'}
        Clicked={handleFilterIconClick}
        secondaryIcon={isFiltered ? 'check' : null}
      />
      <PageContent className="page-events--page-content">
        {isDesktop ? 
          <EventsFilter 
            className={`page-events--filter in-page${FilterOpen ? '' : ' collapsed'}`}
            title="Search"
          />
        : null}
        <Section>
          <Tabs 
            className="page-events--tabs" 
            items={tabItems} 
            activeItem={type}
          />
          <div className="page-events--grid">
            {renderListEvents(type)}
          </div>
          
        </Section>
      </PageContent>
    </>
  );
};

export default EventsIndex;
