import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { fetchArticles } from "../../store/features/slice-articles";
import { fetchResearchData } from "../../store/features/slice-indexes";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import GlobalCtaCard from "../../components/Cards/CtaGlobal";
import FeedCard from "../../components/Cards/Feed";
import G from "../../app/globals"



const Research = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles.list['research']['articles']);
  const researchContent = useSelector((state) => state.indexes.research);
  const requestState = useSelector((state) => state.indexes.requests.research);
  const accountData = useSelector((state) => state.account.data);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    dispatch(setPageClass("page-research"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if (!researchContent && !requestState) {
        dispatch(fetchResearchData());
    }
  }, [dispatch, researchContent, requestState]);

  useEffect(() => {
    let userPref = accountData && accountData.preferences ? accountData.preferences : null;
    if(isAuthenticated){
        if (!articles && userPref) {
            dispatch(fetchArticles({ category: 'research', page: 1, ppp: -1, applyFilter: false, preferences: userPref }));
        }
    }
    else{
        if (!articles ) {
            dispatch(fetchArticles({ category: 'research', page: 1, ppp: -1, applyFilter: false, preferences: null }));
        }
    }
}, [dispatch, articles, accountData, isAuthenticated]);


const renderGloblCtas = (start, end) => {
    return researchContent && researchContent.global_ctas ? researchContent.global_ctas.map((item, index) => {
    if(index >= start && index < end){
      return <GlobalCtaCard 
        key={item.id}
        title={item.header}
        description={item.sub_header}
        icon={item.icon}
        link={item.link}
      />
    }
    else return null;
    }) : null
  }

  const renderArticles = () => {
    return articles ? articles.map(item => (
        <FeedCard
            key={item.id}
            image={item.thumbnail}
            title={parse(item.title)}
            description={parse(item.excerpt)}
            categories={item.format ? [{ 'title': item.format, 'colour': 'yellow', 'icon': item.format_icon ? item.format_icon : null }] : null}
            completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
            cpd={G.CPD_TEXT(item.cpd)}
            link={({ pathname: `/article/${item.slug}`,  state: { sourcePath: 'research' }})}
        />
    )) : null;
}

  
  return (
    <>
      <PageHead 
        className="page-research--page-head"
        title={researchContent ? researchContent.title : null}
        bg="blue"
      />
      <PageContent className="page-research--page-content">
       <Section className="page-research--the-content">
          {researchContent ? parse(researchContent.content, G.HTML_PARSE_OPTIONS) : null}
       </Section>
       {researchContent && researchContent.global_ctas && researchContent.global_ctas.length > 0 ? 
        <Section className="page-research--global-ctas" >
          {renderGloblCtas(0, 2)}
        </Section>
        : null }
        {articles && articles.length > 0 ? 
        <Section className="page-research--list">
          {renderArticles()}
        </Section>
        : null}
        {researchContent && researchContent.global_ctas && researchContent.global_ctas.length > 2 ? 
        <Section className="page-research--global-ctas" >
          {renderGloblCtas(2, 4)}
        </Section>
        : null }
        
      </PageContent>
    </>
  );
};

export default Research;
