import Button from '../Button';
import { ReactComponent as Check } from '../../assets/images/icons/check_solid_clean.svg';

const FlexibleCta = (props) => {

    return (
        <div className='flexible-cta'>
            <div className='flexible-cta--inner'>
                <div className='flexible-cta--image'>
                    <img 
                        src={props.image.src} 
                        alt={props.image.alt}
                    />
                </div>
                <div className='flexible-cta--text'>
                    <h2>{props.title}</h2>
                    <div className='flexible-cta--text-list'>
                    {props.list.map((item, index) => (
                        <div className='flexible-cta--text-list-item' key={index}>
                            <Check />
                            {item}
                        </div>
                    ))}
                    </div>
                    {props.cta && 
                        <Button 
                            className='btn btn-primary purple pill'
                            Clicked={() => props.cta.callback()}
                            text={props.cta.label}
                        />
                    }
                </div>
            </div>
        </div>
    );
    
};

export default FlexibleCta;