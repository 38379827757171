import React, {useEffect} from 'react';
import { useSelector } from "react-redux";
import { useKeenSlider } from 'keen-slider/react'


const Carousel = (props) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    

    const [silderRef, slider] = useKeenSlider({
        ...props.options,
        initial: 0,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide)
        }
    });

    useEffect(() => {
        //re-render componenet on breakpoint change
    }, [breakPoint]);

    return (
        <div className={`carousel${props.className ? ' '+props.className : ''}`}>
            <div ref={props.children.length > props.options.slidesPerView ? silderRef : null} className={props.children.length > props.options.slidesPerView ? 'keen-slider' : 'carousel--content'}>
                {props.children}
            </div>
            {props.hasDots ? 
            slider && Math.ceil(slider.details().size/slider.details().slidesPerView) > 1 &&  (
                <div className="carousel--dots">
                  {[...Array(Math.ceil(slider.details().size/slider.details().slidesPerView)).keys()].map((idx) => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          slider.moveToSlideRelative(idx)
                        }}
                        className={"carousel--dots-item" + (currentSlide === idx ? " active" : "")}
                      />
                    )
                  })}
                </div>
              )
            : null}
        </div>
        
    );
};

export default Carousel;