import React from 'react';
import { useLocation } from "react-router-dom";
import Link from '../Link';
import parse from 'html-react-parser';
import G from "../../app/globals";



const ClinicalTrialCard = (props) => {
    const location = useLocation();
    return (
        <Link className="card card-clinical-trial" to={props.Clicked || !props.link ? null : {pathname: props.link, state: { prevPath: location.pathname }}} onClick={props.Clicked ? props.Clicked : null}>
            
            <div className="card-clinical-trial--content">
                {props.laymanTitle ? <h4 className="card-clinical-trial--title">{parse(props.laymanTitle, G.HTML_PARSE_OPTIONS)}</h4> : null}
                {props.title ? 
                    <div className="text card-clinical-trial--summary">
                        {parse(props.title, G.HTML_PARSE_OPTIONS)}
                    </div>
                : null}
                {props.condition && props.condition.length > 0 ? 
                    <div className="card-clinical-trial--tags">
                        <div className="card-clinical-trial--tags-title">Diagnosis:</div>
                        <div className="card-clinical-trial--tags-items">
                            {props.condition.map((item, index) => {
                                return <div className="text text--tag bold" key={index}>
                                            {item}
                                        </div>
                            })}
                        </div>
                    </div>
                : null}
                {props.locations && props.locations.length > 0 ? 
                    <div className="card-clinical-trial--tags">
                        <div className="card-clinical-trial--tags-title">Locations:</div>
                        <div className="card-clinical-trial--tags-items">
                            {props.locations.map((item, index) => {
                                return <div className="text text--tag bold" key={index}>
                                            {item.label}
                                        </div>
                            })}
                        </div>
                    </div>
                : null}
            </div>
            <div className="card-clinical-trial--footer">
                {props.status ?  <div className={`card-clinical-trial--footer-status card-clinical-trial--footer-status-${props.status.value}`}>{props.status.label}</div> : null}
            </div>
        </Link>
    );
};

export default ClinicalTrialCard;