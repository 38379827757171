import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import { setPageClass, setUpHeader } from "../store/features/slice-ui";
import { resetErrors } from "../store/features/slice-api";
import { resetPassword } from "../store/features/slice-account";
import { toast } from "../store/features/slice-alerts";
import PageHead from "../components/PageHead";
import PageContent from "../components/PageContent";
import Input from "../components/Form/Input";
import Button from "../components/Button";

const ResetPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const errors = useSelector((state) => state.api.errors.reset_password);
  let query = new URLSearchParams(location.search);
  let token = query.get("token");
  const dispatch = useDispatch();
  const confirmPassField = useRef();
  useEffect(() => {
    dispatch(setPageClass("page-reset-password no-bottom-menu"));
    dispatch(setUpHeader({
      logo: true,
      sideNav: false
    }));
  });


  const initialFormState = {
    _token: '',
    _new_password: '',
    _confirm_new_password: ''
  };
  const [form, setState] = useState(initialFormState);

  const handleChange = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    if(e.target.name === '_new_password'){
      e.target.setCustomValidity(!new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=]).{9,}$").test(e.target.value) ? 'Password must be at least 9 characters long, include uppercase and lowercase letters, a number, and a special character from !@#$%^&*()_+-="' : '');
      if(e.target.checkValidity()) confirmPassField.current.pattern = String(e.target.value).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    if(e.target.name === '_confirm_new_password'){
        e.target.setCustomValidity(e.target.validity.patternMismatch ? 'Please enter the same Password as above.' : '');
    }
  };

  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);

  useEffect(() => {
    setState(f => ({...f, '_token': token}))
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword(form)).then((e) => {
        if(e.payload){
            history.push('/login');
            dispatch(toast({
                message: e.payload.message,
                type: 'success'
            }));
        }
    });
}

  return (
    <>
    <PageHead 
      className="page-reset-password--head"
      title="Reset Password"
    />
    <PageContent className="page-reset-password--content">
        {errors && errors.length > 0 ? 
          <div className="form-errors">
          {errors.map((error, index) => (
              <div key={index} className="form-errors--item">{error.message}</div>
          ))}
          </div> 
        : null}
        <form onSubmit={handleSubmit}>
            <Input
                type={"password"}
                name={"_new_password"}
                title={"New password"}
                value={form._new_password}
                handleChange={handleChange}
                isRequired
            />
            <Input
                type={"password"}
                name={"_confirm_new_password"}
                title={"Confirm new password"}
                value={form._confirm_new_password}
                handleChange={handleChange}
                ref={confirmPassField}
                isRequired
            />
            <div className="form-actions">
              <Button
                  type={"submit"}
                  className="btn btn-primary btn-blue text-yellow full-width"
                  text="Reset password"
              />
            </div>
        </form>          
      </PageContent>
    </>
  );
};

export default ResetPassword;
