import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import { fetchCpdLog, applyFilter } from "../../../store/features/slice-cpd";
import { setPageClass, setUpHeader } from "../../../store/features/slice-ui";
import PageHead from "../../../components/PageHead";
import PageContent from "../../../components/PageContent";
import Section from "../../../components/Section";
import FeedCard from "../../../components/Cards/Feed";
import CpdFilter from "../../../components/Filters/CpdFilter";
import G from "../../../app/globals";



const CpdList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [FilterOpen, setFilterOpen] = useState(false);
    const cpdList = useSelector((state) => state.cpd.list);
    const filters = useSelector((state) => state.cpd.filters);
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
    const isFiltered = filters && (filters.type || filters.cpd_type || filters.from_date || filters.to_date);
    const [timeDisplay, setTimeDisplay] = useState('');

    useEffect(() => {
        dispatch(setPageClass("page-cpd"));
        dispatch(setUpHeader({
            show: true,
            back: true,
            profile: true,
            search: true
        }));
    }, [dispatch]);

    useEffect(() => {
        if ((!cpdList)) {
            dispatch(fetchCpdLog()).then(() => {
                dispatch(applyFilter());
            });
        }
    }, [dispatch, cpdList]);


    useEffect(() => {
        // Helper functions as previously defined
        const normalizeTimeToMinutes = (time, unit) => {
            switch (unit) {
                case 'days':
                    return time * 24 * 60;
                case 'hours':
                    return time * 60;
                case 'mins':
                    return time;
                default:
                    return 0;
            }
        };
        const getTotalTimeInMinutes = (cpdList) => {
            return cpdList.reduce((acc, item) => {
                const { time, unit } = item.completion_time;
                const minutes = normalizeTimeToMinutes(parseInt(time, 10) || 0, unit);
                return acc + minutes;
            }, 0);
        };
        const convertMinutesToHoursAndMinutes = (totalMinutes) => {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return { hours, minutes };
        };
        if (cpdList) {
            const totalMinutes = getTotalTimeInMinutes(cpdList);
            const { hours, minutes } = convertMinutesToHoursAndMinutes(totalMinutes);
            let displayString = '';
            if (hours > 0) {
                displayString += `${hours} hour${hours > 1 ? 's' : ''}`;
            }
            if (minutes > 0) {
                if (displayString.length > 0) {
                    displayString += ` and `;
                }
                displayString += `${minutes} minute${minutes > 1 ? 's' : ''}`;
            }
            setTimeDisplay(displayString || 'No time logged');
        }
    }, [cpdList]);


    const renderCpdItems = () => {
        let category = null;
        let link = null;
        return cpdList && cpdList.length > 0 ? cpdList.map(item => {
            switch (item.post_type) {
                case 'article':
                    category = [{ 'title': 'Clinical Articles', 'colour': 'yellow' }];
                    link = `/article/${item.slug}`;
                    break;
                case 'learning-module':
                    category = [{ 'title': 'Learning Modules', 'colour': 'green' }];
                    link = `/education/learning-module/${item.slug}`;
                    break;
                case 'event':
                    category = [{ 'title': 'Events', 'colour': 'purple' }];
                    link = `/education/event/${item.slug}`;
                    break;
                default: category = null;
            }
            return <FeedCard
                key={item.id}
                id={item.id}
                type={item.post_type}
                image={item.thumbnail}
                title={parse(item.title)}
                description={item.excerpt ? parse(item.excerpt) : null}
                categories={category ? category : null}
                completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
                cpd={G.CPD_TEXT(item.cpd)}
                dateCompleted={item.completed_datetime ? G.FORMAT_DATE(item.completed_datetime) : ''}
                cpd_type={item.cpd ? item.cpd.type : null}
                link={({ pathname: link, prevPath: location.pathname })}
            />
        }) : cpdList?.length === 0 ? <div className="page--placeholder-message">You have not yet completed any CPD related education. Visit the Learning Modules section to get started.</div> : null;
    }

    const handleFilterIconClick = () => {
        if (isDesktop) {
            setFilterOpen(!FilterOpen);
        }
        else {
            history.push(`/account/cpd-log/filter`)
        }
    }

    return (
        <>
            <PageHead
                className="page-cpd--page-head"
                title="CPD Log"
                bg="blue"
                icon={'filter'}
                iconText={isDesktop ? (FilterOpen ? 'Close' : 'Filter') : 'Filter'}
                Clicked={handleFilterIconClick}
                secondaryIcon={isFiltered ? 'check' : null}
            />
            <PageContent className="page-cpd--page-content">
                {isDesktop ?
                    <CpdFilter
                        className={`page-cpd--filter in-page${FilterOpen ? '' : ' collapsed'}`}
                        title="Search"
                    />
                    : null}
                    {cpdList?.length > 0 && (
                        <Section className="page-cpd--time-logged">
                            <div className="page-cpd--time-logged-text">
                                <div className="page-cpd--time-logged-text-title">Time Logged: </div>
                                <div className="page-cpd--time-logged-text-value">{timeDisplay}</div>
                            </div>
                            <div className="page-cpd--time-logged-description">Based on the current filters set</div>
                        </Section>
                    )}
                <Section className="page-cpd--list">
                    {renderCpdItems()}
                </Section>
            </PageContent>
        </>
    );
};

export default CpdList;
