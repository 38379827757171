import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLearningModules, selectFilterItem, clearFilterItems, fetchLearningModulesCategories} from "../../store/features/slice-learning-modules";
import Section from "../Section";
import Button from "../Button";
import Select from '../Form/Select';
import { Word } from "../Word";


const LearningModulesFilter = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const topics = useSelector((state) => state.references.topics);
    const cpd_types = useSelector((state) => state.references.cpd_types);
    const categories = useSelector((state) => state.learningModules.categories);
    const filters = useSelector((state) => state.learningModules.filters);
    const [topicsSelect, setTopicsSelect] = useState(filters.topics);
    const [cpdTypesSelect, setCpdTypesSelect] = useState(filters.cpd_type);
    const [categoriesSelect, setCategoriesSelect] = useState(filters.category);
  
    const handleTopicsSelectChange = (payload) => {
        setTopicsSelect(payload);
    };
    const handleCpdTypesSelectChange = (payload) => {
        setCpdTypesSelect(payload);
    };
    const handleCategoriesSelectChange = (payload) => {
      setCategoriesSelect(payload);
  };

    useEffect(() => {
      setTopicsSelect(filters.topics);
    }, [filters.topics]);

    useEffect(() => {
      setCpdTypesSelect(filters.cpd_type);
    }, [filters.cpd_type]);

    useEffect(() => {
      setCategoriesSelect(filters.category);
    }, [filters.category]);

    useEffect(() => {
      if (!categories) {
          dispatch(fetchLearningModulesCategories());
      }
    }, [dispatch, categories]);

    const handleFilterSubmit = () => {
        dispatch(selectFilterItem( {data: topicsSelect , filter: 'topics'}));
        dispatch(selectFilterItem( {data: cpdTypesSelect , filter: 'cpd_type'}));
        dispatch(selectFilterItem( {data: categoriesSelect , filter: 'category'}));
        dispatch(fetchLearningModules()).then(() => {
          if(props.pushAfterSubmit){
            history.push(props.pushAfterSubmit)
          }
        });
    };

    const handleClearFilter = () => {
        setTopicsSelect(null);
        setCpdTypesSelect(null);
        setCategoriesSelect(null);
        if(filters.topics || filters.cpd_type || filters.category){
          dispatch(clearFilterItems());
          dispatch(fetchLearningModules());
        }
    };
    
    return (
        <Section className={`filter${props.className ? ' '+props.className : ''}`}>
          {props.title ? <h2><Word value={props.title} /></h2> : null}
          <div className="filter--form">
            {topics && topics.length > 0 ? 
              <Select 
                title={'Topics'}
                name={'topics'}
                id={'topics'}
                options={topics.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
                onChange={handleTopicsSelectChange}
                isMulti
                value={topicsSelect}
              />
            : null}
            {cpd_types && cpd_types.length > 0 ? 
              <Select 
                title={'CPD Type'}
                name={'cpd_type'}
                id={'cpd_type'}
                options={cpd_types}
                onChange={handleCpdTypesSelectChange}
                value={cpdTypesSelect}
              />
            : null}
            {categories && categories.length > 0 ? 
              <Select 
                title={'Category'}
                name={'category'}
                id={'category'}
                options={categories.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
                onChange={handleCategoriesSelectChange}
                value={categoriesSelect}
              />
            : null}
            {topics || cpd_types || categories ? 
              <div className="filter--actions">
                <Button
                    type={"button"}
                    className="btn-primary text-yellow full-width"
                    text="Filter"
                    Clicked={handleFilterSubmit}
                    isDisabled={!topicsSelect && !cpdTypesSelect && !categoriesSelect ? true : false}
                />
                <Button
                  type={"button"}
                  className="btn-outline full-width"
                  text="Clear all"
                  Clicked={handleClearFilter}
                  isDisabled={!topicsSelect && !filters.topics && !cpdTypesSelect && !filters.cpd_type && !categoriesSelect && !filters.category ? true : false}
                />
              </div>
            : null}
          </div>
        </Section>
    );
};

export default LearningModulesFilter;