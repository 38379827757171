import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../../store/features/slice-ui";
import { fetchEventSingle, unregisterEvent } from "../../../store/features/slice-events";
import { resetAccountReducer } from "../../../store/features/slice-account";
import { resetCpdReducer } from "../../../store/features/slice-cpd";
import { resetErrors } from "../../../store/features/slice-api";
import PageHead from "../../../components/PageHead";
import PageContent from "../../../components/PageContent";
import Section from "../../../components/Section";
import EventRegisterForm from "../../../components/EventRegisterForm";
import Button from "../../../components/Button";
import { Word } from "../../../components/Word";
import CtaCard from "../../../components/Cards/Cta";
import Image from "../../../components/Image";
import Share from "../../../components/Share";
import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/clock.svg';
import { ReactComponent as AddressIcon } from '../../../assets/images/icons/address.svg';
import { ReactComponent as CpdIcon } from '../../../assets/images/icons/cpd.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close_circle.svg';
import G from "../../../app/globals";

const EventsSingle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const registerRef = useRef();
  const [RegisterOpen, setRegisterOpen] = useState(false);
  const doctorsListBasic = useSelector((state) => state.doctors.basic);
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  let { slug } = useParams();
  const eventsSingle = useSelector((state) => state.events.single && state.events.single.slug === slug ? state.events.single : null);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountData = useSelector((state) => state.account.data);
  const is_registered = isAuthenticated && accountData && accountData.events && eventsSingle && typeof(accountData.events.find(ev => ev.event === eventsSingle.id && ev.status === 'Registered')) !== 'undefined';
  const is_upcoming = eventsSingle && G.NEW_DATE(eventsSingle.start.date) > new Date();

  useEffect(() => {
    dispatch(setPageClass("page-event-single"));
    dispatch(setUpHeader({
      show: true,
      back: true,
      profile: true,
      search: true,
      colour: 'white'
    }));
  });

  useEffect(() => {
    if ((!eventsSingle || (eventsSingle && eventsSingle.slug !== slug))) {
        dispatch(fetchEventSingle(slug));

    }
  }, [dispatch, eventsSingle, slug]);


  const openRegisterForm = () => {
      setRegisterOpen(true);
      registerRef.current.scrollIntoView({behavior: "smooth"});
  }

  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);


  const hanldeUnregisterEvent = () => {
    dispatch(unregisterEvent({
      _event_title: eventsSingle.name, 
      _event_id: eventsSingle.id,
      _first_name: accountData.firstName,
      _last_name: accountData.lastName,
      _email: accountData.email
    })).then((e) => {
      if(e.payload){
        dispatch(resetAccountReducer());
        dispatch(resetCpdReducer());
      }
    });
  }

  return (
    <>
      {isDesktop ? 
        <PageHead 
          className="page-event-single--page-head"
          title="Events"
        />
      : null}
      { eventsSingle ? 
      <PageContent className="page-event-single--page-content">
        <Section className="split-content w-60-40 page-event-single--header">
          <div className="page-event-single--details">
            <h1>{parse(eventsSingle.name)}</h1>
            <div className="page-event-single--datetime">
              <div className="text page-event-single--date">
                <CalendarIcon />
                {eventsSingle.formattedDate}
              </div>
              <div className="text page-event-single--time">
                <ClockIcon />
                {eventsSingle.formattedTime}
              </div>
            </div>
            {eventsSingle.venue ? 
              <div className="text page-event-single--location">
                <AddressIcon />
                {eventsSingle.venue}
              </div>
            : null}
            {eventsSingle.cpd?.type ? 
              <div className="text page-event-single--cpd">
                <CpdIcon />
                {G.CPD_TEXT(eventsSingle.cpd)}
              </div>
            : null}
            {eventsSingle.cventEventSummary ? 
              <Button 
                  className="btn-outline white full-width"
                  text="Summary"
                  Clicked={() => history.push(`/education/event/${eventsSingle.slug}/summary`)}
              />
            : null}
            {eventsSingle.cventEventAgenda ? 
              <Button 
                  className="btn-outline white full-width"
                  text="Agenda"
                  Clicked={() => history.push(`/education/event/${eventsSingle.slug}/agenda`)}
              />
            : null}
            {is_upcoming ? 
              is_registered ? 
                <Button 
                    className="btn-primary purple text-yellow full-width"
                    text="Unregister"
                    Clicked={hanldeUnregisterEvent}
                />
              : eventsSingle.registrationLink ?
                  <Button 
                    className="btn-primary purple text-yellow full-width"
                    text="Register"
                    Clicked={() => window.open(eventsSingle.registrationLink, '_blank')}
                  />
                : 
                  <Button 
                      className="btn-primary purple text-yellow full-width"
                      text="Register"
                      Clicked={openRegisterForm}
                  />
            : null}
            <Share className="page-event-single--share purple" title={parse(eventsSingle.name)} />
          </div>
          {eventsSingle.profileImage ? 
            <div className="page-event-single--image">
              <Image 
                  src={eventsSingle.profileImage}
                  alt={parse(eventsSingle.name)}
              />
            </div>
          : null}
          
        </Section>

        {/* <div data-cvt-embed></div> */}

        <div ref={registerRef}></div>
        {
          RegisterOpen ? 
          <>
            
            <Section className={`page-event-single--register${RegisterOpen ? '' : ' collapsed'}`} >
              <div className="page-event-single--register-close" onClick={() => setRegisterOpen(false)}><CloseIcon /></div>
              <EventRegisterForm event={eventsSingle} accountData={accountData} />
            </Section>
          </>
          : null
        }
        <Section className="page-event-single--content">
            {eventsSingle.description ? 
              <div className="page-event-single--description page-event-single--content-section">
                <h3><Word value="About the event" /></h3>
                {parse(eventsSingle.description)}
              </div>
            : null}
            {eventsSingle.topics && eventsSingle.topics.length > 0  ? 
              <div className="page-event-single--topics page-event-single--content-section">
                <h3><Word value="Topics" /></h3>
                {eventsSingle.topics.map((topic, index) => {
                    return <div className="page-event-single--topics--item" key={index}>
                                {topic.name}
                            </div>
                })}
              </div>
            : null}
            {eventsSingle.agenda && eventsSingle.agenda.length > 0  ? 
              <div className="page-event-single--agenda page-event-single--content-section">
                <h3><Word value="Agenda" /></h3>
                {eventsSingle.agenda.map((item, index) => {
                    return <div className="page-event-single--agenda--item" key={index}>
                                {item.title}
                            </div>
                })}
              </div>
            : null}
            {(doctorsListBasic && eventsSingle.speakers && eventsSingle.speakers.length > 0) || (eventsSingle.guestSpeakers && eventsSingle.guestSpeakers.length > 0) ? 
              <div className="page-event-single--speakers page-event-single--content-section">
                <h3><Word value="Speakers" /></h3>
                <div className="page-event-single--speakers-cards">
                  {eventsSingle.speakers && eventsSingle.speakers.length > 0 ? eventsSingle.speakers.map((id, index) => {
                      return doctorsListBasic && doctorsListBasic[id] ? <CtaCard 
                                key={index}
                                image={doctorsListBasic[id].profileImage}
                                title={parse(doctorsListBasic[id].salutation+' '+doctorsListBasic[id].name)}
                                icon={"chevron_right"}
                                iconBg={"blue"}
                                isCentered
                                isSmall
                                link={`/refer-patient/${id}`}
                              /> : null
                  }) : null}
                  {eventsSingle.guestSpeakers && eventsSingle.guestSpeakers.length > 0 ? eventsSingle.guestSpeakers.map((item, index) => {
                      return <CtaCard 
                                key={index}
                                image={item.image}
                                title={item.name}
                                isCentered
                                isSmall
                              />
                  }) : null}
                </div>
              </div>
            : null}
            {eventsSingle.links && eventsSingle.links.length > 0  ? 
                <div className="page-event-single--links page-event-single--content-section">
                  <h3><Word value="Links" /></h3>
                  {eventsSingle.links.map((item, index) => {
                      return <a href={item.link.url} target="_blank" rel="noreferrer" className="page-event-single--links--item" key={index}>
                                  {item.link.title}
                              </a>
                  })}
                </div>
            : null}
        </Section>
      </PageContent>
      : null }
    </>
    
  );
};

export default EventsSingle;
