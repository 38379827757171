import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { fetchDoctorsFilter, selectFilterItem, clearFilterItems } from "../../store/features/slice-doctors";
import Section from "../Section";
import Button from "../Button";
import Select from '../Form/Select';
import { Word } from "../Word";


const DoctorsFilter = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const special_interests = useSelector((state) => state.doctors.taxonomies.special_interests);
  const fields = useSelector((state) => state.doctors.taxonomies.fields);
  const locations = useSelector((state) => state.centres.taxonomies.locations);
  const doctorsListBasic = useSelector((state) => state.doctors.basic);
  const centresListBasic = useSelector((state) => state.centres.basic);
  const filters = useSelector((state) => state.doctors.filters);
  const [specialInterestsSelect, setSpecialInterestsSelect] = useState(filters.special_interests);
  const [fieldsSelect, setFieldsSelect] = useState(filters.fields);
  const [locationSelect, setLocationSelect] = useState(filters.locations);
  const [locationOptions, setLocationOptions] = useState([]);
  const [doctorsSelect, setDoctorsSelect] = useState(filters.doctor_name);
  const [centresSelect, setCentresSelect] = useState(filters.doctor_centre);
  const [filteredCentres, setFilteredCentres] = useState([]);

   useEffect(() => {
    // Initialize filteredCentres with all centres when centresListBasic is loaded
    if (centresListBasic) {
      setFilteredCentres(Object.values(centresListBasic));
    }
  }, [centresListBasic]);


  const handleSpecialInterestsSelectChange = (payload) => {
    setSpecialInterestsSelect(payload);
  };

  const handleFieldsSelectChange = (payload) => {
    setFieldsSelect(payload);
  };

  const handleLocationSelectChange = (payload) => {
    const centres = Object.values(centresListBasic);
    setLocationSelect(payload);
    if(payload){
      const filteredCentres = centres.filter(item => {
        if(payload.type === "state" && item.location?.address){
          return item.location?.state_short?.toLowerCase() === payload.value.toLowerCase() || item.location.address.toLowerCase().includes((" "+payload.value+" ").toLowerCase());
        }
        if(payload.type === "city" && item.location?.address){
          return item.localities?.includes(payload.value) || item.location.address.toLowerCase().includes((" "+payload.value+" ").toLowerCase());
        }
        return true;
      });
      if(filteredCentres.length > 0){
        setFilteredCentres(filteredCentres);
      }
      else{
        setFilteredCentres(centres);
      }
    }
    else{
      setFilteredCentres(centres);
    }
  };

  const handleDoctorSelectChange = (payload) => {
    setDoctorsSelect(payload);
  };

  const handleCentreSelectChange = (payload) => {
    setCentresSelect(payload);
  };

  const handleFilterSubmit = () => {
    dispatch(selectFilterItem({ data: specialInterestsSelect, filterItem: 'special_interests'}));
    dispatch(selectFilterItem({ data: fieldsSelect, filterItem: 'fields'}));
    dispatch(selectFilterItem({ data: locationSelect, filterItem: 'locations'}));
    dispatch(selectFilterItem({ data: doctorsSelect, filterItem: 'doctor_name'}));
    dispatch(selectFilterItem({ data: centresSelect, filterItem: 'doctor_centre'}));
    dispatch(fetchDoctorsFilter()).then(() => {
      if (props.pushAfterSubmit) {
        history.push(props.pushAfterSubmit)
      }
    });

  };

  const handleClearFilter = () => {
    setSpecialInterestsSelect(null);
    setFieldsSelect(null);
    setLocationSelect(null)
    setDoctorsSelect(null)
    setCentresSelect(null);
    if (filters.special_interests || filters.fields || filters.locations || filters.doctor_name || filters.doctor_centre) {
      dispatch(clearFilterItems());
    }
  };

  useEffect(() => {
    let options = [];
    if (locations) {
      if (locations.states) {
        options.push({
          label: "States",
          options: locations.states.map(item => ({ 'value': item, 'label': item, 'type': 'state' }))
        })
      }
      if (locations.cities) {
        options.push({
          label: "Cities",
          options: locations.cities.map(item => ({ 'value': item, 'label': item, type: 'city' }))
        })
      }
    }
    setLocationOptions(options);
  }, [locations]);

  useEffect(() => {
    setSpecialInterestsSelect(filters.special_interests);
  }, [filters.special_interests]);

  useEffect(() => {
    setFieldsSelect(filters.fields);
  }, [filters.fields]);

  useEffect(() => {
    setLocationSelect(filters.locations);
  }, [filters.locations]);

  useEffect(() => {
    setDoctorsSelect(filters.doctor_name);
  }, [filters.doctor_name]);

  useEffect(() => {
    setCentresSelect(filters.doctor_centre);
  }, [filters.doctor_centre]);

  return (
    <Section className={`filter${props.className ? ' ' + props.className : ''}`}>
      <div className="filter--content">
        {props.title ? <h2><Word value={props.title} /></h2> : null}
        <div className="filter--form">
          {doctorsListBasic && Object.values(doctorsListBasic).length > 0 ?
            <Select
              title={'Name'}
              name={'doctor_name'}
              id={'doctor_name'}
              options={Object.values(doctorsListBasic).map(item => ({ 'value': item['slug'], 'label': parse(item['name']) }))}
              onChange={handleDoctorSelectChange}
              value={doctorsSelect}
              placeholder='Type specialist name'
            />
          : null}
          {locations ?
            <>
              <Select
                name="location"
                id={'location'}
                title="Location"
                options={locationOptions}
                onChange={handleLocationSelectChange}
                value={locationSelect}
              />
            </>
            : null}
          {filteredCentres && filteredCentres.length > 0 ?
            <Select
              title={'Centre'}
              name={'doctor_centre'}
              id={'doctor_centre'}
              options={filteredCentres.map(item => ({ 'value': item['slug'], 'label': parse(item['title']) }))}
              onChange={handleCentreSelectChange}
              value={centresSelect}
            />
            : null}
          {fields && fields.length > 0 ?
            <>
              <Select
                name="fields"
                id={'fields'}
                title="Speciality"
                options={fields.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
                onChange={handleFieldsSelectChange}
                isMulti
                value={fieldsSelect}
              />
            </>
            : null}
          {special_interests && special_interests.length > 0 ?
            <Select
              title={'Special Interests'}
              name={'special_interests'}
              id={'special_interests'}
              options={special_interests.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
              onChange={handleSpecialInterestsSelectChange}
              isMulti
              value={specialInterestsSelect}
            />
            : null}
          {(special_interests) || (fields) || (locations) || (doctorsListBasic) ?
            <div className="filter--actions">
              <Button
                type={"button"}
                className="btn-primary text-yellow full-width"
                text="Filter"
                Clicked={handleFilterSubmit}
                isDisabled={!specialInterestsSelect && !fieldsSelect && !locationSelect && !doctorsSelect && !centresSelect ? true : false}
              />
              <Button
                type={"button"}
                className="btn-outline full-width"
                text="Clear all"
                Clicked={handleClearFilter}
                isDisabled={filters.special_interests || filters.fields || filters.locations || filters.doctor_name || filters.doctor_centre ? false : true}
              />
            </div>
            : null}
        </div>
      </div>
    </Section>
  );
};

export default DoctorsFilter;