import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';

const initialState = {
  requests: {
    home: null,
    education: null,
    cr: null,
    research: null
  },
  home: null,
  education: null,
  cr: null,
  research: null
};


export const fetchHomeData = createAsyncThunk("index/getHome", async (parameters,thunkAPI) => {
  let userPreferences = parameters && parameters.preferences ? parameters.preferences.map(function (obj) {return obj.value}) : null;
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/home-feeds` , {
      type : 'public',
      params:{
        topics_preferences: userPreferences ? userPreferences : null
      }
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
    
  }
  catch (error) {
      let errorData = (error.response || {}).data;  
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchEducationData = createAsyncThunk("index/getEducation", async (parameters,thunkAPI) => {
  let userPreferences = parameters && parameters.preferences ? parameters.preferences.map(function (obj) {return obj.value}) : null;
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/education`, {
      type : 'public',
      params:{
        topics_preferences: userPreferences ? userPreferences : null
      }
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue((errorData));
  }
}
);

export const fetchClinicalResourcesData = createAsyncThunk("index/getClinicalResources", async (parameters,thunkAPI) => {
  let userPreferences = parameters && parameters.preferences ? parameters.preferences.map(function (obj) {return obj.value}) : null;
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/clinical-resources`, {
      type : 'public',
      params:{
        topics_preferences: userPreferences ? userPreferences : null
      }
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;  
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue((errorData));
  }
}
);

export const fetchResearchData = createAsyncThunk("index/getResearch", async (parameters,thunkAPI) => {
  
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/research`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;  
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);


const IndexesSlice = createSlice({
  name: "indexes",
  initialState,
  reducers: {
    resetIndexesReducer() {
      return initialState
    }
  },
  extraReducers: {
    [fetchHomeData.pending]: (state, action) => {
      state.requests.home = 'pending'
    },
    [fetchHomeData.fulfilled]: (state, action) => {
      state.home = action.payload;
      state.requests.home = 'fulfilled'
    },
    [fetchHomeData.rejected]: (state, action) => {
      console.log(action);
      state.requests.home = 'rejected';
    },
    [fetchEducationData.pending]: (state, action) => {
      state.requests.education = 'pending'
    },
    [fetchEducationData.fulfilled]: (state, action) => {
      state.education = action.payload;
      state.requests.education = 'fulfilled'
    },
    [fetchEducationData.rejected]: (state, action) => {
      console.log(action);
      state.requests.education = 'rejected';
    },
    [fetchClinicalResourcesData.pending]: (state, action) => {
      state.requests.cr = 'pending'
    },
    [fetchClinicalResourcesData.fulfilled]: (state, action) => {
      state.cr = action.payload;
      state.requests.cr = 'fulfilled'
    },
    [fetchClinicalResourcesData.rejected]: (state, action) => {
      console.log(action);
      state.requests.cr = 'rejected';
    },
    [fetchResearchData.pending]: (state, action) => {
      state.requests.research = 'pending'
    },
    [fetchResearchData.fulfilled]: (state, action) => {
      state.research = action.payload;
      state.requests.research = 'fulfilled'
    },
    [fetchResearchData.rejected]: (state, action) => {
      console.log(action);
      state.requests.research = 'rejected';
    }
  }
});

export const { resetIndexesReducer } = IndexesSlice.actions
export default IndexesSlice.reducer;

