import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";

import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import { Word } from "../../components/Word";
import { Link } from "react-router-dom";
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';
import { ReactComponent as AccountIcon } from '../../assets/images/icons/account.svg';
import { ReactComponent as ConnectedIcon } from '../../assets/images/icons/connected.svg';
import { ReactComponent as ExlamationIcon } from '../../assets/images/icons/exlamation.svg';
import { ReactComponent as ArrrowRightIcon } from '../../assets/images/icons/chevron-right.svg';
import { ReactComponent as DcotorIcon } from '../../assets/images/icons/doctor.svg';
import { ReactComponent as TtIcon } from '../../assets/images/icons/treatment-technique.svg';
import { ReactComponent as EducationIcon } from '../../assets/images/icons/education.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import APP_DATA from '../../../package.json';


const More = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageClass("page-more"));
    dispatch(setUpHeader({
      show:true,
      logo: true,
      back: false,
      close: false,
      profile: true,
      search: true
    }));
  });
  
  
  return (
    <>
      <PageHead 
        className="page-more--page-head"
        title="More"
      />
      <PageContent>
        <Section className="page-more--menu flush">
            <Link className="page-more--menu-item menu-item" to="/search">
                <SearchIcon className="icon-title" />
                <Word value="Search" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <Link className="page-more--menu-item menu-item" to="/research/clinical-trials">
                <DcotorIcon className="icon-title" />
                <Word value="Clinical Trials" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <Link className="page-more--menu-item menu-item" to="/clinical-resources">
                <LockIcon className="icon-title" />
                <Word value="Clinical Resources" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <Link className="page-more--menu-item menu-item" to="/education">
                <EducationIcon className="icon-title" />
                <Word value="Education" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <Link className="page-more--menu-item menu-item" to="/research">
                <AccountIcon className="icon-title" />
                <Word value="Research" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <Link className="page-more--menu-item menu-item" to="/contact-icon">
                <ConnectedIcon className="icon-title" />
                <Word value="Contact Icon" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <Link className="page-more--menu-item menu-item" to="/about-icon">
                <TtIcon className="icon-title" />
                <Word value="About Icon" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <Link className="page-more--menu-item menu-item" to="/report-issue">
                <ExlamationIcon className="icon-title" />
                <Word value="Report an Issue" />
                <ArrrowRightIcon className="icon-next" />
            </Link>
            <div className="page-more--version">{`v${APP_DATA.version}`}</div>
        </Section>
      </PageContent>
    </>
  );
};

export default More;
