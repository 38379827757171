import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { selectFilterItem, clearFilterItems, fetchEvents } from "../../store/features/slice-events";
import Section from "../Section";
import Button from "../Button";
import Select from '../Form/Select';
import DatePicker from '../Form/DatePicker';
import { Word } from "../Word";
import G from "../../app/globals";



const EventsFilter = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountData = useSelector((state) => state.account.data);
  const topics = useSelector((state) => state.references.topics);
  const cpd_types = useSelector((state) => state.references.cpd_types);
  const types = useSelector((state) => state.events.taxonomies.types);
  const filters = useSelector((state) => state.events.filters);
  const doctorsListBasic = useSelector((state) => state.doctors.basic);
  const [topicsSelect, setTopicsSelect] = useState(filters.topics);
  const [typesSelect, setTypesSelect] = useState(filters.type);
  const [cpdTypesSelect, setCpdTypesSelect] = useState(filters.cpd_type);
  const [startDate, setStartDate] = useState(filters.date);
  const [speakersSelect, setSpeakersSelect] = useState(filters.speakers);
  const [statesSelect, setStatesSelect] = useState(filters.state);


  const handleTopicsSelectChange = (payload) => {
    setTopicsSelect(payload);
  };
  const handleTypesSelectChange = (payload) => {
    setTypesSelect(payload);
  };
  const handleCpdTypesSelectChange = (payload) => {
    setCpdTypesSelect(payload);
  };

  const handleSpeakersSelectChange = (payload) => {
    setSpeakersSelect(payload);
  };

  const handleStateSelectChange = (payload) => {
    setStatesSelect(payload);
  };

  const handleFilterSubmit = () => {
    dispatch(selectFilterItem({ data: topicsSelect, filter: 'topics' }));
    dispatch(selectFilterItem({ data: typesSelect, filter: 'type' }));
    dispatch(selectFilterItem({ data: cpdTypesSelect, filter: 'cpd_type' }));
    dispatch(selectFilterItem({ data: startDate, filter: 'date' }));
    dispatch(selectFilterItem({ data: speakersSelect, filter: 'speakers' }));
    dispatch(selectFilterItem({ data: statesSelect, filter: 'state' }));
    dispatch(fetchEvents()).then(() => {
      if (props.pushAfterSubmit) {
        history.push(props.pushAfterSubmit)
      }
    });


  };

  const handleClearFilter = () => {
    let userPref = accountData && accountData.preferences ? accountData.preferences : null;

    setTopicsSelect(null);
    setTypesSelect(null);
    setCpdTypesSelect(null);
    setStartDate(null);
    setSpeakersSelect(null);
    setStatesSelect(null);
    if (filters.topics || filters.type || filters.cpd_type || filters.date || filters.speakers || filters.state) {
      dispatch(clearFilterItems());
      if (isAuthenticated && userPref) {
        dispatch(fetchEvents({ preferences: userPref }));
      }
      else {
        dispatch(fetchEvents({ preferences: null }));
      }
    }
  };

  return (
    <Section className={`filter${props.className ? ' ' + props.className : ''}`}>
      {props.title ? <h2><Word value={props.title} /></h2> : null}
      <div className="filter--form">
        {topics && topics.length > 0 ?
          <Select
            title={'Topics'}
            name={'topics'}
            id={'topics'}
            options={topics.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
            onChange={handleTopicsSelectChange}
            isMulti
            value={topicsSelect}
          />
          : null}
        {types && types.length > 0 ?
          <Select
            title={'Event Type'}
            name={'type'}
            id={'type'}
            options={types}
            onChange={handleTypesSelectChange}
            value={typesSelect}
          />
          : null}
        {cpd_types && cpd_types.length > 0 ?
          <Select
            title={'CPD Type'}
            name={'cpd_type'}
            id={'cpd_type'}
            options={cpd_types}
            onChange={handleCpdTypesSelectChange}
            value={cpdTypesSelect}
          />
          : null}
        <DatePicker
          title={'Date'}
          name={'date'}
          id={'date'}
          placeholder="Select..."
          selected={startDate}
          onChange={(date) => setStartDate(date ? date.toLocaleDateString() : null)}
        />
        {doctorsListBasic && Object.keys(doctorsListBasic).length > 0 ?
          <Select
            title={'Speakers'}
            name={'speakers'}
            id={'speakers'}
            options={Object.values(doctorsListBasic).map(item => ({ label: parse(item.name), value: item.slug }))}
            onChange={handleSpeakersSelectChange}
            value={speakersSelect}
            isMulti
          />
          : null}

        {G.AUSTRALIA_STATES_OPTIONS && G.AUSTRALIA_STATES_OPTIONS.length > 0 ?
          <Select
            title={'State'}
            name={'state'}
            id={'state'}
            options={G.AUSTRALIA_STATES_OPTIONS}
            onChange={handleStateSelectChange}
            value={statesSelect}
          />
          : null}
        {topics || types || cpd_types || doctorsListBasic || G.AUSTRALIA_STATES_OPTIONS ?
          <div className="filter--actions">
            <Button
              type={"button"}
              className="btn-primary text-yellow full-width"
              text="Filter"
              Clicked={handleFilterSubmit}
              isDisabled={!topicsSelect && !typesSelect && !cpdTypesSelect && !startDate && !speakersSelect && !statesSelect ? true : false}
            />
            <Button
              type={"button"}
              className="btn-outline full-width"
              text="Clear all"
              Clicked={handleClearFilter}
              isDisabled={!topicsSelect && !filters.topics && !typesSelect && !filters.type && !cpdTypesSelect && !filters.cpd_type && !startDate && !filters.date && !speakersSelect && !filters.speakers && !statesSelect  && !filters.state ? true : false}
            />
          </div>
          : null}
      </div>

    </Section>
  );
};

export default EventsFilter;