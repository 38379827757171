import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageClass, setUpHeader } from "../../../store/features/slice-ui";
import PageHead from "../../../components/PageHead";
import PageContent from "../../../components/PageContent";
import EventsFilter from '../../../components/Filters/EventsFilter';

const EventsFilterPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageClass("page-filter-events"));
    dispatch(setUpHeader({
      show: true,
      back: true, 
      profile: true,
      search: true
    }));
  }, [dispatch]);

  return (
    <>
      <PageHead 
        className="page-filter-events--page-head"
        title="Filter"
      />
      <PageContent className="page-filter-events--page-content">
        <EventsFilter 
          className="page-filter-events--content-wrapper pt-0" 
          pushAfterSubmit={`/education/events`}
        />
      </PageContent>
    </> 
    
  );
};

export default EventsFilterPage;
