import React from "react";
import { useSelector } from "react-redux";
import Feeds from "./Feeds";
import Welcome from "./Welcome";


const Home = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    return (
        <>
          {isAuthenticated ? <Feeds /> : <Welcome />}
        </>
    );
};

export default Home;
