import React from 'react';
import { Word } from '../Word';

const Textarea = (props) => {
  return (
    <div className="form-group">
      {props.title ? (
        <label htmlFor={props.name} className="form-label">
          <Word value={props.title} />{props.isRequired ? '*' : ''}
        </label>
      ) : null}
      <textarea
        className={`form-textarea`}
        id = {props.name}
        name={props.name}
        value={props.value}
        rows={props.rows || 6}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        required={props.isRequired}
        disabled={props.isDisabled}
      />
    </div>
  );
};

export default React.memo(Textarea);
