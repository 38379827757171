import React, {useState} from 'react';

import { useDispatch } from "react-redux";
import { removeFavourite } from "../../store/features/slice-account";
import Link from '../Link';
import Image from '../Image';
import Button from "../Button";
// Remove Foxo Integration
// import G from "../../app/globals";





const DoctorLinksCard = (props) => {
    const dispatch = useDispatch();
    const [isDeleting, setIsDeleting] = useState(false);
    // Remove Foxo Integration
    // const accountData = useSelector((state) => state.account.data);


    const details_link = `/refer-patient/${props.slug}`;
    const contact_link = {pathname: `/refer-patient/${props.slug}`, state: { tab: 'contact' }};
    const refer_patient_link = `/refer-patient/${props.slug}`;

    //Remove Foxo Integration
    // const handleSecureChat = () => {
    //     window.open(`${G.FOXO_URL}/contacts/${props.foxoId}`)
    // }

    const handleRemoveFavourite = () => {
        setIsDeleting(true);
        dispatch(removeFavourite({doctor: props.slug})).then(e => {
            setIsDeleting(false);
        })
    }

    return (
        <div className={`card-doctor-links${props.className ? ' '+props.className : ''}`}>
            {props.image && props.showImage ? <Link to={isDeleting ? null : details_link}>
                    <Image 
                        className={`card-doctor-links--figure${isDeleting ? ' disabled' : ''}`}
                        src={props.image} 
                        alt={props.title ? props.title : ""}
                    />
                </Link>
            : null}
            {props.title && props.hideTitle ? <div className="card-doctor-links--title">{props.title}</div> : null}
            <Link className={`btn btn-primary text-yellow full-width ${isDeleting ? 'disabled' : ''}`}  onClick={(e) => isDeleting ? e.preventDefault() : null} to={refer_patient_link}>Refer a Patient</Link>
            {/* Remove Foxo Integration */}
            {/* {
                accountData && accountData.foxoStatus === 'is_synced' && props.foxoId ? 
                <Button
                    type={"button"}
                    className="btn-primary text-yellow full-width"
                    text="Secure Chat"
                    isDisabled={isDeleting ? true : false}
                    Clicked={isDeleting ? false : handleSecureChat}
                />
                : null
            } */}
            <Link className={`btn btn-primary text-yellow full-width ${isDeleting ? 'disabled' : ''}`}  onClick={(e) => isDeleting ? e.preventDefault() : null} to={contact_link}>Contact</Link>
            <Button
                type={"button"}
                className="btn-primary grey text-red full-width"
                text={isDeleting ? "Removing..." : "Remove favourite"}
                isDisabled={isDeleting ? true : false}
                Clicked={isDeleting ? false :handleRemoveFavourite}
            />
        </div>
    );
};

export default DoctorLinksCard;