import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';
import G from '../../app/globals';
import { add_to_datalayer } from "../../helpers/tracking";

const initialState = {
  list: null,
  live: null,
  registered: null,
  upcoming: null,
  recommended: null,
  attended: null,
  past: null,
  taxonomies: {
    types: [
      {
        label: 'Online',
        value: 'Online'
      },
      {
        label: 'Physical',
        value: 'Physical'
      }
    ]
  },
  filters:{
    topics: null,
    type: null,
    cpd_type: null,
    date: null,
    speakers: null,
    state: null
  },
  single: null
};

export const fetchEvents = createAsyncThunk("events/getAll", async (parameters,thunkAPI) => {
  let userPreferences = parameters && parameters.preferences ? parameters.preferences.map(function (obj) {return obj.value}) : null;
  let tax_query = [];
  let meta_query = [];
  if(thunkAPI.getState().events.filters.topics && thunkAPI.getState().events.filters.topics.length > 0){
    tax_query.push('topic:'+thunkAPI.getState().events.filters.topics.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().events.filters.type){
    meta_query.push('event_type:'+thunkAPI.getState().events.filters.type.value)
  }
  if(thunkAPI.getState().events.filters.cpd_type){
    meta_query.push('global_cpd_type:'+thunkAPI.getState().events.filters.cpd_type.value)
  }
  if(thunkAPI.getState().events.filters.date){
    meta_query.push('event_start_date:'+thunkAPI.getState().events.filters.date)
  }
  if(thunkAPI.getState().events.filters.speakers && thunkAPI.getState().events.filters.speakers.length > 0){
    meta_query.push('event_speakers:'+thunkAPI.getState().events.filters.speakers.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().events.filters.state){
    meta_query.push('event_state:'+thunkAPI.getState().events.filters.state.value)
  }
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/events`, {
      type : 'public',
      params: {
        lang: thunkAPI.getState().settings.language,
        tax_query: tax_query,
        meta_query: meta_query,
        topics_preferences: userPreferences ? userPreferences : null
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchEventsFilter = createAsyncThunk("events/Filtered", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  let meta_query = [];
  if(thunkAPI.getState().events.filters.topics && thunkAPI.getState().events.filters.topics.length > 0){
    tax_query.push('topic:'+thunkAPI.getState().events.filters.topics.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().events.filters.type){
    meta_query.push('event_type:'+thunkAPI.getState().events.filters.type.value)
  }
  if(thunkAPI.getState().events.filters.cpd_type){
    meta_query.push('global_cpd_type:'+thunkAPI.getState().events.filters.cpd_type.value)
  }
  if(thunkAPI.getState().events.filters.date){
    meta_query.push('event_start_date:'+thunkAPI.getState().events.filters.date)
  }
  if(thunkAPI.getState().events.filters.speakers && thunkAPI.getState().events.filters.speakers.length > 0){
    meta_query.push('event_speakers:'+thunkAPI.getState().events.filters.speakers.map(function (obj) {return obj.value}))
  }
  try {
    const response = await axios.get(`api/v1/events-normalized`, { 
      params: { 
        return: 'ids',
        tax_query: tax_query,
        meta_query: meta_query
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchEventsUser = createAsyncThunk("events/getAllByUser", async (parameters,thunkAPI) => {
  if(thunkAPI.getState().auth.isAuthenticated){
    thunkAPI.dispatch(setLoadingStart());
    try {
      const response = await axios.get(`api/v1/events/user`);
      thunkAPI.dispatch(setLoadingEnd());
      return thunkAPI.dispatch(handleApiResponse(response));
    }
    catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
    }
  }
}
);

export const fetchEventsUserFilter = createAsyncThunk("events/getAllByUserFiltered", async (parameters,thunkAPI) => {
  if(thunkAPI.getState().auth.isAuthenticated){
    thunkAPI.dispatch(setLoadingStart());
    let tax_query = [];
    let meta_query = [];
    if(thunkAPI.getState().events.filters.topics && thunkAPI.getState().events.filters.topics.length > 0){
      tax_query.push('topic:'+thunkAPI.getState().events.filters.topics.map(function (obj) {return obj.value}))
    }
    if(thunkAPI.getState().events.filters.type){
      meta_query.push('event_type:'+thunkAPI.getState().events.filters.type.value)
    }
    if(thunkAPI.getState().events.filters.cpd_type){
      meta_query.push('global_cpd_type:'+thunkAPI.getState().events.filters.cpd_type.value)
    }
    if(thunkAPI.getState().events.filters.date){
      meta_query.push('event_start_date:'+thunkAPI.getState().events.filters.date)
    }
    try {
      const response = await axios.get(`api/v1/events/user`, { 
        params: {
          tax_query: tax_query,
          meta_query: meta_query
        } 
      });
      thunkAPI.dispatch(setLoadingEnd());
      return thunkAPI.dispatch(handleApiResponse(response));
    }
    catch (error) {
        let errorData = (error.response || {}).data;
        thunkAPI.dispatch(setLoadingEnd());
        thunkAPI.dispatch(handleApiErrors(errorData));
        return thunkAPI.rejectWithValue(errorData);
    }
  }
}
);

export const fetchEventSingle = createAsyncThunk("events/getSingleEvent", async (slug, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/event/${slug}`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const registerEvent = createAsyncThunk("events/register", async (data,thunkAPI) => {
    thunkAPI.dispatch(setLoadingStart());
    try {
      const response = await axios.post(`api/v1/event/register`, data);
      if (response.data.success) {
        add_to_datalayer({
          event: 'Event_Registration',
          event_name: data?._event_title || ''
        });
      }
      thunkAPI.dispatch(setLoadingEnd());
      return thunkAPI.dispatch(handleApiResponse(response));
    }
    catch (error) {
        let errorData = (error.response || {}).data;
        thunkAPI.dispatch(setLoadingEnd());
        thunkAPI.dispatch(handleApiErrors(errorData));
        return thunkAPI.rejectWithValue(errorData);
    }
}
);

export const unregisterEvent = createAsyncThunk("events/unregister", async (data,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.post(`api/v1/event/unregister`, data);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

const getLiveEvents = (events) => {
  if(events && events.length > 0){
    return events.filter(event => G.NEW_DATE(event.start.date) < new Date() && G.NEW_DATE(event.end.date) > new Date());
  }
}

const getUpcomingEvents = (events) => {
  if(events && events.length > 0){
    return events.filter(event => G.NEW_DATE(event.start.date) > new Date());
  }
}

const getPastEvents = (events) => {
  if(events && events.length > 0){
    return events.filter(event => G.NEW_DATE(event.end.date) < new Date());
  }
}

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    populateEvents(state) {
      state.live = getLiveEvents(state.list);
      state.upcoming = getUpcomingEvents(state.list);
      state.past = getPastEvents(state.list);
    },
    selectFilterItem(state, action) {
      const { data, filter } = action.payload
      state.filters[filter] = data;
    },
    clearFilterItems(state) {
      state.filters = {
        topics: null,
        type: null,
        cpd_type: null,
        date: null,
        speakers: null
      };
    },
    resetEventSingleReducer(state){
      state.single = null;
    },
    resetEventsReducer(){
      return initialState
    }
  },
  extraReducers: {
    [fetchEvents.pending]: (state, action) => {
      
    },
    [fetchEvents.fulfilled]: (state, action) => {
      if(action.payload && action.payload.events){
        state.list = action.payload.events;
        state.live = getLiveEvents(state.list);
        state.upcoming = getUpcomingEvents(state.list);
        state.past = getPastEvents(state.list);
      }

    },
    [fetchEvents.rejected]: (state, action) => {
      console.log(action);
    },

    [fetchEventsFilter.pending]: (state, action) => {
      // console.log(action);
    },
    [fetchEventsFilter.fulfilled]: (state, action) => {
      if(action.payload){
        state.list = action.payload.events;
        state.live = getLiveEvents(state.list, state.basic);
        state.upcoming = getUpcomingEvents(state.list, state.basic);
        state.past = getPastEvents(state.list, state.basic);
      }

    },
    [fetchEventsFilter.rejected]: (state, action) => {
      console.log(action);
    },

    [fetchEventsUser.pending]: (state, action) => {
      
    },
    [fetchEventsUser.fulfilled]: (state, action) => {
      if(action.payload && action.payload.events){
        state.registered = action.payload.events.registered.ids;
        state.attended = action.payload.events.attended.ids;
        state.recommended = action.payload.events.recommended.ids
      }
    },
    [fetchEventsUser.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchEventsUserFilter.pending]: (state, action) => {
      
    },
    [fetchEventsUserFilter.fulfilled]: (state, action) => {
      if(action.payload && action.payload.events){
        state.registered = action.payload.events.registered.ids;
        state.attended = action.payload.events.attended.ids;
        state.recommended = action.payload.events.recommended.ids
      }
    },
    [fetchEventsUserFilter.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchEventSingle.pending]: (state, action) => {
      
    },
    [fetchEventSingle.fulfilled]: (state, action) => {
      state.single = action.payload['event']
    },
    [fetchEventSingle.rejected]: (state, action) => {
      console.log(action);
    },
    [registerEvent.pending]: (state, action) => {
      
    },
    [registerEvent.fulfilled]: (state, action) => {
      
    },
    [registerEvent.rejected]: (state, action) => {
      console.log(action);
    }
  },
});

export const { populateEvents, selectFilterItem, clearFilterItems, resetEventSingleReducer, resetEventsReducer} = eventsSlice.actions
export default eventsSlice.reducer;

