import React from 'react';
import { Word } from '../Word';
import Cleave from "cleave.js/react";

const WB_Date = (props) => {
  
  return (
    <div className="form-group">
      {props.title ? (
        <label htmlFor={props.name} className="form-label">
          <Word value={props.title} />{props.isRequired ? '*' : ''}
        </label>
      ) : null}
      <Cleave
          options={{ date: true, datePattern: ["d", "m", 'Y'] }}
          className={`form-input`}
          id = {props.name}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.handleChange}
          required={props.isRequired}
          disabled={props.isDisabled}
      />
    </div>
  );
};

export default React.memo(WB_Date);
