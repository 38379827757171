import React from 'react';
import Link from '../Link'
import { Word } from '../Word';
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg';


const CTASubPage = (props) => {
    return (
        <Link className="card card-cta-subpage" to={props.Clicked || !props.link  ? null : props.link} onClick={props.Clicked ? props.Clicked : null}>
            {props.icon ? 
            <div className="card-cta-subpage--icon">{props.icon}</div>
            : null}
            <div className="card-cta-subpage--content">
                <div className="card-cta-subpage--subtitle">{props.subtitle}</div>
                <div className="card-cta-subpage--title">{props.title}</div>
            </div>
            <div className="card-cta-subpage--more">
                <ArrowRight />
                <span><Word value="View more" /></span>
            </div>
        </Link>
    );
};

export default React.memo(CTASubPage);