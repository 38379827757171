import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { registerEvent } from "../store/features/slice-events";
import { resetErrors } from "../store/features/slice-api";
import { resetAccountReducer } from "../store/features/slice-account";
import { resetCpdReducer } from "../store/features/slice-cpd";
import { clearReducers, loginState } from "../store/features/slice-auth";
import { logActivityInSheet } from "../store/features/slice-log";

import Input from "../components/Form/Input";
import Checkbox from "../components/Form/Checkbox";
import Select from "../components/Form/Select";
import Button from "../components/Button";
import Turnstile from '../components/Turnstile';
import G from "../app/globals";


const EventRegisterForm = ({ event, accountData }) => {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const errorsRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const errors = useSelector((state) => state.api.errors.event_register);
  const initialFormState = {
    _salutation: "",
    _first_name: "",
    _last_name: "",
    _phone: "",
    _postcode: "",
    _ahpra_number: "",
    _racgp_number: "",
    _practice_name: "",
    _dietary_requirements: "",
    _email: "",
    _event_title: "",
    _event_id: "",
    _marketing: false,
    _consent: false,
    _is_authenticated: isAuthenticated
  };
  const [form, setForm] = useState(initialFormState);

  useEffect(() => {
    if(event){
      setForm((f) => ({...f, _event_title: event.name, _event_id: event.id}));
    }
  }, [event]);

  useEffect(() => {
    if(errors && Object.values(errors).length > 0){
      errorsRef.current?.scrollIntoView({behavior: "smooth"});
    }
  }, [errors, errorsRef])

  useEffect(() => {
    if(isAuthenticated && accountData){
      setForm((f) => ({...f, 
        _salutation: accountData.salutation,
        _first_name: accountData.firstName, 
        _last_name: accountData.lastName,
        _phone: accountData.mobile,
        _postcode: accountData.postcode,
        _ahpra_number: accountData.ahpraNumber,
        _email: accountData.email,
        _racgp_number: accountData.racgpNumber,
        _practice_name: accountData.clinics?.length > 0 ? accountData.clinics[0].name : ""
      }));
    }
  }, [isAuthenticated, accountData]);


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
    if (e.target.name === '_ahpra_number') {
      e.target.setCustomValidity(e.target.validity.patternMismatch ? 'AHPRA number must start with 3 letters followed by 10 numbers.' : '');
    }
  };

  const handleSelectChange = (source, payload, isMulti) => {
    setForm({
        ...form,
        [source]: isMulti ? payload.map(item => item.value) : payload.value
    });
  };

  const setTurnstileToken = (token) => {
    setForm({
      ...form,
      cf_turnstile_response: token,
    });
  };


  const handleSubmit = (e) => {
    if(e){
      e.preventDefault();
    }
    if(!isAuthenticated){
      form._pass = G.GENERATE_PASSWORD(10);
    }
    dispatch(registerEvent(form)).then((e) => {
      if(e.payload){
        let eventData = {
          id: event.id,
          slug: event.slug,
          title: parse(event.name),
          date: event.formattedDate,
          time: event.formattedTime,
          start: event.start,
          end: event.end,
          venue: event.venue,
          form: form
        }
        dispatch(resetErrors());
        if(isAuthenticated){
          dispatch(resetAccountReducer());
          dispatch(resetCpdReducer());
        }
        else{
          if(e.payload.user?.token){
            //auto login
            eventData.auto_login = true;
            dispatch(clearReducers());
            localStorage.setItem("wb_idp_token", e.payload.user.token);
            localStorage.setItem("user_preferences", JSON.stringify(e.payload.user.preferences ? e.payload.user.preferences : null));
            dispatch(loginState());
          }
        }
        dispatch(logActivityInSheet({activity: eventData.title, sheet: 'Event Registrations'}));
        history.push({pathname: `/education/event/${eventData.slug}/register-success`, state: eventData})
      }
    });
  };

  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);

  return (
    <>
      <div className="event-register-form">
        <div ref={errorsRef}></div>
        {errors && Object.values(errors).length > 0 ?
          <div className="form-errors">
            { Object.values(errors).map((error, index) => (
              <div key={index} className="form-errors--item">{error}</div>
            ))}
          </div>
          : null}
        <form onSubmit={handleSubmit}>
                <Select
                  type={"text"}
                  name={"_salutation"}
                  title={"Title"}
                  options={G.SALUTATIONS_OPTIONS}
                  value={form._salutation ? ({label: form._salutation, value: form._salutation}) : null}
                  onChange={(payload) => handleSelectChange('_salutation', payload)}
                  isRequired
                  isDisabled={(isAuthenticated && accountData?.salutation) ? true : false}
                />
                <Input
                  type={"text"}
                  name={"_first_name"}
                  title={"First name"}
                  value={form._first_name}
                  handleChange={handleChange}
                  isRequired
                  isDisabled={(isAuthenticated && accountData?.firstName) ? true : false}
                />
                <Input
                  type={"text"}
                  name={"_last_name"}
                  title={"Last name"}
                  value={form._last_name}
                  handleChange={handleChange}
                  isRequired
                  isDisabled={(isAuthenticated && accountData?.lastName) ? true : false}
                />
                <Input
                  type={"email"}
                  name={"_email"}
                  title={"Email"}
                  value={form._email}
                  handleChange={handleChange}
                  isRequired
                  isDisabled={(isAuthenticated && accountData?.email) ? true : false}
                />
                <Input
                  type={"text"}
                  name={"_phone"}
                  title={"Phone Number"}
                  value={form._phone}
                  handleChange={handleChange}
                  isRequired
                  isDisabled={(isAuthenticated && accountData?.mobile) ? true : false}
                />
                <Input
                  type={"number"}
                  name={"_postcode"}
                  title={"Postcode"}
                  value={form._postcode}
                  handleChange={handleChange}
                  isRequired
                  isDisabled={(isAuthenticated && accountData?.postcode) ? true : false}
                />
                <Input
                  type={"text"}
                  name={"_ahpra_number"}
                  title={"AHPRA Number"}
                  value={form._ahpra_number}
                  handleChange={handleChange}
                  pattern="^[a-zA-Z]{3}\d{10}"
                  isDisabled={(isAuthenticated && accountData?.ahpraNumber) ? true : false}
                />
                <Input
                    type={"text"}
                    name={"_racgp_number"}
                    title={"RACGP Number"}
                    value={form._racgp_number}
                    handleChange={handleChange}
                    isDisabled={isAuthenticated && accountData?.racgpNumber ? true : false}
                />
                <Input
                    type={"text"}
                    name={"_practice_name"}
                    title={"Practice name"}
                    value={form._practice_name}
                    handleChange={handleChange}
                    isRequired
                />
                <Input
                  type={"text"}
                  name={"_dietary_requirements"}
                  title={"Dietary requirements"}
                  value={form._dietary_requirements}
                  handleChange={handleChange}
                />
                <Checkbox 
                  title={"I acknowledge I have read and understood the Group Privacy Policy and agree to the collection, use and handling of my Personal Data in accordance with the policy."}
                  name={"_consent"}
                  handleChange={handleChange}
                  isRequired
                />
                <Checkbox 
                  title={"I agree to receive emails about the latest in cancer care, treatments and other updates from Icon Cancer Centre."}
                  name={"_marketing"}
                  handleChange={handleChange}
                />
                {!isAuthenticated && <Turnstile setToken={setTurnstileToken} />}
                <Button
                  type={"submit"}
                  className="btn btn-primary purple text-yellow full-width"
                  text="Register"
                />
              </form>
      </div>
    </>
  );
};

export default EventRegisterForm;