import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { addBulkFavourites } from "../../store/features/slice-account";
import parse from 'html-react-parser';
import PageHead from "../../components/PageHead";
import FavDoctorCard from "../../components/Cards/DoctorCircular";
import Button from "../../components/Button";
import G from "../../app/globals";
import { ReactComponent as CheckedSolidIcon } from '../../assets/images/icons/check_solid.svg';


const ReferPatientConfirmation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [successMessage, setSuccessMessage] = useState("");
  const accountData = useSelector((state) => state.account.data);
  const doctorsListBasic = useSelector((state) => state.doctors.basic);
  const options = useSelector((state) => state.references.options);
  useEffect(() => {
    dispatch(setPageClass("page-refer-patient"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      back: false,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if(accountData){
        if(options && options.static_texts && options.static_texts.submit_ref_message_nofoxo){
          setSuccessMessage(parse(options.static_texts.submit_ref_message_nofoxo, G.HTML_PARSE_OPTIONS))
        }
        else{
          setSuccessMessage(G.DEFAULT_SUBMIT_REF_MSG);
        }
    }

  }, [dispatch, accountData, options]);

  const handleAddFavourite = () => {
    dispatch(addBulkFavourites({doctors: [props.location.state.doctor]})).then(e => {
      history.replace('/account/favourites')
    })
  }

  const handleDownloadPdf = () => {
    G.BASE64_TO_DOWNLOAD_FILE(props.location.state.file, {parent: 'ida', type: 'referral', id: 'confirmation'});
  }
  
  return (
    <>
      <PageHead 
        className="page-refer-patient--page-head"
        title="Refer a Patient"
        bg="blue"
      />
      {props.location.state && props.location.state.source && props.location.state.source === 'formsubmission' ? 
        <div className="success--message">
          <div className="success--message-icon"><CheckedSolidIcon /></div>
          {successMessage}

          {props.location.state.file &&
          <Button
                  type={"button"}
                  className="btn-primary text-yellow"
                  text="Download referral confirmation"
                  Clicked={handleDownloadPdf}
                />
          }

          {props.location.state.doctor && accountData && accountData.favourites && !accountData.favourites.includes(props.location.state.doctor) && doctorsListBasic ? 
        
            doctorsListBasic[props.location.state.doctor] ? 
              <>
                <FavDoctorCard
                    slug={doctorsListBasic[props.location.state.doctor].slug}
                    image={doctorsListBasic[props.location.state.doctor].profileImage}
                    title={parse(doctorsListBasic[props.location.state.doctor].salutation+" "+doctorsListBasic[props.location.state.doctor].name)}
                    link={`/refer-patient/${doctorsListBasic[props.location.state.doctor].slug}`}
                />
                <Button
                  type={"button"}
                  className="btn-primary text-yellow"
                  text="Add as favourite"
                  Clicked={handleAddFavourite}
                />
              </>
              : null
          : null}
        </div>
      : <Redirect replace to='/refer-patient' />}
    </>
  );
};

export default ReferPatientConfirmation;
