import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../store/features/slice-ui";
import { fetchSearchResults, clearSearch, fetchSearchRecent } from "../store/features/slice-search";
import PageHead from "../components/PageHead";
import PageContent from "../components/PageContent";
import Section from "../components/Section";
import Link from "../components/Link";
import { Word } from '../components/Word';
import Input from '../components/Form/Input';
import Button from '../components/Button';
import { ReactComponent as SearchIcon } from '../assets/images/icons/search.svg';
import { ReactComponent as CloseIcon } from '../assets/images/icons/close.svg';
import G from '../app/globals';

const Search = () => {

  let { keyword } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [search_loading, setSearchLoading] = useState(true);

  // Get search results from keyword in pathname
  useEffect(() => {
    if(keyword && keyword.trim() !== ''){
      setSearchInput(keyword);
      dispatch(fetchSearchResults({query: keyword}));
    }
    setSearchLoading(false);
  }, [dispatch, keyword]);

  // Search state
  const search_q = useSelector((state) => state.search.q);
  const search_results = useSelector((state) => state.search.results);
  const search_recent = useSelector((state) => state.search.recent);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [search_input, setSearchInput] = useState(search_q ? search_q : '');

  // Page setup
  useEffect(() => {
    dispatch(setPageClass("page-search"));
    dispatch(setUpHeader({
      show: true,
      profile: true,
      search: true,
      back: true
    }));
  }, [dispatch]);

  // Get recent results
  useEffect(() => {
    if(!search_recent && isAuthenticated){
      dispatch(fetchSearchRecent());
    }
  }, [dispatch, search_recent, isAuthenticated])
  
  // Search input update
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value)
  };

  // Search submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if(search_input.trim() !== ''){
      //dispatch(fetchSearchResults({query: search_input}))
      history.push('/search/' + search_input)
    }
  }

  // Clear search results
  const clearSearchResults = () => {
    setSearchInput('');
    dispatch(clearSearch());
  }

  // View recent search
  const clickPreviousSearch = (keyword) => {
    G.RESET_SCROLL();
    setSearchInput(keyword);
    dispatch(fetchSearchResults({query: keyword}))
  }

  // Tags
  const getTagColour = (tag) => {
    if (tag) {
      const tagLower = tag.toLowerCase();
      switch (tagLower) {
        case 'article' : 
          return 'yellow';
        case 'webinar' :
          return 'green';
        case 'video' :
            return 'green';
        default: 
          return 'grey';
      }
    }
    return 'grey'; 
  }

  return (
    <>
      <PageHead className="page-search--page-head" />
      <PageContent className="page-search--content">
        <Section className="page-search--form flush">
          <form onSubmit={handleSubmit}>
            <Input 
              name="general_search"
              id="general_search"
              placeholder="Type to search..."
              type="text"
              handleChange={handleSearchInputChange}
              value={search_input}
            />
            <Button
              className="btn-primary"
              text="Search"
              textIcon={<SearchIcon />}
              type="submit"
            />
          </form>
        </Section>


        {!search_loading &&
          <>
            {search_results?.length > 0 ?
              <Section className="page-search--results flush">
                <h3>
                    <Word value="Results" /> ({search_results.length})
                    <Button 
                      className="btn-plain underline"
                      text="Clear"
                      textIcon={<CloseIcon />}
                      Clicked={clearSearchResults}
                    />
                </h3>
                {search_results.map((item, index) => {
                    let link = '/';
                    switch(item.type){
                      case 'post' : link = `/article/${item.slug}`;
                        break;
                      case 'learning-module' : link = `/education/learning-module/${item.slug}`;
                        break;
                      case 'event' : link = `/education/event/${item.slug}`;
                        break;
                      case 'page' : link = `/${item.slug}`;
                        break;
                      default: link = '/';
                    }
                    return (
                    <Link to={link} key={index} className="page-search--item">
                      <div className={'item-tags'}>
                        <div className={`text text--category card-standard--stamp ${getTagColour(item.format)}`}>
                        {item.format ?
                          <Word value={item.format} />
                        : <Word value={'Page'} />}
                        </div> 
                      </div>
                      <div className={'item-title'}>{parse(item.title)}</div>
                      { item.excerpt ? <div className={'item-excerpt'}>{item.excerpt}</div> : null}
                    </Link>
                    )
                })}
              </Section> 
            : 
              null
            }
          </>
        }
        {search_recent && search_recent.length > 0 ? 
        <Section className="page-search--recent flush">
            <h3><Word value="Your recent searches" /></h3>
            {search_recent.map((item, index) => (
              <div className="page-search--item" key={index} onClick={() => clickPreviousSearch(item.keyword)}>
                {item.keyword}
                <span>{G.FORMAT_DATE(item.date)}</span>
              </div>
            ))}
        </Section>
        : null}
        
      </PageContent>
    </>
  );
};

export default Search;
