import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../../store/features/slice-ui";
import { fetchLearningModuleSingle } from "../../../store/features/slice-learning-modules";
import PageHead from "../../../components/PageHead";
import PageContent from "../../../components/PageContent";
import Section from "../../../components/Section";
import SubTopicCard from "../../../components/Cards/SubTopic";
import RessourceCard from "../../../components/Cards/CtaLmResource";
import Button from "../../../components/Button";
import { Word } from "../../../components/Word";
import ProgressBar from '../../../components/ProgressBar';
import Share from '../../../components/Share';
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/clock.svg';
import { ReactComponent as StarIcon } from '../../../assets/images/icons/star.svg';
import { ReactComponent as PlayVideoIcon } from '../../../assets/images/icons/play-button-green.svg';
import { ReactComponent as ArticleIcon } from '../../../assets/images/icons/article.svg';
import { ReactComponent as WebinarIcon } from '../../../assets/images/icons/webinar.svg';
import RegisterLoginPrompt from "../../../components/RegisterLoginPrompt";

import G from "../../../app/globals";




const LearningModuleSingle = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let { slug } = useParams();
  const LearningModule = useSelector((state) => state.learningModules.single && state.learningModules.single.slug === slug ? state.learningModules.single : null);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountData = useSelector((state) => state.account.data);
  const isLocked = !isAuthenticated && LearningModule && LearningModule.user_type ? true : false;

  const firstClickRef = useRef();
  useEffect(() => {
    dispatch(setPageClass("page-lm-single"));
    dispatch(setUpHeader({
      show: true,
      back: true,
      profile: true,
      search: true,
      backTo: LearningModule && LearningModule.parent ? `/education/learning-module/${LearningModule.parent.slug}` : props.location.prevPath && props.location.prevPath === '/account/cpd-log' ? props.location.prevPath : '/education/learning-modules'
    }));
  }, [dispatch, LearningModule, props.location]);

  useEffect(() => {
    if ((!LearningModule || (LearningModule && LearningModule.slug !== slug))) {
      dispatch(fetchLearningModuleSingle(slug));
    }
  }, [dispatch, LearningModule, slug]);


  const handleStartBtn = () => {
    if (firstClickRef.current) {
      firstClickRef.current.click();
    }
  }

  const setRefNextResource = (index) => {
    const progress = getLmProgress(LearningModule.id);
    if (progress === 0) {
      if (index === 0) {
        return firstClickRef;
      }
      else { return null; }
    }
    else {
      if (index > 0 && index < LearningModule.resources.length) {
        if (LearningModule.resources[index - 1].post && accountData.lms_articles.includes(LearningModule.resources[index - 1].post.id) && LearningModule.resources[index].post && !accountData.lms_articles.includes(LearningModule.resources[index].post.id)) {
          return firstClickRef;
        }
        else return null;
      }
    }
  }

  const setRefNextSubTopic = (index) => {
    const progress = getLmProgress(LearningModule.id);
    if (progress === 0) {
      if (index === 0) return firstClickRef;
      else return null;
    }
    else {
      if (index > 0 && index < LearningModule.children.length) {
        if (getLmProgress(LearningModule.children[index - 1].id) === 100 && getLmProgress(LearningModule.children[index].id) < 100) {
          return firstClickRef;
        }
        else return null;
      }
    }
  }

  const getLmProgress = (id) => {
    if (accountData && accountData.lms && accountData.lms[id]) {
      return +accountData.lms[id].progress;
    }
    else {
      return 0;
    }
  }

  return (
    LearningModule && (
      <>
        {isLocked ?
          <>
            <PageHead
              className="page-lm-single--page-head"
              title={LearningModule ? parse(LearningModule.title) : null}
              breadcrumb={
                <>
                  <div className="text text--category green">
                    {LearningModule && LearningModule.category_icon ? parse(LearningModule.category_icon) : null}
                    <Word value="Learning Modules" />
                  </div>
                  {LearningModule && LearningModule.parent ? parse(LearningModule.parent.title) : ''}
                </>}
            />
            <PageContent className="page-lm-single--page-content">
              <RegisterLoginPrompt />
            </PageContent>
            
          </>
          :
          <>
            <PageHead
              className="page-lm-single--page-head"
              title={LearningModule ? parse(LearningModule.title) : null}
              breadcrumb={
                <>
                  <div className="text text--category green">
                    {LearningModule && LearningModule.category_icon ? parse(LearningModule.category_icon) : null}
                    <Word value="Learning Modules" />
                  </div>
                  {LearningModule && LearningModule.parent ? parse(LearningModule.parent.title) : ''}
                </>}
            />
            <PageContent className="page-lm-single--page-content">
              {LearningModule ?
                <>
                  <Section className="split-content">
                    <div className="page-lm-single--details">
                      {LearningModule.completion_time || LearningModule.cpd ?
                        <div className="page-lm-single--tags">
                          {LearningModule.completion_time && LearningModule.completion_time.time ?
                            <div className="text text--tag">
                              {<ClockIcon />}
                              {G.COMPLETION_TIME_TEXT(LearningModule.completion_time)}
                            </div>
                            : null}
                          {LearningModule.cpd && LearningModule.cpd.type ?
                            <div className="text text--tag">
                              {<StarIcon />}
                              {G.CPD_TEXT(LearningModule.cpd)}
                            </div>
                            : null}
                        </div>
                        : null}
                      {isAuthenticated ? <ProgressBar value={getLmProgress(LearningModule.id)} /> : null}
                      <div className="page-lm-single--description">
                        {LearningModule.description ? parse(LearningModule.description, G.HTML_PARSE_OPTIONS) : null}
                      </div>
                      <Share className="page-lm-single--share green" title={parse(LearningModule.title)} />
                    </div>
                    <div className="page-lm-single--right">
                      {LearningModule.children && LearningModule.children.length > 0 ?
                        LearningModule.children.map((item, index) => (
                          <SubTopicCard
                            key={index}
                            number={index + 1}
                            title={parse(item.title)}
                            link={({
                              pathname: `/education/learning-module/${item.slug}`
                            })}
                            ref={setRefNextSubTopic(index)}
                            progress={getLmProgress(item.id)}
                            isDisabled={index > 0 && getLmProgress(LearningModule.children[index - 1].id) === 0 && isAuthenticated}
                          />
                        ))
                        : LearningModule.resources && LearningModule.resources.length > 0 ?
                          LearningModule.resources.map((item, index) => {
                            if (item.post) {
                              let svgIcon = <PlayVideoIcon />
                              switch (item.post.format) {
                                case 'Article': svgIcon = <ArticleIcon />
                                  break;
                                case 'Webinar': svgIcon = <WebinarIcon />
                                  break;
                                case 'Video': svgIcon = <PlayVideoIcon />
                                  break;
                                default: svgIcon = <ArticleIcon />
                              }
                              return item.post ?
                                <RessourceCard
                                  key={index}
                                  title={parse(item.post.title)}
                                  svg={svgIcon}
                                  svgBig
                                  isDisabled={index > 0 && accountData && !accountData.lms_articles.includes(LearningModule.resources[index - 1].post.id)}
                                  isCompleted={accountData && accountData.lms_articles.includes(item.post.id)}
                                  link={({
                                    pathname: `/article/${item.post.slug}`,
                                    state: {
                                      sourcePath: 'lm',
                                      qa: item.qa ? item.qa : null,
                                      lm: LearningModule.slug,
                                      lm_id: LearningModule.id
                                    }
                                  })}
                                  ref={setRefNextResource(index)}
                                />
                                : null
                            }
                            else return null;
                          })
                          : null}
                    </div>
                  </Section>
                  {isAuthenticated && (LearningModule.parent || getLmProgress(LearningModule.id) !== 100) ?
                    <Section className="page-lm-single--actions">
                      <div className="page-lm-single--actions-navs">
                        {LearningModule.parent ?
                          <Button
                            className="btn-outline blue"
                            text={"Previous"}
                            Clicked={() => history.push(`/education/learning-module/${LearningModule.prev}`)}
                            isDisabled={!LearningModule.prev}
                          />
                          : null}
                        {LearningModule.parent ?
                          <Button
                            className="btn-primary blue"
                            text={"Next"}
                            Clicked={() => history.push(`/education/learning-module/${LearningModule.next}`)}
                            isDisabled={(!LearningModule.next || getLmProgress(LearningModule.id) !== 100)}
                          />
                          : null}
                      </div>
                      {getLmProgress(LearningModule.id) !== 100 ?
                        <div className="page-lm-single--actions-start">
                          <Button
                            className="btn-primary green"
                            text={(getLmProgress(LearningModule.id) === 0 ? 'Start' : 'Continue') + (LearningModule.parent ? " sub topic" : " learning module")}
                            Clicked={LearningModule.parent ? handleStartBtn : handleStartBtn} //handleNextResource
                          />
                        </div>
                        : null}
                      {
                        LearningModule.parent && getLmProgress(LearningModule.parent.id) === 100 ?
                          <div className="page-lm-single--actions-start">
                            <Button
                              className="btn-primary green"
                              text={'Learning module completed'}
                              Clicked={() => history.push(`/education/learning-module/${LearningModule.parent.slug}`)}
                            />
                          </div>
                          : null
                      }
                    </Section>
                    : null}

                </>
                : null}
            </PageContent>
          </>
        }
      </>
    )

  );
};

export default LearningModuleSingle;
