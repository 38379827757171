import React from 'react';
import { useSelector } from "react-redux";
import Link from '../Link';
import { Word } from '../Word';
import Image from '../Image';
import parse from 'html-react-parser';
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg';
import { ReactComponent as StartIcon } from '../../assets/images/icons/star.svg';

const StandardCard = (props) => {
  const doctorsListBasic = useSelector((state) => state.doctors.basic);

    return (
        <Link className={`card card-standard${props.className ? ' '+props.className : ''}${!props.image && props.stamp ? ' pt' : ''}`} to={props.Clicked || !props.link  ? null : props.link} onClick={props.Clicked ? props.Clicked : null}>
            {props.image ? 
                <Image 
                    className={`card-standard--figure${props.image_small ? ' small' : ''}`} 
                    src={props.image} 
                    alt={props.title ? props.title : ""}
                />
            : null}
            <div className="card-standard--content">
                {props.categories && props.categories.length > 0 ? 
                    <div className={`card-standard--categories`}>
                        {props.categories.map((category, index) => {
                            return <div className={`text text--category ${category.colour ? category.colour : ''}`} key={index}>
                                        {category.icon ? parse(category.icon) : null}
                                        {category.title}
                                    </div>
                        })}
                    </div>
                : null}
                {props.title ? <h4 className="card-standard--title blue">{props.title}</h4> : null}
                {props.description ? <div className="card-standard--description">{props.description}</div> : null }
                {props.author ? 
                    <div className="card-standard--author text text--author">
                        {props.author.image ? 
                            <Image 
                                src={props.author.image}
                                alt={props.author.title}
                            /> 
                        : null}
                        {props.author.name}
                    </div>
                : null}
                {props.authors && props.authors.length > 0 && doctorsListBasic ? 
                    <div className="card-standard--authors">
                        {props.authors.map((author, index) => {
                            return doctorsListBasic[author] ? <div key={index} className="text text--author">
                                        {doctorsListBasic[author].profileImage ? 
                                            <Image 
                                                src={doctorsListBasic[author].profileImage}
                                                alt={doctorsListBasic[author].name}
                                            />
                                        : null}
                                        {parse(doctorsListBasic[author].salutation+" "+doctorsListBasic[author].name)}
                                    </div>
                                    : null
                        })}
                    </div>
                : null}
                {(props.tags && props.tags.length > 0) || props.completion || props.cpd ? 
                    <div className="card-standard--tags">
                        {props.tags && props.tags.length > 0 ? props.tags.map((tag, index) => {
                            return <div className="text text--tag" key={index}>
                                        {tag.icon ? parse(tag.icon) : null}
                                        {tag.title}
                                    </div>
                        }) : null}
                        {props.completion ? 
                            <div className="text text--tag">
                                {<ClockIcon />}
                                {props.completion}
                            </div> 
                        : null}
                        {props.cpd ? 
                            <div className="text text--tag">
                                {<StartIcon />}
                                {props.cpd}
                            </div> 
                        : null}
                        
                    </div>
                : null}
            </div>
            {props.stamp ? <div className={`text text--category card-standard--stamp ${props.stamp.colour}`}>
                {props.stamp.icon ? parse(props.stamp.icon) : null}
                <Word value={props.stamp.title} />
            </div> : null}
        </Link>
    );
};

export default StandardCard;