import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageClass, setUpHeader } from "../store/features/slice-ui";
import PageHead from "../components/PageHead";
import PageContent from "../components/PageContent";
import {Word} from "../components/Word";
import Input from "../components/Form/Input";
import Checkbox from "../components/Form/Checkbox";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { login } from '../store/features/slice-auth';

const Login = (props) => {

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setPageClass("page-login no-bottom-menu"));
    dispatch(setUpHeader({
      logo: true,
      sideNav: false
    }));
  }, [dispatch]);


  const initialFormState = {
    username: "",
    password: "",
    rememberPassword: ""
  };
  const [form, setState] = useState(initialFormState);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(form));
  };

  const handleChange = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  return (
    <>
    <PageHead 
      className="page-login--head"
      title="Log In"
    />
    <PageContent className="page-login--content">
      <form onSubmit={handleSubmit}>
          <Input
            type={"email"}
            title={"Email"}
            name={"username"}
            value={form.username}
            handleChange={handleChange}
            isRequired
          />
          <Input
            type={"password"}
            title={"Password"}
            name={"password"}
            value={form.password}
            handleChange={handleChange}
            isRequired
          />
          <div className="form-group row">
            <Checkbox 
              title={"Remember me"}
              name={"rememberPassword"}
              handleChange={handleChange}
            />
            <Link to="/forgot-password" className="btn-text"><Word value="Forgot password?" /></Link>
          </div>
          
          <Button
            type={"submit"}
            className="btn-primary btn-blue text-yellow full-width"
            text="Log in"
          />
        </form>
        <div className="form-text-link">
          <Word value="No account?" />&nbsp;
          <Link to={{
                pathname: "/register", 
                state: {from: props.location.state?.from}
              }}
            >
            <Word value="Register here" />
          </Link>
        </div>
      </PageContent>
    </>
  );
};

export default Login;
