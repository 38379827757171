import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';

const initialState = {
  q: null,
  results: null,
  recent: null
};

export const fetchSearchResults = createAsyncThunk("search/getAll", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/search`, { 
      params: { 
        query: parameters ? parameters.query : null
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchSearchRecent = createAsyncThunk("search/getRecent", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/search/recent`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearSearch(state) {
      state.q=null;
      state.results=null;
    },
    resetSearchReducer(state){
      state.recent = null;
    }
  },
  extraReducers: {
    [fetchSearchResults.pending]: (state, action) => {
      
    },
    [fetchSearchResults.fulfilled]: (state, action) => {
      if(action.payload){
        state.q= action.payload.args.s;
        state.results = action.payload.results;
        state.recent = action.payload.recent_searches
      }
    },
    [fetchSearchResults.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchSearchRecent.pending]: (state, action) => {
      
    },
    [fetchSearchRecent.fulfilled]: (state, action) => {
      if(action.payload){
        state.recent = action.payload.recent_searches
      }
    },
    [fetchSearchRecent.rejected]: (state, action) => {
      console.log(action);
    },
  },
});

export const { clearSearch, resetSearchReducer } = searchSlice.actions
export default searchSlice.reducer;

