import React, {useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import FavDoctorCard from "../components/Cards/DoctorCircular";
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { ReactComponent as ProfileIcon } from '../assets/images/icons/profile-icon.svg';
import { ReactComponent as SearchIcon } from '../assets/images/icons/search.svg';
import { ReactComponent as BackIcon } from '../assets/images/icons/chevron-left.svg';
import { ReactComponent as CloseIcon } from '../assets/images/icons/close.svg';
import { ReactComponent as StarIcon } from '../assets/images/icons/star.svg';
import Button from "../components/Button";
import Input from '../components/Form/Input';
import G from "../app/globals";

const Header = (props) => {

    const history = useHistory();
    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    const isDesktop = breakPoint && !G.TABLET_BREAKPOINTS.includes(breakPoint);
    const accountData = useSelector((state) => state.account.data);
    const doctorsListBasic = useSelector((state) => state.doctors.basic);

    const [searchQuery, setSearchQuery] = useState('');

    const renderFavDoctorsCards = () => {
        return accountData && accountData.favourites && doctorsListBasic ? accountData.favourites.slice(0, 5).map(id => (
          doctorsListBasic[id] ? 
            <FavDoctorCard
                key={id}
                slug={doctorsListBasic[id].slug}
                image={doctorsListBasic[id].profileImage}
                title={parse(doctorsListBasic[id].salutation+" "+doctorsListBasic[id].name)}
                foxoId={doctorsListBasic[id].foxoId}
                hideTitle
            /> : null
        )) : null;
      };
    
    const handleChange = event => {
        setSearchQuery(event.target.value);
    }

    const handleFormSubmit = event => {
        event.preventDefault();
        if(searchQuery.trim() !== ''){
            history.push('/search/' + searchQuery);
            setSearchQuery('');
        }
    };

    const handleLogoClick = () => {
        if (isAuthenticated) {
            history.push('/');
        } else{
            history.push('/education');
        }
    };

    return (
        <header className={`header ${isDesktop ? 'blue' : props.colour} ${location.pathname === '/register' ? ' is-register-page' : ''}`}>
            <div className="header--inner">
                {props.close ? <div className="header--icon header--icon-close btn-icon" onClick={props.backTo ? history.push(props.backTo) : history.goBack}><CloseIcon /></div> : null}
                {(props.back && !isDesktop) ? <div className="header--icon header--icon-back btn-icon" onClick={props.backTo ? () => history.push(props.backTo) : () => history.goBack()}><BackIcon /></div> : null}
                {props.logo || isDesktop ? <div className="header--logo" onClick={handleLogoClick}><Logo /></div> : null}
                {props.favourites && isAuthenticated ? 
                    <div className="header--favourites">
                        {isDesktop ? 
                            <div className="header--favourites-list">
                                {renderFavDoctorsCards()}
                            </div> 
                            : 
                            accountData && accountData.favourites.length <= 2 ? 
                            <div className="header--favourites-list">{renderFavDoctorsCards()}</div> 
                            : <Button 
                                className={`btn-plain btn-icon header--icon header--icon-favourites${location.pathname === '/account/favourites' ? ' text-yellow' : ''}`}
                                Clicked={() => history.push('/account/favourites')}
                                icon={<StarIcon />}
                            /> 
                        }
                    </div>
                : null}
                {props.search ? 
                    isDesktop ? 
                        // <Button 
                        //     className={`btn btn-primary header--icon header--icon-search${props.favourites && isAuthenticated ? '' : ' first'}`}
                        //     Clicked={() => history.push('/search')}
                        //     text={"Search IDA"}
                        //     textIcon={<SearchIcon />}
                        // /> 

                        <form className="header-search" onSubmit={handleFormSubmit}>
                            <Input type="search" handleChange={handleChange} value={searchQuery} placeholder={'Search'} />
                            <Button type="submit" className={`btn-plain btn-icon`} icon={<SearchIcon />} />
                        </form>
                   
                    :
                    <></>
                    // <Button 
                    //     className={`btn-plain btn-icon header--icon ${location.pathname === '/search' ? ' text-yellow' : ''}${props.favourites && isAuthenticated ? '' : ' first'}`}
                    //     Clicked={() => history.push('/search')}
                    //     textIcon={<SearchIcon />}
                    //     text={'Search'}
                    // /> 
                : null}
                {props.profile ? 
                    isDesktop ? 
                        <>
                            <Button 
                                className={`btn btn-primary header--icon header--icon-profile ${location.pathname === '/account' ? 'text-yellow' : ''}`}
                                Clicked={() => isAuthenticated ? history.push('/account') : history.push('/login')}
                                text={isAuthenticated ? 'My Account' : 'Log in' }
                            />
                            {isAuthenticated &&
                                <Button 
                                    className={`btn btn-primary purple header--icon header--icon-profile`}
                                    Clicked={() => history.push('/refer-patient')}
                                    text={'Refer a Patient'}
                                /> 
                            }
                            {!isAuthenticated &&
                                <Button 
                                    className={`btn btn-primary header--icon header--icon-profile ${location.pathname === '/account' ? 'text-yellow' : ''}`}
                                    Clicked={() =>  history.push('/register')}
                                    text={'Register'}
                                /> 
                            }
                        </>
                    :
                        <>
                            <Button 
                                className={`btn-plain btn-icon header--icon ${location.pathname === '/account' ? 'text-yellow' : ''}`}
                                Clicked={() => isAuthenticated ? history.push('/account') : history.push('/login')}
                                textIcon={<ProfileIcon />}
                                text={isAuthenticated ? 'My Account' : 'Log in'}

                            /> 
                            {!isAuthenticated &&
                                <Button 
                                    className={`btn-plain btn-icon header--icon ${location.pathname === '/account' ? 'text-yellow' : ''}`}
                                    Clicked={() => history.push('/register')}
                                    textIcon={<ProfileIcon />}
                                    text={'Register'}
                                /> 
                            }
                        </>
                : null }
            </div>
        </header>
    );
};

export default React.memo(Header);