import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addBulkFavourites, removeFavourite } from "../../store/features/slice-account";
import Loading from "../Loading";
import { ReactComponent as StarIcon } from '../../assets/images/icons/star.svg';
import { ReactComponent as StarSolidIcon } from '../../assets/images/icons/star_solid.svg';




const AddToFavoriteButton = ({className, doctorSlug}) => {

    const dispatch = useDispatch();
    const [isFavouriteOp, setIsFavouriteOp] = useState(false);
    const accountData = useSelector((state) => state.account.data);

    const handleAddFavourite = () => {
        setIsFavouriteOp(true);
        dispatch(addBulkFavourites({doctors: [doctorSlug], skip_loading: true})).then(e => {
            setIsFavouriteOp(false);
        })
    }

    const handleRemoveFavourite = () => {
        setIsFavouriteOp(true);
        dispatch(removeFavourite({doctor: doctorSlug})).then(e => {
            setIsFavouriteOp(false);
        })
    }

    if(accountData){
        return (
            <div className={`btn--favourite ${className}`}>
                {isFavouriteOp ? <Loading /> : 
                accountData.favourites.includes(doctorSlug) ? 
                    <>
                        <div className={`btn--favourite-tooltip solid`}>
                            Remove from favourites
                        </div>
                        <StarSolidIcon className="icon-solid" onClick={handleRemoveFavourite} />
                    </>
                    : <>
                        <div className={`btn--favourite-tooltip`}>
                            Add as favourite
                        </div>
                        <StarIcon className="icon-outline" onClick={handleAddFavourite} />
                    </>
                }
            </div>
        );
    }

    return null;
};

export default AddToFavoriteButton;