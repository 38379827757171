import React, {useState, useEffect} from 'react';
import { useDispatch } from "react-redux";
import Button from "../components/Button";
import { toast } from "../store/features/slice-alerts";
import { logQandaItem } from "../store/features/slice-qa";





const QandA = (props) => {
    const dispatch = useDispatch();
    const [selectedAnswers, SelectAnswers] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleAnswerSelect = (q,i, a) => {
        SelectAnswers({
            ...selectedAnswers,
            [q]: {
                index: i,
                answer: a
            }
          });
    }
    const handleCompleteQA = () => {
        if(isSubmitted) {
            dispatch(logQandaItem({id: props.id, answers: selectedAnswers}));
            props.Completed();
        }
        else{
            if(Object.keys(selectedAnswers).length === props.questions.length){
                setIsSubmitted(true);
            }
            else{
                dispatch(toast(
                    {
                      message: 'All questions have to be answered',
                      type: 'error',
                      timeout: 5000
                    }
                  ))
            }
        }
    }
    useEffect(() => {
        if(props.answers){
            setIsSubmitted(true);
            SelectAnswers(props.answers);
        }
    }, [props.answers]);

    return (
        <div className={`qanda${props.className ? ' '+props.className : ''}`}>
            {props.questions ? props.questions.map((item, index) => (
                <div key={index} className="qanda--item">
                    <h3 className="qanda--item-question">{item.question}</h3>
                    <div className="qanda--item-answers">
                    {item.answers ? item.answers.map((answer, i) => (
                        <div 
                            className={`qanda--item-answer
                            ${!isSubmitted && selectedAnswers[index] && selectedAnswers[index].index === i ? ' selected' : ''}
                            ${isSubmitted && answer.status.length > 0  ? ' correct' : isSubmitted && selectedAnswers[index] && +selectedAnswers[index].index === i ? ' wrong' : ''}
                            ${isSubmitted  ? ' non-clickable' : ''}`} 
                            key={i} 
                            onClick={isSubmitted ? null : () => handleAnswerSelect(index, i, answer.answer)}>
                                {answer.answer}
                        </div>
                    )) : null}
                    </div>
                </div>
            ))
        : null}
        {props.answers ? null : 
            <Button 
                className="btn-primary green full-width-m"
                text={isSubmitted ? "Complete Resource" :  "Submit Q&A"}
                Clicked={handleCompleteQA}
            />
        }
        </div>
    );
};

export default QandA;