import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectFilterItem,  clearFilterItems, applyFilter } from "../../store/features/slice-cpd";
import Section from "../Section";
import Button from "../Button";
import Select from '../Form/Select';
import DatePicker from '../Form/DatePicker';
import { Word } from "../Word";


const CpdFilter = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const cpd_types = useSelector((state) => state.references.cpd_types);
    const types = [
      {
        label: 'All',
        value: 'all'
      },
      {
        label: 'Learning Modules',
        value: 'learning-module'
      },
      {
        label: 'Clinical Articles',
        value: 'article'
      },
      {
        label: 'Events',
        value: 'event'
      }
  ]
    const filters = useSelector((state) => state.cpd.filters);
    const [typesSelect, setTypesSelect] = useState(filters.type);
    const [cpdTypesSelect, setCpdTypesSelect] = useState(filters.cpd_type);
    const [fromDate, setFromDate] = useState(filters.from_date);
    const [toDate, setToDate] = useState(filters.to_date);

    const handleTypesSelectChange = (payload) => {
        setTypesSelect(payload);
    };
    const handleCpdTypesSelectChange = (payload) => {
        setCpdTypesSelect(payload);
    };

    const handleFilterSubmit = () => {
        dispatch(selectFilterItem( {data: typesSelect , filter: 'type'}));
        dispatch(selectFilterItem( {data: cpdTypesSelect , filter: 'cpd_type'}));
        dispatch(selectFilterItem( {data: fromDate , filter: 'from_date'}));
        dispatch(selectFilterItem( {data: toDate , filter: 'to_date'}));
        dispatch(applyFilter());
        if(props.pushAfterSubmit){
          history.push(props.pushAfterSubmit)
        }
    };

    const handleClearFilter = () => {
        setTypesSelect(null);
        setCpdTypesSelect(null);
        setFromDate(null);
        setToDate(null);
        if(filters.type || filters.cpd_type || filters.from_date || filters.to_date){
          dispatch(clearFilterItems());          
          // dispatch(fetchEvents());
          // dispatch(fetchEventsUser());
        }
    };
    
    return (
        <Section className={`filter${props.className ? ' '+props.className : ''}`}>
          {props.title ? <h2><Word value={props.title} /></h2> : null}
          <div className="filter--form">
            {types && types.length > 0 ? 
              <Select 
                title={'Type'}
                name={'type'}
                id={'type'}
                options={types}
                onChange={handleTypesSelectChange}
                value={typesSelect}
              />
            : null}
            {cpd_types && cpd_types.length > 0 ? 
              <Select 
                title={'Accreditation'}
                name={'cpd_type'}
                id={'cpd_type'}
                options={cpd_types}
                onChange={handleCpdTypesSelectChange}
                value={cpdTypesSelect}
              />
            : null}
            <DatePicker  
              title={'From Date'}
              name={'from_date'}
              id={'from_date'}
              placeholder="Select..."
              selected={fromDate ? fromDate.label : null} 
              onChange={(date) => setFromDate(date ? {label: date.toLocaleDateString(), value: date} : null)} 
              dayPickerProps={{
                modifiers: {
                  disabled: [
                    {
                      after: toDate ? toDate.value : null
                    }
                  ]
                }
              }}
            />
            <DatePicker  
              title={'To Date'}
              name={'to_date'}
              id={'to_date'}
              placeholder="Select..."
              selected={toDate ? toDate.label : null} 
              onChange={(date) => setToDate(date ? {label: date.toLocaleDateString(), value: date} : null)}
              dayPickerProps={{
                modifiers: {
                  disabled: [
                    {
                      before: fromDate ? fromDate.value : null
                    }
                  ]
                }
              }}
            />
            {types || cpd_types ? 
              <div className="filter--actions">
                <Button
                    type={"button"}
                    className="btn-primary text-yellow full-width"
                    text="Filter"
                    Clicked={handleFilterSubmit}
                    isDisabled={!typesSelect && !cpdTypesSelect && !fromDate && !toDate ? true : false}
                />
                <Button
                  type={"button"}
                  className="btn-outline full-width"
                  text="Clear all"
                  Clicked={handleClearFilter}
                  isDisabled={!typesSelect && !filters.type && !cpdTypesSelect && !filters.cpd_type && !fromDate && !filters.from_date && !toDate && !filters.to_date ? true : false}
                />
              </div>
            : null}
          </div>
        </Section>
    );
};

export default CpdFilter;