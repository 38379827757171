import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';
import G from '../../app/globals';

const currentYear = new Date().getUTCFullYear(); // Ensures we're using the UTC year

// Create dates directly at 01:00 UTC
const fromDate = new Date(Date.UTC(currentYear, 0, 1, 1, 0, 0));  // January 1 at 01:00 UTC
const toDate = new Date(Date.UTC(currentYear, 11, 31, 1, 0, 0)); // December 31 at 01:00 UTC (technically the beginning of Jan 1 next year)


const initialState = {
  items: null,
  list: null,
  filters: {
    type: null,
    cpd_type: null,
    from_date: {
      label: fromDate.toLocaleDateString(),
      value: fromDate
    },
    to_date: {
      label: toDate.toLocaleDateString(),
      value: toDate
    }
    // from_date: null,
    // to_date: null
  }
};

export const fetchCpdLog = createAsyncThunk("cpd/getAll", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/cpd/items`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const generateCpdCertificate = createAsyncThunk("cpd/certificate", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/cpd/certificate`, { params: parameters });
    thunkAPI.dispatch(setLoadingEnd());
    if (response.data.file) {
      G.BASE64_TO_DOWNLOAD_FILE(response.data.file, parameters);
    }
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


const CpdSlice = createSlice({
  name: "cpd",
  initialState,
  reducers: {
    selectFilterItem(state, action) {
      const { data, filter } = action.payload
      state.filters[filter] = data;
    },
    clearFilterItems(state) {
      state.filters = {
        type: null,
        cpd_type: null,
        from_date: null,
        to_date: null
      };
      state.list = state.items;
    },
    applyFilter(state) {
      let list = state.items ? state.items.filter(item => (
        (!state.filters.type || (state.filters.type && state.filters.type.value === 'all') || (state.filters.type && state.filters.type.value === item.post_type))
        && (!state.filters.cpd_type || (state.filters.cpd_type && item.cpd && state.filters.cpd_type.value === item.cpd.type))
        && (!state.filters.from_date || (state.filters.from_date && new Date(state.filters.from_date.value).setHours(0, 0, 0, 0) <= G.NEW_DATE(item.completed_datetime)))
        && (!state.filters.to_date || (state.filters.to_date && new Date(state.filters.to_date.value).setHours(23, 59, 59, 999) >= G.NEW_DATE(item.completed_datetime)))
      )) : null;
      state.list = list;
    },
    resetCpdReducer() {
      return initialState
    }
  },
  extraReducers: {
    [fetchCpdLog.pending]: (state, action) => {

    },
    [fetchCpdLog.fulfilled]: (state, action) => {
      if (action.payload && action.payload.items) {
        state.items = action.payload.items;
        state.list = action.payload.items.sort(function (a, b) {
          return G.NEW_DATE(b.completed_datetime) - G.NEW_DATE(a.completed_datetime);
        });
      }
    },
    [fetchCpdLog.rejected]: (state, action) => {
      console.log(action);
    },
  }
});

export const { populateEvents, selectFilterItem, clearFilterItems, applyFilter, resetCpdReducer } = CpdSlice.actions
export default CpdSlice.reducer;

