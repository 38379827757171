import React from 'react';
import { Word } from "../components/Word";




const ProgressBar = (props) => {
    let progress = props.value ? Math.floor(props.value) : 0;
    return (
        <div className={`progress-bar${props.className ? ' '+props.className : ''}`}>
            <div className="progress-bar--line">
                <div className="progress-bar--fill" style={{width: `${progress}%`}}></div>
            </div>
            <div className="progress-bar--label">
                {progress+"%"} <Word value="complete" />
            </div>
        </div>
    );
};

export default React.memo(ProgressBar);