import React, {useRef} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import { ReactComponent as ArrrowRightIcon } from '../assets/images/icons/chevron_right_light.svg';



const HorizontalScroller = (props) => {
    const scrollerRef = useRef();
    const scroll = () => {
        scrollerRef.current.scrollLeft += props.scrollOffset;
    };
    return (
        <div className={`horizontal-scoller${props.className ? ' '+props.className : ''}`}>
            <ScrollContainer 
                className="horizontal-scoller--content" 
                innerRef={scrollerRef}
                vertical={false}
                hideScrollbars={false}
            >
                {props.children}
            </ScrollContainer>
            <ArrrowRightIcon className="horizontal-scoller--icon" onClick={scroll} />
        </div>
    );
};

export default React.memo(HorizontalScroller);