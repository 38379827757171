import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { fetchArticleSingle, completeArticle } from "../../store/features/slice-articles";
import { completeLmArticle } from "../../store/features/slice-learning-modules";
import { mutateAccountLMs, mutateAccountLMsArticles } from "../../store/features/slice-account";
import { fetchQandaItem } from "../../store/features/slice-qa";
import { logActivityInSheet } from "../../store/features/slice-log";
import { useIntersection } from "../../helpers/customHooks"
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import CtaCard from "../../components/Cards/Cta";
import FeedCard from "../../components/Cards/Feed";
import { Word } from "../../components/Word";
import Accordion from "../../components/Accordion";
import DownloadCard from "../../components/Cards/DownloadFile";
import QandA from "../../components/QandA";
import Share from "../../components/Share";
import Button from "../../components/Button";
import G from "../../app/globals";
import { add_to_datalayer } from "../../helpers/tracking";
import RegisterLoginPrompt from "../../components/RegisterLoginPrompt";
import ArticleHero from "../../components/article/Hero";


const Article = (props) => {
  const IS_LM_ARTICLE = props.location && props.location.state && props.location.state.sourcePath === 'lm';
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const inViewport = useIntersection(ref, '0px');
  const qaRef = useRef();
  let { slug } = useParams();
  const [qaOpen, setQaOpen] = useState(false);
  const [completeBtn, setCompleteBtn] = useState(false);
  const [articleCompleted, setArticleCompleted] = useState(false);
  const article = useSelector((state) => state.articles.single && state.articles.single.slug === slug ? state.articles.single : null);
  const doctorsListBasic = useSelector((state) => state.doctors.basic);
  const qa = useSelector((state) => {
    let value = null;
    if (IS_LM_ARTICLE && props.location.state.qa) {
      value = state.qa.item && state.qa.item.id === props.location.state.qa.id ? state.qa.item : null
    }
    return value;
  });
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountData = useSelector((state) => state.account.data);
  const isLocked = !isAuthenticated && article && article.user_type ? true : false;

  // Tracking article views
  useEffect(() => {
    if (article && article.title && article.categories?.length > 0) {
      let articleName = article.categories[0].name?.replace(' ', '_') || '';
      articleName = (articleName === 'News') ? 'News_Article' : articleName;
      add_to_datalayer({
        event: articleName,
        article_name: article.title || ''
      });
      const isTreatmentPathway = article.categories.find(item => item.slug === 'treatment-pathways');
      const isTreatmentTechniques = article.categories.find(item => item.slug === 'treatment-techniques');
      const isNews = article.categories.find(item => item.slug === 'news');
      if (isTreatmentPathway) {
        dispatch(logActivityInSheet({ activity: parse(article.title), sheet: 'Treatment Pathways' }));
      }
      if (isTreatmentTechniques) {
        dispatch(logActivityInSheet({ activity: parse(article.title), sheet: 'Treatment Techniques' }));
      }
      if (isNews) {
        dispatch(logActivityInSheet({ activity: parse(article.title), sheet: 'News Articles' }));
      }
    }
  }, [article, dispatch]);

  useEffect(() => {
    dispatch(setPageClass("page-single-article"));
    dispatch(setUpHeader({
      show: true,
      back: true,
      colour: 'white',
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    if ((!article || (article && article.slug !== slug))) {
      dispatch(fetchArticleSingle(slug));
    }
  }, [dispatch, article, slug]);

  useEffect(() => {
    if (IS_LM_ARTICLE && props.location.state.qa && isAuthenticated) {
      dispatch(fetchQandaItem(props.location.state.qa.id));
    }
  }, [dispatch, IS_LM_ARTICLE, props.location, isAuthenticated]);

  useEffect(() => {
    //show complete button on Scroll for LM Article
    if (IS_LM_ARTICLE && article && article.format === 'Article' && accountData && !accountData.lms_articles.includes(article.id) && !completeBtn && inViewport && isAuthenticated) {
      setCompleteBtn(true);
    }
  }, [article, completeBtn, inViewport, IS_LM_ARTICLE, isAuthenticated, accountData]);

  useEffect(() => {
    //Log Clinical Article Completion 
    if (article && article.categories.find(item => item.slug === 'clinical-article') && accountData && !accountData.clinical_articles.includes(article.id) && !articleCompleted && inViewport && isAuthenticated) {
      setArticleCompleted(true);
      dispatch(completeArticle({ id: article.id, category: 'clinical-article' }))
    }
  }, [dispatch, article, inViewport, articleCompleted, isAuthenticated, accountData])



  const handleVideoProgress = (e) => {
    if (e.played >= 0.75 && accountData && !accountData.lms_articles.includes(article.id) && IS_LM_ARTICLE && !completeBtn && isAuthenticated) {
      setCompleteBtn(true)
    }
  }

  const completeResource = () => {
    dispatch(completeArticle({ id: article.id, lm_id: props.location.state.lm_id, category: 'learning-resource' })).then((e) => {
      if (e.payload) {
        if (e.payload.params) {
          dispatch(mutateAccountLMsArticles({ id: +e.payload.params.id }));
          dispatch(completeLmArticle({ id: +e.payload.params.id }));
        }
        if (e.payload.lms) {
          dispatch(mutateAccountLMs({ lms: e.payload.lms }));
          const parentLm = e.payload.lms.find(item => item.parent && item.progress === 100);
          if (parentLm) {
            // LM is fully completed
            add_to_datalayer({
              event: 'Learning_Module_Completion',
              module_name: parentLm.title || ''
            });
            dispatch(logActivityInSheet({ activity: parentLm.title, sheet: 'Learning Modules' }));
          }
        }
      }
      history.push(`/education/learning-module/${props.location.state.lm}`);
    })
  }

  const handleQaClick = () => {
    setQaOpen(true);
    setTimeout(() => {
      qaRef.current.scrollIntoView({ behavior: "smooth" });
    }, 200)
  }

  return (
    article && (
      <>
        {isLocked ?
          <>
            <PageHead
              className="page-single-article--page-head"
            />
            <PageContent className={`page-single-article--content`}>
              <ArticleHero
                article={article}
                isLmArticle={IS_LM_ARTICLE}
                isLocked
              />
              <Section className="page-single-article--section page-single-article--the-content is-locked">
                {parse(article.snippet, G.HTML_PARSE_OPTIONS)}
              </Section>
              <RegisterLoginPrompt />
            </PageContent>
          </>
          :
          <>
            <PageHead
              className="page-single-article--page-head"
            />
            <PageContent className={`page-single-article--content`}>
              {article ?
                <>
                  <ArticleHero
                    article={article}
                    onVideoProgress={handleVideoProgress}
                    isLmArticle={IS_LM_ARTICLE}
                  />
                  <Section className="page-single-article--section page-single-article--the-content">
                    {parse(article.content, G.HTML_PARSE_OPTIONS)}
                    <div ref={ref}></div>
                  </Section>
                  {((article.authors && article.authors.length > 0) || (article.guest_authors && article.guest_authors.length > 0)) && doctorsListBasic ?
                    <Section className="page-single-article--section">
                      <h3><Word value="Authors" /></h3>
                      <div className="page-single-article--grid">
                        {article.authors.map((item, index) => {
                          return doctorsListBasic[item] ? <CtaCard
                            key={index}
                            image={doctorsListBasic[item].profileImage}
                            title={parse(doctorsListBasic[item].salutation + ' ' + doctorsListBasic[item].name)}
                            icon={"chevron_right"}
                            iconBg={"blue"}
                            isCentered
                            isSmall
                            link={`/refer-patient/${item}`}
                          />
                            : null
                        })}
                        {article.guest_authors.map((item, index) => {
                          return <CtaCard
                            key={index}
                            image={item.image}
                            title={item.name}
                            icon={item.url ? "chevron_right" : null}
                            iconBg={item.url ? "blue" : null}
                            isCentered
                            isSmall
                            Clicked={item.url ? () => window.open(item.url) : null}
                          />
                        })}
                      </div>
                    </Section>
                    : null}
                  {article.accordion_content && article.accordion_content.length > 0 ?
                    <Section className="page-single-article--section">
                      {article.accordion_content.map((item, index) => (
                        <Accordion key={index} title={<Word value={item.title} />}>
                          {parse(item.text, G.HTML_PARSE_OPTIONS)}
                        </Accordion>
                      ))}
                    </Section>
                    : null}
                  {article.downloads && article.downloads.length > 0 ?
                    <Section className="page-single-article--section">
                      <div className="page-single-article--grid">
                        {article.downloads.map((item, index) => (
                          item.file ?
                            <DownloadCard
                              key={index}
                              title={item.title}
                              url={item.file.url}
                              type={item.file.subtype}
                              icon={item.file.icon}
                            />
                            : null

                        ))}
                      </div>
                    </Section>
                    : null}
                  {!IS_LM_ARTICLE ? <Share className="page-single-article--section page-single-article--share" title={parse(article.title)} /> : null}
                  {!IS_LM_ARTICLE && article.related && article.related.length > 0 ?
                    <Section className="page-single-article--section">
                      <h3><Word value="Related Articles" /></h3>
                      <div className="page-single-article--grid">
                        {article.related.map((item, index) => (
                          <FeedCard
                            key={item.id}
                            image={item.thumbnail}
                            title={parse(item.title)}
                            // description={parse(item.excerpt)}
                            categories={item.format ? [{ 'title': item.format, 'colour': 'yellow' }] : null}
                            completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
                            cpd={G.CPD_TEXT(item.cpd)}
                            link={({ pathname: `/article/${item.slug}` })}
                          />
                        ))}
                      </div>
                    </Section>
                    : null}
                  <div ref={qaRef}></div>
                  {IS_LM_ARTICLE && !qaOpen && accountData && !accountData.lms_articles.includes(article.id) && isAuthenticated ?
                    <Section className="page-single-article--actions">
                      <div className="page-single-article--actions-content">
                        {props.location.state.qa ?
                          <Button
                            className="btn-primary green full-width-m"
                            text="Start Q&A"
                            Clicked={handleQaClick}
                            isDisabled={!completeBtn}
                          />
                          :
                          <Button
                            className="btn-primary green full-width-m"
                            text="Complete Resource"
                            Clicked={completeResource}
                            isDisabled={!completeBtn}
                          />
                        }
                      </div>
                    </Section>
                    : null}
                  {IS_LM_ARTICLE && props.location.state.qa && qa && (qaOpen || qa.answers) && isAuthenticated ?
                    <Section className="page-single-article--section">
                      <h2><Word value='Q&A' /></h2>
                      <QandA id={qa.id} questions={qa.questions} answers={qa.answers} Completed={completeResource} />
                    </Section>
                    : null}
                </>
                : null}
            </PageContent>
          </>
        }
      </>
    )
  );
};

export default Article;
