import React from 'react';
import { Word } from './Word';

const Button = (props) => {
  return(
      <button 
        className= {`btn ${props.className}`} 
        type={props.type ? props.type : 'button'} 
        onClick={props.Clicked ? props.Clicked : null} 
        disabled={props.isDisabled}
        name={props.name}
      >
        {props.textIcon}
        {props.text ? <span><Word value={props.text} /></span> : null}
        {props.icon}
      </button>
      )
};

export default React.memo(Button);