import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { updatePreferences } from "../../store/features/slice-account";

import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import Checkbox from "../../components/Form/Checkbox";

import UserSummary from "../../components/UserSummary";


const Preferences = () => {
    const dispatch = useDispatch();
    const [selectedTopics, setSelectedTopics] = useState([]);
    const topics = useSelector((state) => state.references.topics);
    useEffect(() => {
      dispatch(setPageClass("page-preferences"));
      dispatch(setUpHeader({
        show: true,
        back: true,
        profile: true,
        search: true
      }));
    }, [dispatch]);

    useEffect(() => {
      if(localStorage.getItem("user_preferences") && JSON.parse(localStorage.getItem("user_preferences")) && JSON.parse(localStorage.getItem("user_preferences")).length > 0){
        setSelectedTopics(JSON.parse(localStorage.getItem("user_preferences")).map(item => item.value));
      }
    }, [])

    const handleChange = (e) => {
      let checkbox_arr = [];
      if(e.target.checked){
        checkbox_arr = [...selectedTopics, e.target.name]
      }
      else{
        checkbox_arr = selectedTopics.filter(item => item !== e.target.name)
      }
      setSelectedTopics(checkbox_arr);
    };

    const handlePreferencesSave = () => {
      dispatch(updatePreferences({_topics: selectedTopics}));
    }

    return (
      <>
        {topics ? 
        <>
          <PageHead 
            className="page-preferences--page-head"
            title="Preferences"
          />
          <PageContent className="page-preferences--page-content">
            <Section className="split-content w-40-60">
                <Section><UserSummary save={handlePreferencesSave} /></Section>
                <Section className="page-preferences--list">
                <div className="page-preferences--list-intro">Please select your interest areas to  personalise your education recommendations</div>
                  {topics && topics.length > 0 ?
                    topics.map((topic, index) => {
                      return <Checkbox
                              key={index}
                              title={topic.name}
                              name={topic.slug}
                              handleChange={handleChange}
                              isChecked={selectedTopics && selectedTopics.includes(topic.slug) ? true : false}
                            />
                    })
                  : null}
                </Section>
            </Section>
          </PageContent>
        </>
        : null}
      </>
    );
};

export default Preferences;
