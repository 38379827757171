import React from 'react';
import { Word } from './Word';
import Section from './Section';
import { ReactComponent as FacebookIcon } from '../assets/images/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../assets/images/icons/twitter.svg';
import { ReactComponent as LinkedinIcon } from '../assets/images/icons/linkedin.svg';
import { ReactComponent as MailIcon } from '../assets/images/icons/mail.svg';


const Share = (props) => {
    const currentPageLink = window.location.href;
    return (
        <Section className={`wb-share ${props.className ? props.className : ''}`}>
            <div className={"wb-share--title"}><Word value="Share" /></div>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentPageLink}&display=popup&ref=plugin&src=share_button`} target="_blank" rel="noreferrer" className="wb-share--item facebook"><FacebookIcon /></a>
            <a href={`https://twitter.com/share?url=${currentPageLink}&text=${props.title}`} target="_blank" rel="noreferrer" className="wb-share--item twitter"><TwitterIcon /></a>
            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentPageLink}`} target="_blank" rel="noreferrer" className="wb-share--item linkedin"><LinkedinIcon /></a>
            <a href={`mailto:?subject=${props.title}>&body=Read this article on the ICON IDA app: ${currentPageLink}`} rel="noreferrer" className="wb-share--item mail"><MailIcon /></a>
        </Section>
    );
};
export default React.memo(Share);