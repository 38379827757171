import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import PageContent from "../../components/PageContent";
import { ReactComponent as CheckedSolidIcon } from '../../assets/images/icons/check_solid.svg';


const RegisterSuccess = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageClass("page-register-success no-bottom-menu"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      sideNav: false
    }));
  }, [dispatch]);
  
  
  return (
    <>
      <PageContent className="page-register-success--page-content">
        <div className="success--message">
          <div className="success--message-icon"><CheckedSolidIcon /></div>
          <h2>Thanks for registering</h2>
        </div>
      </PageContent>
    </>
  );
};

export default RegisterSuccess;
