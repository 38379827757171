import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PublicRoute from '../components/PublicRoute';
import PrivateRoute from '../components/PrivateRoute';
import Login from '../pages/Login';
import Register from '../pages/register/Form';
import RegisterSuccess from '../pages/register/Success';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Account from '../pages/account/Index';
import AccountCpdList from '../pages/account/cpd/List';
import AccountCpdFilter from '../pages/account/cpd/Filter';
import AccountProfile from '../pages/account/Profile';
import AccountEditProfile from '../pages/account/EditProfile';
import AccountPreferences from '../pages/account/Preferences';
import AccountChangePassword from '../pages/account/ChangePassword';
import AccountMarketing from '../pages/account/Marketing';
import AccountFavourites from '../pages/account/Favourites';
import Home from '../pages/home/Home';
import Education from '../pages/education/Index';
import Events from '../pages/education/events/Index';
import EventsFilter from '../pages/education/events/Filter';
import EventsSingle from '../pages/education/events/Single';
import EventsRegisterSuccess from '../pages/education/events/RegisterSuccess';
import EventsSingleSub from '../pages/education/events/SingleSub';
import LearningModules from '../pages/education/learning-modules/Index';
import LearningModulesFilter from '../pages/education/learning-modules/Filter';
import LearningModulesSingle from '../pages/education/learning-modules/Single';
import ReferPatient from '../pages/refer-patient/Index';
import ReferPatientForm from '../pages/refer-patient/Refer';
import ReferPatientConfirmation from '../pages/refer-patient/Confirmation';
import CentresList from '../pages/centres/List';
import CentresNearest from '../pages/centres/Nearest';
import CentresFilter from '../pages/centres/Filter';
import More from '../pages/more/Index';
import ClinicalResources from '../pages/more/ClinicalResources';
import Research from '../pages/more/Research';
import Report from '../pages/more/Report';
import Search from '../pages/Search';
import ArticlesList from '../pages/article/List';
import ArticlesFilter from '../pages/article/Filter';
import ArticleSingle from '../pages/article/Single';
import GenericPage from '../pages/GenericPage';
import BecomeDoctor from '../pages/more/BecomeDoctor';
import ClinicalTrialsList from '../pages/clinical-trials/List';
import ClinicalTrialsFilter from '../pages/clinical-trials/Filter';
import ClinicalTrialsSingle from '../pages/clinical-trials/Single';
import PracticeBased from '../pages/education/PracticeBased';
import NotFound from '../pages/NotFound'


const Routes = () => {
    return (
        <Switch>
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/register" component={Register} />
            <PublicRoute exact path="/register/success" component={RegisterSuccess} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PublicRoute exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/" component={Home} />
            <Route exact path="/education" component={Education} />
            <Route exact path="/education/events/:type(live|registered|upcoming|recommended|attended|past)?" component={Events} />
            <Route exact path="/education/events/filter" component={EventsFilter} />
            <Route exact path="/education/event/:slug" component={EventsSingle} />
            <Route exact path="/education/event/:slug/:sub(summary|agenda|register)" component={EventsSingleSub} />
            <Route exact path="/education/event/:slug/register-success" component={EventsRegisterSuccess} />
            <Route exact path="/education/clinical-articles/filter" component={ArticlesFilter} />
            <Route exact path="/education/clinical-articles/:page?" component={ArticlesList} />
            <Route exact path="/education/learning-modules/started" component={LearningModules} />
            <Route exact path="/education/learning-modules/completed" component={LearningModules} />
            <Route exact path="/education/learning-modules/filter" component={LearningModulesFilter} />
            <Route exact path="/education/learning-modules/:page?" component={LearningModules} />
            <Route exact path="/education/learning-module/:slug" component={LearningModulesSingle} />
            <Route exact path="/education/practice-based-case-discussions-dinners" component={PracticeBased} />
            <Route exact path="/education/practice-based-case-discussions-dinners/success" component={() =><GenericPage id={74223} />} />


            <Route exact path="/refer-patient" component={ReferPatient} />
            <Route exact path="/refer-patient/confirmation" component={ReferPatientConfirmation} />
            <Route exact path="/refer-patient/:doctorSlug" component={ReferPatientForm} />
            <Route exact path="/more" component={More} />
            <Route exact path="/clinical-resources" component={ClinicalResources} />
            <Route exact path="/research" component={Research} />
            <Route exact path="/research/clinical-trials" component={ClinicalTrialsList} />
            <Route exact path="/research/clinical-trials/filter" component={ClinicalTrialsFilter} />
            <Route exact path="/research/clinical-trials/eoi-success" component={() =><GenericPage id={72597} />} />
            <Route exact path="/research/clinical-trials/:slug" component={ClinicalTrialsSingle} />
            <Route exact path="/clinical-resources/treatment-pathways/:page?" component={ArticlesList} />
            <Route exact path="/clinical-resources/treatment-techniques/:page?" component={ArticlesList} />
            <Route exact path="/news/:page?" component={ArticlesList} />
            <Route exact path="/contact-icon/all" component={CentresList} />
            <Route exact path="/contact-icon" component={CentresNearest} />
            <Route exact path="/contact-icon/filter" component={CentresFilter} />
            <Route exact path="/report-issue" component={Report} />
            <Route exact path="/practice-with-icon" component={BecomeDoctor} />
            <Route exact path="/practice-with-icon/success" component={() => <GenericPage id={64857} />} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/search/:keyword" component={Search} />
            <Route path="/article/:slug" component={ArticleSingle} />
            <Route exact path="/about-icon" component={() => <GenericPage id={1756} />} />
            <Route exact path="/disclaimer" component={() => <GenericPage id={739} />} />
            <Route exact path="/privacy" component={() => <GenericPage id={741} />} />
            <PrivateRoute exact path="/account" component={Account} />
            <PrivateRoute exact path="/account/cpd-log" component={AccountCpdList} />
            <PrivateRoute exact path="/account/cpd-log/filter" component={AccountCpdFilter} />
            <PrivateRoute exact path="/account/profile" component={AccountProfile} />
            <PrivateRoute exact path="/account/profile/edit" component={AccountEditProfile} />
            <PrivateRoute exact path="/account/preferences" component={AccountPreferences} />
            <PrivateRoute exact path="/account/change-password" component={AccountChangePassword} />
            <PrivateRoute exact path="/account/marketing" component={AccountMarketing} />
            <PrivateRoute exact path="/account/favourites" component={AccountFavourites} />
            <Route component={NotFound} />
        </Switch>
    )
};

export default Routes;