import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import {useBasePath} from "../../helpers/customHooks"
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import ArticlesFilter from '../../components/Filters/ArticlesFilter';

const ArticlesFilterPage = () => {
  const dispatch = useDispatch();
  let basePath = useBasePath();
  basePath = basePath.substring(0, basePath.lastIndexOf('/'));
  let section = null;
  switch (basePath) {
    case '/education/clinical-articles': 
        section = 'clinical-article';
    break;
    case '/clinical-information/treatment-pathways': 
        section = 'treatment-pathways';
    break;
    case '/clinical-information/treatment-techniques': 
        section = 'treatment-techniques';
    break;
    case '/research': 
        section = 'research';
    break;
    case '/news': 
        section = 'news';
    break;
    default: 
        section = 'clinical-article';
}
  useEffect(() => {
    dispatch(setPageClass("page-filter-articles"));
    dispatch(setUpHeader({
      show: true,
      back: true, 
      profile: true,
      search: true
    }));
  }, [dispatch]);

  return (
    <>
      <PageHead 
        className="page-filter-articles--page-head"
        title="Filter"
      />
      <PageContent className="page-filter-articles--page-content">
        <ArticlesFilter 
          className="page-filter-articles--content-wrapper pt-0" 
          pushAfterSubmit={`${basePath}`}
          filter={section}
        />
      </PageContent>
    </> 
    
  );
};

export default ArticlesFilterPage;
