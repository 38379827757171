import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { completeProfile } from "../store/features/slice-account";
import { resetErrors } from "../store/features/slice-api";
import Input from "../components/Form/Input";
import Button from "../components/Button";
import { Word } from "../components/Word";
import Select from "../components/Form/Select";
import G from "../app/globals";
import { ReactComponent as CloseIcon } from '../assets/images/icons/close_circle.svg';


const CompleteRegisterForm = ({ accountData, buttonText }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const specialities = useSelector((state) => state.references.specialities);
  const errors = useSelector((state) => state.api.errors.complete_register);
  const initialFormState = {
    _speciality: "",
    _sub_specialities: "",
    _racgp_number: "",
    _clinics: [
      {
        name: "",
        address: "",
        email: "",
        provider_number: ""
      }
    ]

  };
  const [form, setForm] = useState(initialFormState);
  const [subSpecialities, setSubSpecialities] = useState([]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };
  const handleSelectChange = (source, payload, isMulti) => {
    setForm({
      ...form,
      [source]: isMulti ? payload.map(item => item.value) : payload.value
    });
  };

  const handleSpecialityChange = (payload) => {
    setForm({
      ...form,
      '_speciality': payload?.value,
      '_sub_specialities': '',
    });
    if (payload?.children && payload?.children.length > 0) {
      setSubSpecialities(payload.children);
    }
  };

  const handleChangeClinic = (e, index) => {
    setForm({
      ...form,
      '_clinics': form._clinics.map((clinic, i) => index === i ? { ...clinic, [e.target.name]: e.target.value } : clinic)
    });
  }

  const handleAddClinic = () => {
    setForm({
      ...form,
      '_clinics': [...form._clinics, {
        name: "",
        address: "",
        email: "",
        provider_number: ""
      }]
    })
  }

  const handleDeleteClinic = (i) => {
    var clinics = form._clinics.filter((value, index) => index !== i);
    setForm({
      ...form,
      '_clinics': clinics
    })
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(completeProfile(form)).then(() => {
        G.RESET_SCROLL();
        history.go(0);
    });
    // setState(initialFormState);
  };
  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);


  return (
    <>
      <div className="complete-register-form">
        {errors && errors.length > 0 ?
          <div className="form-errors">
            {errors.map((error, index) => (
              <div key={index} className="form-errors--item">{error.message}</div>
            ))}
          </div>
          : null}
        <form onSubmit={handleSubmit}>
            <h2><Word value="Professional details" /></h2>
            {specialities && Object.values(specialities).length > 0 ?
              <>
                <Select
                  title={'Speciality'}
                  name={'_speciality'}
                  options={Object.values(specialities)}
                  onChange={handleSpecialityChange}
                  value={form._speciality ? ({ label: specialities[form._speciality].label, value: form._speciality }) : null}
                  isRequired
                />
                <Select
                  title={'Sub Speciality'}
                  name={'_sub_specialities'}
                  options={subSpecialities}
                  onChange={(payload) => handleSelectChange('_sub_specialities', payload, true)}
                  value={form._sub_specialities ? form._sub_specialities.map(item => ({ label: item, value: item })) : null}
                  isMulti
                  isRequired
                />
              </>
              : null
            }
            {!accountData.racgpNumber && (
                <Input
                    type={"text"}
                    name={"_racgp_number"}
                    title={"RACGP Number"}
                    value={form._racgp_number}
                    handleChange={handleChange}
                />
            )}
            <h2><Word value="Practice details" /></h2>
            {form._clinics.map((clinic, index) => (
              <div key={index} className={`form-container${index > 0 ? ' has-close' : ''}`}>
                {index > 0 ? <div className="form-container--close" onClick={() => handleDeleteClinic(index)}><CloseIcon /></div> : null}
                <Input
                  type={"text"}
                  name={"name"}
                  title={"Clinic name"}
                  value={clinic.name}
                  handleChange={(e) => handleChangeClinic(e, index)}
                  isRequired
                />
                <Input
                  type={"text"}
                  name={"address"}
                  title={"Clinic address"}
                  value={clinic.address}
                  handleChange={(e) => handleChangeClinic(e, index)}
                  isRequired
                />
                <Input
                  type={"email"}
                  name={"email"}
                  title={"Clinic email"}
                  value={clinic.email}
                  handleChange={(e) => handleChangeClinic(e, index)}
                  isRequired
                />
                <Input
                  type={"text"}
                  name={"provider_number"}
                  title={"Provider number"}
                  value={clinic.provider_number}
                  handleChange={(e) => handleChangeClinic(e, index)}
                  isRequired
                />
              </div>
            ))}
            {form._clinics.length < G.CLINICS_ALLOWED_NB ?
              <Button
                className="btn-plain blue add-btn"
                text="+ Add another clinic"
                Clicked={handleAddClinic}
              />
            : null}
          <Button
            type={"submit"}
            className="btn btn-primary btn-blue text-yellow full-width"
            text={buttonText || 'Complete'}
          />
        </form>
      </div>
    </>
  );
};

export default CompleteRegisterForm;