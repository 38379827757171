import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';

export const useBasePath = () => {
    const location = useLocation();
    const params = useParams();

    return Object.values(params).reduce(
        (path, param) => path.replace('/' + param, ''),
        location.pathname,
    );
};


export const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        let el = element.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, { rootMargin }
        );

        el && observer.observe(el);

        return () => el ? observer.unobserve(el) : null;
    });

    return isVisible;
};

// export const useScript = (url, selector = 'body', async = true) => {
//   useEffect(() => {
//     const element = document.querySelector(selector)
//     const script = document.createElement('script')
//     script.src = url
//     script.async = async
//     element.appendChild(script)
//     return () => {
//       element.removeChild(script)
//     }
//   }, [url, async, selector])
// }