import './app/wdyr';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import store from "./store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-M44RP49'
// }

if (process.env.NODE_ENV === 'production' ) {
  disableReactDevTools();
}
// if(process.env.REACT_APP_ENVIRONMENT === 'production'){
//   TagManager.initialize(tagManagerArgs)
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


