import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../../store/features/slice-ui";
import { fetchEventSingle } from "../../../store/features/slice-events";
import PageHead from "../../../components/PageHead";
import PageContent from "../../../components/PageContent";
import Section from "../../../components/Section";
import Loading from "../../../components/Loading";
import G from "../../../app/globals";

const SingleSub = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  let { slug } = useParams();
  let { sub } = useParams();
  const eventsSingle = useSelector((state) => state.events.single && state.events.single.slug === slug ? state.events.single : null);

  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    dispatch(setPageClass("page-event-single"));
    dispatch(setUpHeader({
      show: true,
      back: true,
      profile: true,
      search: true,
      colour: 'white'
    }));
  });

  useEffect(() => {
    if ((!eventsSingle || (eventsSingle && eventsSingle.slug !== slug))) {
        dispatch(fetchEventSingle(slug));

    }
  }, [dispatch, eventsSingle, slug]);

  useEffect(() => {
    if((sub === 'summary' && eventsSingle && !eventsSingle.cventEventSummary)){
      history.replace(`/education/event/${slug}`);
    }
    if((sub === 'agenda' && eventsSingle && !eventsSingle.cventEventAgenda)){
      history.replace(`/education/event/${slug}`);
    }
    if((sub === 'register' && eventsSingle && !eventsSingle.registrationLink)){
      history.replace(`/education/event/${slug}`);
    }
  }, [sub, eventsSingle, history, slug]);

  const renderContent = () => {
    let title = "";
    let iframe_link = "";
  
    switch(sub){
      case 'summary': 
        title = `${parse(eventsSingle.name)} - Summary`;
        iframe_link = eventsSingle.cventEventSummary;
      break;
      case 'agenda': 
        title = `${parse(eventsSingle.name)} - Agenda`;
        iframe_link = eventsSingle.cventEventAgenda;
      break;
      case 'register': 
        title = `${parse(eventsSingle.name)} - Registration`;
        iframe_link = eventsSingle.registrationLink;
      break;
      default: 
        title = ``;
        iframe_link = ``;
    }
    return (
      <>
       <h1>{title}</h1>
        {!iframeLoaded ? <Loading /> : null}
        <iframe
          src={iframe_link}
          onLoad={() => setIframeLoaded(true)}
          width="100%"
          height="1200px"
          frameBorder="0"
          title={title}
        ></iframe>
      </>
    )
  }

  
  return (
    <>
      {isDesktop ? 
        <PageHead 
          className="page-event-single--page-head"
          title="Events"
        />
      : null}
      { eventsSingle ? 
      <PageContent className="page-event-single--page-content">
        <Section className="">
          {renderContent()}
        </Section>
      </PageContent>
      : null }
    </>
    
  );
};

export default SingleSub;
