import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';

const initialState = {
  topics: null,
  specialities: null,
  cpd_types: null,
  disclaimer: null,
  privacy: null,
  options: null,
  welcome: null
};

export const fetchTopics = createAsyncThunk("references/getTopics", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/topics`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchSpecialties = createAsyncThunk("references/getSpecialties", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/specialities`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchPage = createAsyncThunk("references/getPage", async (id,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/page/${id}`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchPageWelcome = createAsyncThunk("references/getPageWelcome", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/page-welcome`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchOptions = createAsyncThunk("references/getOptions", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/options`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);



const refSlice = createSlice({
  name: "references",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTopics.pending]: (state, action) => {},
    [fetchTopics.fulfilled]: (state, action) => {
      if(action.payload){
        state.topics = action.payload.topics;
        state.cpd_types = [
          {
            label: 'Self-directed',
            value: 'Self-directed'
          },
          {
            label: 'Accredited',
            value: 'Accredited'
          }
        ];
      }

    },
    [fetchTopics.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchSpecialties.pending]: (state, action) => {},
    [fetchSpecialties.fulfilled]: (state, action) => {
      if(action.payload){
        state.specialities = action.payload.specialities
      }
    },
    [fetchSpecialties.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchPage.pending]: (state, action) => {},
    [fetchPage.fulfilled]: (state, action) => {
      if(action.meta && action.meta.arg){
        switch(action.meta.arg){
          case 739 : 
            state.disclaimer = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 741 : 
            state.privacy = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 1756 : 
            state.about = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 1759 : 
            state.startup = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 1768 : 
            state.report_issue = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 5323 : 
            state.become_doctor = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 64857 : 
            state.become_doctor_success = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 74225 : 
            state.practice_based = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 74223 : 
            state.practice_based_eoi_success = action.payload && action.payload.page ? action.payload.page : null;
          break;
          case 72597 : 
            state.trial_eoi_success = action.payload && action.payload.page ? action.payload.page : null;
          break;
          default: 
            state.disclaimer = null;
            state.privacy = null;
        }
      }

    },
    [fetchPage.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchPageWelcome.pending]: (state, action) => {},
    [fetchPageWelcome.fulfilled]: (state, action) => {
      state.welcome = action.payload?.page
    },
    [fetchPageWelcome.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchOptions.pending]: (state, action) => {},
    [fetchOptions.fulfilled]: (state, action) => {
      if(action.payload){
        state.options = action.payload.options
      }
    },
    [fetchOptions.rejected]: (state, action) => {
      console.log(action);
    }
  }
});

// export const { } = refSlice.actions
export default refSlice.reducer;

