import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import G from "../app/globals"

export default function ScrollToTop() {
  const {pathname} = useLocation();

  useEffect(() => {
      G.RESET_SCROLL();
  }, [pathname]);

  return null;
}