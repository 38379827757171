import React from 'react';
import { useSelector } from "react-redux";

import CenterListItem from "../Cards/Contact";

import G from "../../app/globals";



const SingleLocations = (props) => {

    const centresListBasic = useSelector((state) => state.centres.basic);
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);

    return (
        <div className="page-doctor-single--locations">
            {centresListBasic && props.centres.length > 0 ? 
                props.centres.map((centre) => {
                    return <CenterListItem
                                key={centresListBasic[centre.slug].id}
                                image={centresListBasic[centre.slug].image}
                                title={centresListBasic[centre.slug].title}
                                phone={centresListBasic[centre.slug].phone}
                                address={centresListBasic[centre.slug].address ? centresListBasic[centre.slug].address : centresListBasic[centre.slug].location['address']}
                                fax={centresListBasic[centre.slug].fax}
                                isDesktop={isDesktop}
                            />
                }) 
            : null}
        </div> 
    );
};

export default SingleLocations;