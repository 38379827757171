import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';
import { add_to_datalayer } from "../../helpers/tracking";

const initialState = {};


export const reportIssue = createAsyncThunk("form/report-issue", async (data,thunkAPI) => {
    thunkAPI.dispatch(setLoadingStart());
    try {
      const response = await axios.post(`api/v1/form/report-issue`, data.form);
      thunkAPI.dispatch(setLoadingEnd());
      return thunkAPI.dispatch(handleApiResponse(response));
    }
    catch (error) {
        let errorData = (error.response || {}).data;
        thunkAPI.dispatch(setLoadingEnd());
        thunkAPI.dispatch(handleApiErrors(errorData));
        return thunkAPI.rejectWithValue(errorData);
    }
}
);

export const becomeDoctor = createAsyncThunk("form/become-doctor", async (data,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.post(`api/v1/form/become-doctor`, data.form);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const clinicalTrialEOI = createAsyncThunk("form/ct_eoi", async (data,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.post(`api/v1/form/ct-eoi`, data.form);
    if (response.data.success) {
      add_to_datalayer({
        event: 'EOI_Form',
        form_type: 'Clinical Trial',
        trial_name: data?.form?._trial_name || ''
      });
    }
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const practiceBasedEoi = createAsyncThunk("form/practice_based_eoi", async (data,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.post(`api/v1/form/practice-based-eoi`, data.form);
    if (response.data.success) {
      add_to_datalayer({
        event: 'EOI_Form',
        form_type: 'Practice Based'
      });
    }
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      let errorData = (error.response || {}).data;
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(errorData));
      return thunkAPI.rejectWithValue(errorData);
  }
}
);


const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    resetFormsReducer(){
      return initialState
    }
  },
  extraReducers: {
    [reportIssue.pending]: (state, action) => {
    },
    [reportIssue.fulfilled]: (state, action) => {
    },
    [reportIssue.rejected]: (state, action) => {
      console.log(action);
    },
    [becomeDoctor.pending]: (state, action) => {
    },
    [becomeDoctor.fulfilled]: (state, action) => {
    },
    [becomeDoctor.rejected]: (state, action) => {
      console.log(action);
    },
    [clinicalTrialEOI.pending]: (state, action) => {
    },
    [clinicalTrialEOI.fulfilled]: (state, action) => {
    },
    [clinicalTrialEOI.rejected]: (state, action) => {
      console.log(action);
    },
    [practiceBasedEoi.pending]: (state, action) => {
    },
    [practiceBasedEoi.fulfilled]: (state, action) => {
    },
    [practiceBasedEoi.rejected]: (state, action) => {
      console.log(action);
    }
  },
});

export const { resetFormsReducer} = formsSlice.actions
export default formsSlice.reducer;

