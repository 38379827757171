import React from 'react';
import Link  from '../Link';
import ProgressBar from '../ProgressBar';
import { ReactComponent as ArrrowRightIcon } from '../../assets/images/icons/chevron-right.svg';


const SubTopic = React.forwardRef((props, ref) => {
    return (
        <Link ref={ref} className={`card card-subtopic${props.isDisabled ? ' is-disabled' : ''}`}
        to={props.Clicked || !props.link || props.isDisabled  ? null : props.link} onClick={props.Clicked && !props.isDisabled ? props.Clicked : null}>
            <figure className={`card-subtopic--figure`}><div className="card-subtopic--figure-index">{props.number}</div></figure>
            <div className="card-subtopic--content">
                {props.title ? <h4 className="card-subtopic--title">{props.title}</h4> : null}
                {props.progress !== undefined && !props.isDisabled ? <ProgressBar value={props.progress} /> : null}
            </div>
            <div className={`card-subtopic--icon`}>
                <ArrrowRightIcon />
            </div>
        </Link>
    );
});

export default SubTopic;