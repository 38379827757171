import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import axios from '../../services/apiService';

const initialState = {
  list: {
    lms: null,
    pagination: null
  },
  started: null,
  completed: null,
  filters:{
    topics: null,
    cpd_type: null,
    category: null
  },
  single: null
};

export const fetchLearningModules = createAsyncThunk("learning-modules/getAll", async (parameters,thunkAPI) => {
  let page = parameters && parameters.page ? parameters.page : 1;
  let userPreferences = parameters && parameters.preferences ? parameters.preferences.map(function (obj) {return obj.value}) : null;
  let tax_query = [];
  if(thunkAPI.getState().learningModules.filters.topics && thunkAPI.getState().learningModules.filters.topics.length > 0){
    tax_query.push('topic:'+thunkAPI.getState().learningModules.filters.topics.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().learningModules.filters.category){
    tax_query.push('lm-category:'+thunkAPI.getState().learningModules.filters.category.value)
  }
  let meta_query = [];
  if(thunkAPI.getState().learningModules.filters.cpd_type){
    meta_query.push('global_cpd_type:'+thunkAPI.getState().learningModules.filters.cpd_type.value);
  }
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/learning-modules`, { 
      type : 'public',
      params: { 
        page: page,
        ppp: 25,
        lang: thunkAPI.getState().settings.language,
        tax_query: tax_query,
        meta_query: meta_query,
        topics_preferences: userPreferences ? userPreferences : null
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchLearningModulesStarted = createAsyncThunk("learning-modules/getStarted", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/learning-modules/started`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchLearningModulesCompleted = createAsyncThunk("learning-modules/getCompleted", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/learning-modules/completed`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

export const fetchLearningModuleSingle = createAsyncThunk("learning-modules/getSingleLM", async (id,thunkAPI) => {

  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`api/v1/learning-module/${id}`, {type : 'public'});
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);


export const fetchLearningModulesCategories = createAsyncThunk("learning-modules/getCategories", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`/api/v1/taxonomies/lm-category?type=learning-module`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    let errorData = (error.response || {}).data;
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(errorData));
    return thunkAPI.rejectWithValue(errorData);
  }
}
);

const learningModulesSlice = createSlice({
  name: "learningModules",
  initialState,
  reducers: {
    selectFilterItem(state, action) {
      const { data, filter } = action.payload
      state.filters[filter] = data;
    },
    clearFilterItems(state) {
      state.filters = {
        topics: null,
        cpd_type: null
      };
    },
    clearSingleLM(state) {
      state.single = null;
    },
    resetLmReducer(){
      return initialState
    },
    completeLmArticle(state, action){
      if(state.single && state.single.resources){
        let article = state.single.resources.find(item => item.post.id === action.payload.id);
        if(article){
          article.post.completed = true;
        }
      }
    }
  },
  extraReducers: {
    [fetchLearningModules.pending]: (state, action) => {
      
    },
    [fetchLearningModules.fulfilled]: (state, action) => {
      if(action.payload){
        state.list.lms = action.payload['learning-modules'];
        state.list.pagination = action.payload['pagination'];
      }

    },
    [fetchLearningModules.rejected]: (state, action) => {
      console.log(action);
    },

    [fetchLearningModulesStarted.pending]: (state, action) => {
      
    },
    [fetchLearningModulesStarted.fulfilled]: (state, action) => {
      if(action.payload){
        state.started = action.payload['learning-modules']
      }
    },
    [fetchLearningModulesStarted.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchLearningModulesCompleted.pending]: (state, action) => {
      
    },
    [fetchLearningModulesCompleted.fulfilled]: (state, action) => {
      if(action.payload){
        state.completed = action.payload['learning-modules']
      }
    },
    [fetchLearningModulesCompleted.rejected]: (state, action) => {
      console.log(action);
    },

    [fetchLearningModuleSingle.pending]: (state, action) => {
      
    },
    [fetchLearningModuleSingle.fulfilled]: (state, action) => {
      state.single = action.payload['learning-module']
    },
    [fetchLearningModuleSingle.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchLearningModulesCategories.pending]: (state, action) => {
      
    },
    [fetchLearningModulesCategories.fulfilled]: (state, action) => {
      state.categories = action.payload['terms']
    },
    [fetchLearningModulesCategories.rejected]: (state, action) => {
      console.log(action);
    }
  },
});

export const { selectFilterItem, clearFilterItems, resetLmReducer, clearSingleLM, completeLmArticle} = learningModulesSlice.actions
export default learningModulesSlice.reducer;

