import React from 'react';
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/download.svg';
import { ReactComponent as GenericFileIcon } from '../../assets/images/icons/generic_file.svg';
import { ReactComponent as WordFileIcon } from '../../assets/images/icons/word.svg';
import { ReactComponent as PptFileIcon } from '../../assets/images/icons/powerpoint.svg';
import { ReactComponent as XlsFileIcon } from '../../assets/images/icons/excel.svg';
import { ReactComponent as PdfFileIcon } from '../../assets/images/icons/pdf.svg';
import { add_to_datalayer } from "../../helpers/tracking";

const DownloadFile = (props) => {
    let fileIcon = null;
    switch(props.type){
        case 'pdf' : fileIcon = <PdfFileIcon />;
        break;
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document' : fileIcon = <WordFileIcon />;
        break;
        case 'msword' : fileIcon = <WordFileIcon />;
        break;
        case 'vnd.ms-powerpoint' : fileIcon = <PptFileIcon />;
        break;
        case 'vnd.ms-excel' : fileIcon = <XlsFileIcon />;
        break;
        default : fileIcon = <GenericFileIcon />;
    }
    const handleOnClick = (e) => {
        // Track PDF views
        add_to_datalayer({
            event: 'PDF_Download',
            pdf_name: props.title || ''
        });
    };

    return (
        <a className={`card card-download`} rel="noreferrer" href={props.url} onClick={(e) => handleOnClick(e)} target="_blank">
            <figure className={`card-download--figure`}>
                <div className={`card-download--figure-svg${fileIcon ? ' has-icon' : null}`}>{fileIcon}</div>
            </figure> 
            <div className={`card-download--content`}>
                {props.title ? <h4 className="card-download--title">{props.title}</h4> : null}
            </div>
            <div className={`card-download--icon`}>
                <DownloadIcon  />
            </div>
        </a>
    );
};

export default React.memo(DownloadFile);