import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { logout } from '../store/features/slice-auth'
import Button from './Button';




const UserSummary = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accountData = useSelector((state) => state.account.data);
  return (
    <>
      {accountData ? 
        <div className="user-summary">
          <div className="user-summary--info">
            <div className="user-summary--content">
                <h2 className="user-summary--title">{accountData.salutation} {accountData.firstName} {accountData.lastName}</h2>
                {accountData.speciality && accountData.speciality[0] ? 
                  <div className="user-summary--subtitle">{accountData.speciality[0].label}</div>
                : null}
                {accountData.subSpecialties && accountData.subSpecialties.length > 0 ? 
                <div className="user-summary--tags">
                  {accountData.subSpecialties.map((item, index) => (
                    <div key={index} className="text text--tag bold">{item.label}</div>
                  ))} 
                </div>
                : null}
            </div>
          </div>
          {props.logout || props.edit || props.save ? 
          <div className="user-summary--actions">
            {props.save ? <Button className="btn-primary" text="Save" Clicked={props.save} /> :  null }
            {props.edit ? <Button className="btn btn-primary" text="Edit profile" Clicked={() => history.push('/account/profile/edit') } /> :  null }
            {props.logout ? <Button className="btn btn-outline" text="Logout" Clicked={() => dispatch(logout())} /> : null }
          </div>
          : null}
        </div>
      : null}
    </>
  );
};

export default React.memo(UserSummary);