import React from 'react';
import NavLink from './Navlink';
import { Word } from './Word';
import APP_DATA from '../../package.json'

const Footer = () => {
    return (
        <footer className={`footer`}>
            <div className="footer--inner">
                <NavLink className="footer--menu-item" to="/contact-icon">
                    <Word value="Contact Icon" />
                </NavLink>
                <NavLink className="footer--menu-item" to="/report-issue">
                    <Word value="Report an Issue" />
                </NavLink>
                <NavLink className="footer--menu-item" to="/practice-with-icon">
                    <Word value="Practice with Icon" />
                </NavLink>
                <NavLink className="footer--menu-item" to="/about-icon">
                    <Word value="About Icon" />
                </NavLink>
                <NavLink className="footer--menu-item" to="/privacy">
                    <Word value="Privacy Policy" />
                </NavLink>
                <NavLink className="footer--menu-item" to="/disclaimer">
                    <Word value="Disclaimer" />
                </NavLink>  
                <div className="footer--version">{`v${APP_DATA.version}`}</div>
            </div>
        </footer>
    );
};

export default React.memo(Footer);