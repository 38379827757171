import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectFilterItem, clearFilterItems, fetchArticles} from "../../store/features/slice-articles";
import Section from "../Section";
import Button from "../Button";
import Select from '../Form/Select';
import Input from '../Form/Input';
import { Word } from "../Word";


const ArticlesFilter = (props) => {
    let {filter = 'clinical-articles'} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const topics = useSelector((state) => state.references.topics);
    const filters = useSelector((state) => state.articles.filters[filter]);
    const [topicsSelect, setTopicsSelect] = useState(filters.topics);
    const [search, setSearch] = useState(filters.s ? filters.s : '');


    const handleTopicsSelectChange = (payload) => {
        setTopicsSelect(payload);
    };

    const handleSearchChange = (e) => {
      setSearch(e.target.value);
    }

    const handleFilterSubmit = () => {
        dispatch(selectFilterItem( {data: topicsSelect , filterItem: 'topics', filter: filter}));
        dispatch(selectFilterItem( {data: search , filterItem: 's', filter: filter}));
        dispatch(fetchArticles({category: filter})).then(() => {
          if(props.pushAfterSubmit){
            history.push(props.pushAfterSubmit)
          }
        });

    };

    const handleClearFilter = () => {
        setTopicsSelect([]);
        setSearch('');
        if(filters.topics.length > 0 || filters.s){
          dispatch(clearFilterItems(filter));
          dispatch(fetchArticles({category: filter, applyFilter: false}));
        }
    };
    
    return (
        <Section className={`filter${props.className ? ' '+props.className : ''}`}>
          {props.title ? <h2><Word value={props.title} /></h2> : null}
          <div className="filter--form">
            {topics && topics.length > 0 ? 
              <Select 
                title={'Topics'}
                name={'topics'}
                id={'topics'}
                options={topics.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
                onChange={handleTopicsSelectChange}
                isMulti
                value={topicsSelect}
              />
            : null}
              <Input
                  type={"text"}
                  title={"Keyword"}
                  name={"search"}
                  value={search}
                  handleChange={handleSearchChange}
              />
              {topics ? 
                <div className="filter--actions">
                  <Button
                      type={"button"}
                      className="btn-primary text-yellow full-width"
                      text="Filter"
                      Clicked={handleFilterSubmit}
                      isDisabled={topicsSelect.length === 0 && search === '' ? true : false}
                  />
                  <Button
                    type={"button"}
                    className="btn-outline full-width"
                    text="Clear all"
                    Clicked={handleClearFilter}
                    isDisabled={topicsSelect.length === 0 && filters.topics.length === 0 && search === '' ? true : false}
                  />
                </div>
              : null}
          </div>
        </Section>
    );
};

export default ArticlesFilter;