import React, {useState} from 'react';


const Accordion = (props) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <div className="accordion--item">
            <div className={`accordion--title ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)}>
                {props.title}
                <div className="icon"></div>
            </div>
        
            <div className={`accordion--content ${!isOpen ? "collapsed" : ""}`}>
                {props.children}
            </div>
        </div>
    );
};

export default Accordion;