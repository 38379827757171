import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoadingStart, setLoadingEnd } from "./slice-ui";
import { handleApiResponse, handleApiErrors } from "./slice-api";
import { logActivityInSheet } from "./slice-log";
import axios from 'axios';
import wb_axios from '../../services/apiService';
import { add_to_datalayer } from "../../helpers/tracking";

const initialState = {
  ids: null,
  basic: null,
  full: null,
  list: null,
  closest: null,
  visited: null,
  currentLoaded: 9,
  single: {
    slug: null,
    articles: null,
    lms: null,
    clinical_trials: null
  },
  taxonomies: {
    special_interests: null,
    fields: null
  },
  filters:{
    special_interests: null,
    fields: null,
    locations: null,
    doctor_centre: null,
    doctor_name: null
  }
};


export const fetchDoctors = createAsyncThunk("doctors/getAll", async (parameters,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  tax_query.push('country:au');
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/doctors-normalized`, { 
      params: { 
       lang: thunkAPI.getState().settings.language,
       tax_query: tax_query,
       orderby: 'last_name'
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchDoctorsFilter = createAsyncThunk("doctors/Filtered", async (params,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  let tax_query = [];
  let meta_query = [];
  if(thunkAPI.getState().doctors.filters.special_interests && thunkAPI.getState().doctors.filters.special_interests.length > 0){
    tax_query.push('special-interest:'+thunkAPI.getState().doctors.filters.special_interests.map(function (obj) {return obj.value}))
  }
  if(thunkAPI.getState().doctors.filters.fields && thunkAPI.getState().doctors.filters.fields.length > 0){
    tax_query.push('doctor-tag:'+thunkAPI.getState().doctors.filters.fields.map(function (obj) {return obj.value}))
  }
  tax_query.push('country:au');
  if(thunkAPI.getState().doctors.filters.locations && thunkAPI.getState().doctors.filters.locations.type  === 'state'){
    meta_query.push('doctor_states:'+thunkAPI.getState().doctors.filters.locations.value);
  }
  if(thunkAPI.getState().doctors.filters.locations && thunkAPI.getState().doctors.filters.locations.type  === 'city'){
    meta_query.push('doctor_localities:'+thunkAPI.getState().doctors.filters.locations.value);
  }
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/doctors-normalized`, { 
      params: { 
        return: 'slugs',
        lang: thunkAPI.getState().settings.language,
        tax_query: tax_query,
        meta_query: meta_query,
        //query: thunkAPI.getState().doctors.filters.locations ? thunkAPI.getState().doctors.filters.locations.value : null,
        doctor_centre: thunkAPI.getState().doctors.filters.doctor_centre ? thunkAPI.getState().doctors.filters.doctor_centre.value : null,
        doctor_name: thunkAPI.getState().doctors.filters.doctor_name ? thunkAPI.getState().doctors.filters.doctor_name.label : null,
        orderby: 'last_name'
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);


export const fetchDoctorsTags = createAsyncThunk("doctors/getTags", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    let response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/doctor-tags`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
});

export const fetchDoctorsSpecialInterests = createAsyncThunk("doctors/getSpecialInterest", async (parameters, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    let response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/special-interests`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
});

export const fetchClosestDoctors = createAsyncThunk("doctors/getClosest", async (postcode,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/doctors/closest`, { 
      params: { 
       lang: thunkAPI.getState().settings.language,
       postcode: postcode,
       orderby: 'last_name'
      } 
    });
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const logDoctorVisit = createAsyncThunk("doctor/logVisited", async (data,thunkAPI) => {
  try {
    const response = await wb_axios.get(`api/v1/log/doctor-visited`, {params: data});
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchVisitedDoctors = createAsyncThunk("doctors/getVisited", async (postcode,thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await wb_axios.get(`api/v1/log/get-doctor-visited`);
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      thunkAPI.dispatch(setLoadingEnd());
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchDoctorsArticles = createAsyncThunk("doctors/getArticles", async (parameters,thunkAPI) => {
  if(!parameters.skip_loading){
    thunkAPI.dispatch(setLoadingStart());
  }
  try {
    const response = await wb_axios.get(`api/v1/author-articles/${parameters.doctor}`, {type : 'public'});
    if(!parameters.skip_loading){
      thunkAPI.dispatch(setLoadingEnd());
    }
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
      if(!parameters.skip_loading){
        thunkAPI.dispatch(setLoadingEnd());
      }
      thunkAPI.dispatch(handleApiErrors(error));
      return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchDoctorsLMs = createAsyncThunk("doctors/getLMs", async (parameters,thunkAPI) => {
  if(!parameters.skip_loading){
    thunkAPI.dispatch(setLoadingStart());
  }
  try {
    const response = await wb_axios.get(`api/v1/author-learning-modules/${parameters.doctor}`, {type : 'public'});
    if(!parameters.skip_loading){
      thunkAPI.dispatch(setLoadingEnd());
    }
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    if(!parameters.skip_loading){
      thunkAPI.dispatch(setLoadingEnd());
    }
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const fetchDoctorsClinicalTrials = createAsyncThunk("doctors/getClinicalTrials", async (parameters,thunkAPI) => {
  let tax_query = [];
  let meta_query = [];
  let ppp = -1;
  tax_query.push('country:au');
  if(parameters.doctor){
    meta_query.push('ct_doctors:'+parameters.doctor)
    ppp = 4;
  }
  if(!parameters.skip_loading){
    thunkAPI.dispatch(setLoadingStart());
  }
  try {
    const response = await axios.get(`https://ccms.icongroup.global/wp-json/ccms/clinical-trials-normalized`, {
      params: {
        return: 'basic',
        lang: thunkAPI.getState().settings.language,
        tax_query,
        meta_query,
        ppp
      } 
    });
    if(!parameters.skip_loading){
      thunkAPI.dispatch(setLoadingEnd());
    }
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    if(!parameters.skip_loading){
      thunkAPI.dispatch(setLoadingEnd());
    }
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);

export const referPatient = createAsyncThunk("account/referPatient", async (data, thunkAPI) => {
  thunkAPI.dispatch(setLoadingStart());
  try {
    const response = await wb_axios.post("api/v1/new-referral", data);
    if (response.data.success) {
      add_to_datalayer({
        event: 'Referral',
        doctor_name: data?.doctor || ''
      });
    }
    thunkAPI.dispatch(logActivityInSheet({activity: data?.doctor ? 'Referral to: '+data.doctor : 'Referral', sheet: 'Referrals'}));
    thunkAPI.dispatch(setLoadingEnd());
    return thunkAPI.dispatch(handleApiResponse(response));
  }
  catch (error) {
    thunkAPI.dispatch(setLoadingEnd());
    thunkAPI.dispatch(handleApiErrors(error));
    return thunkAPI.rejectWithValue((error.response || {}).data);
  }
}
);


const doctorsSlice = createSlice({
  name: "doctors",
  initialState,
  reducers: {
    selectFilterItem(state, action) {
      const { data, filterItem } = action.payload
      state.filters[filterItem] = data;
    },
    clearFilterItems(state, action) {
      state.filters = {
        special_interests: null,
        fields: null,
        locations: null,
        doctor_centre: null,
        doctor_name: null
        
      };
      state.list = state.ids
      state.currentLoaded = 9
    },
    renderMore(state){
      state.currentLoaded = state.currentLoaded + 9
    },
    resetRender(state){
      state.currentLoaded = 9;
    },
    populateInitials(state){
      state.list = state.ids
    },
    resetDoctorsReducer(state){
      state.closest = null;
      state.visited = null
    }
  },
  extraReducers: {
    [fetchDoctors.pending]: (state, action) => {
      
    },
    [fetchDoctors.fulfilled]: (state, action) => {
      state.ids = action.payload.doctors.slugs;
      state.basic = action.payload.doctors.basic;
      state.full = action.payload.doctors.full;
      state.list= action.payload.doctors.slugs;
    },
    [fetchDoctors.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchDoctorsFilter.pending]: (state) => {
      state.list = null;
    },
    [fetchDoctorsFilter.fulfilled]: (state, action) => {
        state.list = action.payload ? action.payload.doctors : [];
        state.currentLoaded = 9;
      
    },
    [fetchDoctorsFilter.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchClosestDoctors.pending]: (state, action) => {
      
    },
    [fetchClosestDoctors.fulfilled]: (state, action) => {
        state.closest = action.payload ? action.payload.doctors.slugs : [];
    },
    [fetchClosestDoctors.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchDoctorsTags.fulfilled]: (state, action) => {
      state.taxonomies.fields = action.payload.tags ? action.payload.tags.filter(item => item.sites?.includes('IDA')) : null;
    },
    [fetchDoctorsTags.rejected]: (state, action) => {
      console.log(action);
    },

    [fetchDoctorsSpecialInterests.fulfilled]: (state, action) => {
      state.taxonomies.special_interests = action.payload.interests.filter(item => item.sites?.includes('ICC-AU'));
    },
    [fetchDoctorsSpecialInterests.rejected]: (state, action) => {
      console.log(action);
    },
    [logDoctorVisit.pending]: (state, action) => {
      
    },
    [logDoctorVisit.fulfilled]: (state, action) => {
        state.visited = action.payload.visited
    },
    [logDoctorVisit.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchVisitedDoctors.pending]: (state, action) => {
      
    },
    [fetchVisitedDoctors.fulfilled]: (state, action) => {
        state.visited = action.payload.visited
    },
    [fetchVisitedDoctors.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchDoctorsArticles.pending]: (state, action) => {
      
    },
    [fetchDoctorsArticles.fulfilled]: (state, action) => {
        if(action.meta && action.meta.arg && action.payload){
          state.single.slug = action.meta.arg.doctor;
          state.single.articles = action.payload.articles;
        }
    },
    [fetchDoctorsArticles.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchDoctorsLMs.pending]: (state, action) => {
      
    },
    [fetchDoctorsLMs.fulfilled]: (state, action) => {
        if(action.meta && action.meta.arg && action.payload){
          state.single.slug = action.meta.arg.doctor;
          state.single.lms = action.payload.learning_modules;
        }
    },
    [fetchDoctorsLMs.rejected]: (state, action) => {
      console.log(action);
    },
    [fetchDoctorsClinicalTrials.pending]: (state, action) => {
      
    },
    [fetchDoctorsClinicalTrials.fulfilled]: (state, action) => {
        if(action.meta && action.meta.arg && action.payload){
          state.single.slug = action.meta.arg.doctor;
          state.single.clinical_trials = action.payload.trials ? Object.values(action.payload.trials) : null;
        }
    },
    [fetchDoctorsClinicalTrials.rejected]: (state, action) => {
      console.log(action);
    },
    [referPatient.pending]: (state, action) => {
      
    },
    [referPatient.fulfilled]: (state, action) => {
        
    },
    [referPatient.rejected]: (state, action) => {
      console.log(action);
    }
  },
});

export const { selectFilterItem, clearFilterItems, renderMore, resetRender, populateInitials, resetDoctorsReducer } = doctorsSlice.actions
export default doctorsSlice.reducer;
