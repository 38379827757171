import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectFilterItem,  clearFilterItems, fetchCentresFilter} from "../../store/features/slice-centres";
import Section from "../Section";
import Button from "../Button";
import Select from '../Form/Select';
import Input from '../Form/Input';
import { Word } from "../Word";


const CentresFilter = (props) => {
    let {filter = 'centres'} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const treatments = useSelector((state) => state.centres.taxonomies.treatments);
    const techniques = useSelector((state) => state.centres.taxonomies.techniques);
    const locations = useSelector((state) => state.centres.taxonomies.locations);
    const locationsN = useSelector((state) => state.centres.taxonomies.locationsN);
    const filters = useSelector((state) => state.centres.filters[filter]);
    const [treatmentsSelect, setTreatmentsSelect] = useState(filters.treatments);
    const [techniquesSelect, setTechniquesSelect] = useState(filters.techniques);
    const [stateSelect, setStateSelect] = useState(filters.state);
    const [citySelect, setCitySelect] = useState(filters.city);
    const [postcode, setPostcode] = useState(filters.postcode ? filters.postcode : '');

    const handleTreatmentsSelectChange = (payload) => {
        setTreatmentsSelect(payload);
    };
    
    const handleTechniquesSelectChange = (payload) => {
        setTechniquesSelect(payload);
    };
    const handleStateSelectChange = (payload) => {
        setStateSelect(payload);
    };

    const handleCitySelectChange = (payload) => {
      setCitySelect(payload);
    };
    const handlePostCodeChange = (e) => {
      setPostcode(e.target.value);
  };

    const handleFilterSubmit = () => {
        dispatch(selectFilterItem( {data: treatmentsSelect , filterItem: 'treatments', filter: filter}));
        dispatch(selectFilterItem( {data: techniquesSelect , filterItem: 'techniques', filter: filter}));
        dispatch(selectFilterItem( {data: stateSelect , filterItem: 'state', filter: filter}));
        dispatch(selectFilterItem( {data: citySelect , filterItem: 'city', filter: filter}));
        dispatch(selectFilterItem( {data: postcode , filterItem: 'postcode', filter: filter}));
        dispatch(fetchCentresFilter(filter)).then(() => {
          if(props.pushAfterSubmit){
           history.push(props.pushAfterSubmit)
          }
        });

    };

    const handleClearFilter = () => {
        setTreatmentsSelect(null);
        setTechniquesSelect(null);
        setStateSelect(null);
        setCitySelect(null);
        setPostcode('');
        if(filters.treatments || filters.techniques || filters.state || filters.city || filters.postcode){
          dispatch(clearFilterItems(filter));
        }
    };
    
    return (
        <Section className={`filter${props.className ? ' '+props.className : ''}`}>
          <div className="filter--content">
            {props.title ? <h2><Word value={props.title} /></h2> : null}
            <div className="filter--form">
              {locationsN && Object.keys(locationsN).length > 0 ? 
                <>
                  <Select 
                    name="state"
                    id={'state'}
                    title="State"
                    options={Object.keys(locationsN).map(state => ({ 'value': state, 'label': state, 'cities': locationsN[state] }))}
                    onChange={handleStateSelectChange}
                    value={stateSelect}
                  />
                  <Select 
                    name="city"
                    id={'city'}
                    title="City"
                    options={
                      stateSelect ? 
                        stateSelect.cities.map(city => ({ 'value': city, 'label': city})) 
                        : 
                        locations && locations.cities ? locations.cities.map(city => ({ 'value': city, 'label': city}))  : []}
                    onChange={handleCitySelectChange}
                    value={citySelect}
                  />
                </>
              : null}
              <Input 
                title="Postcode"
                name="psotcode"
                id="postcode"
                value={postcode}
                placeholder="Enter postcode..."
                handleChange={handlePostCodeChange}
              />
              {treatments && treatments.length > 0 ? 
                  <Select 
                    title={'Treatments'}
                    name={'treatments'}
                    id={'treatments'}
                    options={treatments.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
                    onChange={handleTreatmentsSelectChange}
                    isMulti
                    value={treatmentsSelect}
                  />
              : null}
              {techniques && techniques.length > 0 ? 
                  <>
                  <Select 
                    title="Techniques"
                    name="techniques"
                    id={'techniques'}
                    options={techniques.map(item => ({ 'value': item['slug'], 'label': item['name'] }))}
                    onChange={handleTechniquesSelectChange}
                    isMulti
                    value={techniquesSelect}
                  />
                  </>
              : null}
              {treatments || techniques || locationsN ? 
                <div className="filter--actions">
                  <Button
                      type={"button"}
                      className="btn-primary text-yellow full-width"
                      text="Filter"
                      Clicked={handleFilterSubmit}
                      isDisabled={!treatmentsSelect && !techniquesSelect && !stateSelect && !citySelect && postcode === '' ? true : false}
                  />
                  <Button
                    type={"button"}
                    className="btn-outline full-width"
                    text="Clear all"
                    Clicked={handleClearFilter}
                    isDisabled={!treatmentsSelect && !techniquesSelect && !stateSelect && !citySelect && !filters.treatments && !filters.techniques && !filters.state && !filters.city && postcode === '' && !filters.postcode ? true : false}
                  />
                </div>
              : null}
            </div>
        </div>
      </Section>
    );
};

export default CentresFilter;