import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import Accordion from "../Accordion";
import { Word } from "../Word";
import FeedCard from "../Cards/Feed";
import ClinicalTrialCard from "../Cards/ClinicalTrial";
import Button from "../Button";
import { fetchClinicalTrialsFiltered, selectFilterItem } from "../../store/features/slice-clinical-trials";
import { fetchDoctorsArticles, fetchDoctorsLMs, fetchDoctorsClinicalTrials } from "../../store/features/slice-doctors";

import G from "../../app/globals";

const SingleProfile = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const doctorEducationSingle = useSelector((state) => state.doctors.single);

    useEffect(() => {
        if(props.slug && (!doctorEducationSingle.slug || doctorEducationSingle.slug !== props.slug) ){
            dispatch(fetchDoctorsArticles({doctor: props.slug, skip_loading: true}));
            dispatch(fetchDoctorsLMs({doctor: props.slug, skip_loading: true}));
            dispatch(fetchDoctorsClinicalTrials({doctor: props.slug, skip_loading: true}));
        }
    }, [dispatch, props.slug, doctorEducationSingle.slug]);

    return (
        <div className="page-doctor-single--accordions">
            {props.interests && props.interests.length > 0 ? 
                <Accordion title={<Word value="Special Interests" />}>
                    <div className="page-doctor-single--specialintrests">
                        {props.interestsText ? 
                            <div className="page-doctor-single--specialintrests-intro">{props.interestsText}</div>
                        : null}
                        <div className="page-doctor-single--specialintrests-tags">
                            {props.interests.map((tag, index) => {
                            return <div className="page-doctor-single--specialintrests-tags-item" key={index}>
                                        {tag.name}
                                    </div>
                            })}
                        </div>
                    </div>
                </Accordion>
            : null}
            {props.publications && props.publications.length > 0 ? 
                <Accordion title={<Word value="Publications" />}>
                    <div className="page-doctor-single--publications">
                        {props.publications.map((item, index) => {
                            return <div className="page-doctor-single--publications-item" key={index}>
                                        {parse(item.publication, G.HTML_PARSE_OPTIONS)}
                                    </div>
                        })}
                    </div>
                </Accordion>
            : null}                      
            {props.bio ? 
                <Accordion title={<Word value="Biography" />}>
                    <div className="page-doctor-single--bio">
                        {parse(props.bio, G.HTML_PARSE_OPTIONS)}
                    </div>
                </Accordion>
            : null}
            {props.slug === doctorEducationSingle.slug && ((doctorEducationSingle.articles && doctorEducationSingle.articles.length > 0) || (doctorEducationSingle.lms && doctorEducationSingle.lms.length > 0)) ? 
                <Accordion title={<Word value="Education Authored" />}>
                    <div className="page-doctor-single--ea">
                        {doctorEducationSingle.articles && doctorEducationSingle.articles.length > 0 ? 
                            doctorEducationSingle.articles.map((item) => (
                                <FeedCard
                                    key={item.id}
                                    image={item.thumbnail}
                                    title={parse(item.title)}
                                    description={parse(item.excerpt)}
                                    categories={item.format ? [{ 'title': item.format, 'colour': 'yellow', 'icon': item.format_icon ? item.format_icon : null }] : null}
                                    completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
                                    cpd={G.CPD_TEXT(item.cpd)}
                                    link={`/article/${item.slug}`}
                                />
                            ))
                        : null}
                        {doctorEducationSingle.lms && doctorEducationSingle.lms.length > 0 ? 
                            doctorEducationSingle.lms.map((item) => (
                                <FeedCard
                                    key={item.id}
                                    image={item.thumbnail}
                                    title={parse(item.title)}
                                    description={parse(item.excerpt)}
                                    categories={ [{ 'title': 'Learning Module', 'colour': 'green', 'icon': item.category_icon ? item.category_icon : null }]}
                                    link={`/education/learning-module/${item.slug}`}
                                    completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
                                    cpd={G.CPD_TEXT(item.cpd)}
                                />
                            ))
                        : null}
                    </div>
                </Accordion>
            : null}
            {props.slug === doctorEducationSingle.slug && ((doctorEducationSingle.clinical_trials && doctorEducationSingle.clinical_trials.length > 0)) ? 
                <Accordion title={<Word value="Clinical Trials" />}>
                    <div className="page-doctor-single--ct">
                        {doctorEducationSingle.clinical_trials.map((item) => (
                            <ClinicalTrialCard
                                key={item.id}
                                slug={item.slug}
                                laymanTitle={item.laymanTitle}
                                title={item.title}
                                condition={item.condition}
                                locations={item.locations}
                                status={item.status}
                                link={`/research/clinical-trials/${item.slug}`}
                            />
                        ))}
                        <br />
                        <Button 
                            className="btn-primary text-yellow full-width"
                            text={`View all of ${props.title}'s trials`}
                            Clicked={() => {
                                const payload = [
                                    {
                                        value: props.slug,
                                        label: props.title
                                    }
                                ];
                                dispatch(selectFilterItem( {data: payload , filterItem: 'doctors'}));
                                dispatch(fetchClinicalTrialsFiltered());
                                history.push(`/research/clinical-trials/`);
                            }}
                        />
                    </div>
                </Accordion>
            : null}
        </div>
    );
};

export default SingleProfile;