import React from 'react';
import parse from 'html-react-parser';
import Link from '../Link';
import { Word } from '../Word';
import Image from '../Image';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg';
import { ReactComponent as AddressIcon } from '../../assets/images/icons/address.svg';

const FeaturedCard = (props) => {
    return (
        <Link className={`card card-featured${props.className ? ' '+props.className : ''}`} to={props.Clicked || !props.link ? null : props.link} onClick={props.Clicked ? props.Clicked : null}>
            {props.image ? 
                <Image 
                    className={`card-featured--figure${props.image_small ? ' small' : ''}`}
                    src={props.image} 
                    alt={props.title ? props.title : ""}
                />
            : null}
            <div className="card-featured--content">
                {props.title ? <h4 className="card-featured--title blue">{props.title}</h4> : null}
                {props.description ? <div className="card-featured--description">{props.description}</div> : null }
                {props.date || props.time || props.address ? 
                    <div className="card-featured--details">
                        {props.date ? 
                            <div className="text card-featured--date">
                                <CalendarIcon />
                                {props.date}
                            </div>
                        : null}
                        {props.time ? 
                            <div className="text card-featured--time">
                                <ClockIcon />
                                {props.time}
                            </div>
                        : null}
                        {props.address ? 
                            <div className="text card-featured--address">
                                <AddressIcon />
                                {props.address}
                            </div>
                        : null}
                    </div>
                : null}
                {((props.categories && props.categories.length > 0) || (props.tags && props.tags.length > 0)) ? 
                    <div className="card-featured--tags">
                        {props.categories ? props.categories.map((category, index) => {
                            return <div className={`text text--category ${category.colour ? category.colour : ''}`} key={index}>
                                        {category.icon ? typeof category.icon === 'string' ? parse(category.icon) :  category.icon : null}
                                        {category.title}
                                    </div>
                        }) : null}
                        {props.tags ? props.tags.map((tag, index) => {
                            return <div className="text text--tag" key={index}>
                                        {tag.icon ? parse(tag.icon) : null}
                                        {tag.title}
                                    </div>
                        }) : null}
                    </div>
                : null}
            </div>
            {props.stamp ? <div className={`card-featured--stamp ${props.stamp.colour}`}><Word value={props.stamp.title} /></div> : null}
        </Link>
    );
};

export default React.memo(FeaturedCard);