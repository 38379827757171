import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import {Word} from "../../components/Word";
import UserSummary from "../../components/UserSummary";


const Profile = () => {
    const dispatch = useDispatch();
    const accountData = useSelector((state) => state.account.data);
    useEffect(() => {
      dispatch(setPageClass("page-profile"));
      dispatch(setUpHeader({
        show: true,
        back: true,
        profile: true,
        search: true
      }));
    }, [dispatch]);

    return (
        <>
            {accountData ? 
            <>
                <PageHead 
                className="page-profile--page-head"
                title="Profile"
                />
                <PageContent className="page-profile--page-content">
                    <Section className="split-content w-40-60">
                        <Section><UserSummary edit /></Section>
                        <Section className="page-profile--details">
                            <div className="page-profile--details-account">
                                <h2><Word value="Account details" /></h2>
                                {accountData.salutation ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="Salutation" /></div>
                                        <div className="page-profile--details-item__value">{accountData.salutation}</div>
                                    </div>
                                : null}
                                {accountData.firstName ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="First Name" /></div>
                                        <div className="page-profile--details-item__value">{accountData.firstName}</div>
                                    </div>
                                : null}
                                {accountData.lastName ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="Last Name" /></div>
                                        <div className="page-profile--details-item__value">{accountData.lastName}</div>
                                    </div>
                                : null}
                                {accountData.email ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="Email" /></div>
                                        <div className="page-profile--details-item__value">{accountData.email}</div>
                                    </div>
                                : null}
                                {accountData.mobile ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="Mobile" /></div>
                                        <div className="page-profile--details-item__value">{accountData.mobile}</div>
                                    </div>
                                : null}
                                {accountData.postcode ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="Postcode" /></div>
                                        <div className="page-profile--details-item__value">{accountData.postcode}</div>
                                    </div>
                                : null}
                            </div>
                            <div className="page-profile--details-professional">
                                <h2><Word value="Professional details" /></h2>
                                {accountData.speciality && accountData.speciality[0] ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="Speciality" /></div>
                                        <div className="page-profile--details-item__value">{accountData.speciality[0].label}</div>
                                    </div>
                                : null}
                                {accountData.subSpecialties && accountData.subSpecialties.length > 0 ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="Sub Speciality" /></div>
                                        <div className="page-profile--details-item__value">
                                        {accountData.subSpecialties.map(item => (item.label)).join(", ")}
                                        </div>
                                    </div>
                                : null}
                                {accountData.ahpraNumber ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="AHPRA Number" /></div>
                                        <div className="page-profile--details-item__value">{accountData.ahpraNumber}</div>
                                    </div>
                                : null}
                                {accountData.racgpNumber ? 
                                    <div className="page-profile--details-item">
                                        <div className="page-profile--details-item__label"><Word value="RACGP Number" /></div>
                                        <div className="page-profile--details-item__value">{accountData.racgpNumber}</div>
                                    </div>
                                : null}
                            </div>
                            {accountData.clinics && accountData.clinics.length > 0 ? 
                                <div className="page-profile--details-practice">
                                <h2><Word value="Practice details" /></h2>
                                {accountData.clinics.map((item, index) => (
                                    <div className="page-profile--details-item" key={index}>
                                        <div className="page-profile--details-item__label">{item.name}</div>
                                        <div className="page-profile--details-item__value">
                                            <div>{item.address}</div>
                                            <div>{item.email}</div>
                                            <div><Word value="Provider number" />: {item.provider_number}</div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            : null}
                        </Section>
                    </Section>
                </PageContent>
            </>
            : null}
        </>
    );
};

export default Profile;
