import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { addBulkFavourites } from "../../store/features/slice-account";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import Button from "../../components/Button";
import Select from "../../components/Form/Select";
import DoctorCard from "../../components/Cards/DoctorCircular";


const Favourites = () => {
  const [favouritesSelect, setFavouritesSelect] = useState();
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.account.data);
  const doctorsListBasic = useSelector((state) => state.doctors.basic);

  
  useEffect(() => {
    dispatch(setPageClass("page-favourites"));
    dispatch(setUpHeader({
      show: true,
      back: true,
      profile: true,
      search: true,
      backTo: '/account'
    }));
  }, [dispatch]);

  const handleFavouritessSelectChange = (payload) => {
    setFavouritesSelect(payload);
  };

  const handleFavouritesSelectSubmit = () => {
    let doctors = favouritesSelect.map(item => item.value);
    dispatch(addBulkFavourites({doctors: doctors})).then(e => {
      if(e.payload && e.payload.favourites){
        setFavouritesSelect(null);
      }
    });
  }

  const renderDoctorsCards = () => {
    return accountData && accountData.favourites && doctorsListBasic ? accountData.favourites.map(id => (
      doctorsListBasic[id] ? 
        <DoctorCard
            key={id}
            slug={doctorsListBasic[id].slug}
            image={doctorsListBasic[id].profileImage}
            title={parse(doctorsListBasic[id].salutation+" "+doctorsListBasic[id].name)}
            foxoId={doctorsListBasic[id].foxoId}
        /> : null
    )) : null;
  };

  return (
      <>
        <PageHead 
          className="page-favourites--page-head"
          title="Favourites"
          bg="white"
        />
        <PageContent className="page-favourites--page-content">
          {accountData ? 
          <>
            {accountData.favourites.length > 0 ? 
              <Section className="page-favourites--list">
                <p>Select a favourite for further actions</p>
                <div className="page-favourites--list-grid">
                  {renderDoctorsCards()}
                </div>
              </Section>
            : null}
            <Section className="page-favourites--form">
              {doctorsListBasic && Object.keys(doctorsListBasic).length > 0 ? 
                <>
                <Select 
                  title={'Add favourite'}
                  name={'add_favourites'}
                  id={'add_favourites'}
                  options={Object.values(doctorsListBasic).map(item => ({label: parse(item.name), value: item.slug}))}
                  onChange={handleFavouritessSelectChange}
                  value={favouritesSelect}
                  isMulti
                />
                <Button
                  type={"button"}
                  className="btn-primary text-yellow full-width"
                  text="Add selected to favourites"
                  Clicked={handleFavouritesSelectSubmit}
                  isDisabled={!favouritesSelect ? true : false}
                />
                </>
              : null}
            </Section>
          </>
          : null}
        </PageContent>
      </>
  );
};

export default Favourites;
