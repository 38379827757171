import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

const CustomNavLink = (props) => {

    const history = useHistory();
    return (
        <NavLink replace={history.location.pathname === props.to} className={props.className} to={props.to} exact={props.exact}>{props.children}</NavLink>
    );
};

export default CustomNavLink;