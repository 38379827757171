import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewPassword  } from "../store/features/slice-account";
import { resetErrors } from "../store/features/slice-api";
import { toast } from "../store/features/slice-alerts";
import Input from "./Form/Input";
import Button from "./Button";
import G from "../app/globals";


const SetNewPasswordForm = (props) => {

  const confirmPassField = useRef();
  const errorsRef = useRef();
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.api.errors.set_account_password);
  const initialFormState = {
    _new_password: '',
    _confirm_new_password: '',
    _ahpra_number: ''
  };
  const [form, setState] = useState(initialFormState);


  useEffect(() => {
    if(errors && Object.values(errors).length > 0){
      errorsRef.current?.scrollIntoView({behavior: "smooth"});
    }
  }, [errors, errorsRef])

  const handleChange = (e) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
    if(e.target.name === '_new_password'){
      e.target.setCustomValidity(!new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=]).{9,}$").test(e.target.value) ? 'Password must be at least 9 characters long, include uppercase and lowercase letters, a number, and a special character from !@#$%^&*()_+-="' : '');
      if(e.target.checkValidity()) confirmPassField.current.pattern = String(e.target.value).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    if(e.target.name === '_confirm_new_password'){
        e.target.setCustomValidity(e.target.validity.patternMismatch ? 'Please enter the same Password as above.' : '');
    }
    if (e.target.name === '_ahpra_number') {
      e.target.setCustomValidity(e.target.validity.patternMismatch ? 'AHPRA number must start with 3 letters followed by 10 numbers.' : '');
  }
  };

  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);
  const handleSubmit = (e) => {
    if(e){
      e.preventDefault();
    }
    dispatch(setNewPassword(form)).then((e) => {
      if(e.payload){
          G.RESET_SCROLL();
          setState(initialFormState);
          dispatch(toast({
              message: e.payload.message,
              type: 'success'
          }));
          if(props.callback){
            props.callback();
          }
      }
  });
    
  };

  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);

  return (
    <>
      <div className="set-new-password-form">
        <div ref={errorsRef}></div>
        {errors && errors.length > 0 ? 
            <div className="form-errors">
            {errors.map((error, index) => (
                <div key={index} className="form-errors--item">{error.message}</div>
            ))}
            </div> 
        : null}
        <form onSubmit={handleSubmit}>
            <Input
                type={"password"}
                name={"_new_password"}
                title={"Set new password"}
                value={form._new_password}
                handleChange={handleChange}
                isRequired
            />
            <Input
                type={"password"}
                name={"_confirm_new_password"}
                title={"Confirm new password"}
                value={form._confirm_new_password}
                handleChange={handleChange}
                ref={confirmPassField}
                isRequired
            />
            {!props.ahpraNumber ? 
              <Input
                  type={"text"}
                  name={"_ahpra_number"}
                  title={"AHPRA Number"}
                  value={form._ahpra_number}
                  handleChange={handleChange}
                  isRequired
                  pattern="^[a-zA-Z]{3}\d{10}"
              />
            : null}
            <div className="form-actions">
              <Button
                  type={"submit"}
                  className="btn btn-primary purple text-yellow full-width"
                  text="Set password"
              />
            </div>
        </form>       
      </div>
    </>
  );
};

export default SetNewPasswordForm;